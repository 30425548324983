import React, { useState, useEffect } from 'react';
import { MDBCol, MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import axios from 'axios';
import ManageBtn from './myBtnMgr';

export default function MyTexter(props) {

    const [kpi, setKpi] = useState(JSON.parse(props.kpi));

    useEffect(() => {
    },[])
    
    useEffect(() => {
      setKpi(JSON.parse(props.kpi));
    },[props.kpi])

    const chartStyle = {
      width: 100,
      height:100
    }  
    
    return(
                <div style={{textAlign:'center'}}
                 className="
                        bg-glass
                        shadow-4-strong
                        rounded-6
                        p-4
                        ripple
                        "
                >
                    <h3>
                      {kpi.name}
                    </h3><br />
                    {kpi.iotype==="Laufschrift" ?
                      <marquee><p style={{color:"#00ffff", fontSize:"2em"}}>{kpi.data2[0]}</p></marquee>
                      :
                      <p style={{color:"#00ffff", fontSize:"2em"}}>{kpi.data2[0]}</p>
                    }
                  <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
                </div>
        );
}