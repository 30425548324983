import React, { useState, useEffect } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBInputGroup,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBAccordion, 
  MDBAccordionItem,
  MDBRadio,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBValidation,
  MDBIcon,
  MDBScrollbar
} from 'mdb-react-ui-kit';

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import axios from 'axios';

export default function MyStripe (props) {

  const [isLoading, setIsLoading] = useState(true);
  const [intent, setIntent] = useState(null);
  const [info, setInfo] = useState("Initialisierung...");
  const [user, setUser] = useState(null);

  const [clientSecret, setClientSecret] = useState(null);
  const [staticModal, setStaticModal] = useState(true);
  const toggleShow = () => setStaticModal(!staticModal);

  //const stripe = loadStripe("pk_test_...");
  const stripe = loadStripe(props.pkey);
  
  const readintent = (id) => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/dashboards/getstripeintent?user_id='+id+"&paket="+props.paket+"&price="+props.price;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/getstripeintent?user_id='+id+"&paket="+props.paket+"&price="+props.price;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data.intent);
        setIntent(res.data.intent);
        setClientSecret(res.data.intent.client_secret);
        setIsLoading(false);
        setInfo("bereit für Zahlung...");
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
          setInfo("Fehler bei Zahlungsinitialisierung...");
        }
      })
  }  
  
  useEffect(() => {
    setUser(JSON.parse(props.user));
    readintent(JSON.parse(props.user).id);
  },[])

function CheckoutForm() {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    //clientSecret ref
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
        },
      },
    });
    setPaymentLoading(false);
    if (paymentResult.error) {
      setInfo(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
        setInfo("Zahlung erfolgreich!");
        props.buyAbo();
        //closeWin("OK");
      }
    }
  };

  return (
    <form onSubmit = {payMoney}>
        <CardElement
          className="card"
          options={{
            hidePostalCode: true,
            style: {
              base: {
                backgroundColor: "white"
              } 
            },
          }}
        />
        <br />
        <button
          className="btn btn-info"
          disabled={isPaymentLoading}
        >
          {isPaymentLoading ? "Prüfung..." : "Bezahlen"}
        </button>
    </form>
  );
}

  return (
     
     <>
      <MDBModal className="bg-glasst" staticBackdrop tabIndex='-1' show={props.showme} >
        <div className="myheader">Kartenzahlung</div>
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />

        <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
              <h1>Bestellung</h1>
              {!isLoading ?
              <>
              <div className="mid">{user.email} </div>
              <div className="mid">{user.name + " " + user.lastname} </div>
              <div className="mid">IOTeasy {intent.description}</div>
              <div className="red big">{(intent.amount/100).toFixed(2)} CHF</div>
              </>
              :
              <div className="center">
                <MDBIcon className="fa-5x" spin fas icon='spinner' />
                <p className="midi">preparing payment...</p>
              </div> 
              }

              <Elements stripe={stripe}>
                {/*<CardElement /> */}
                <CheckoutForm />
              </Elements>

                <div className="red">{info}</div>
        </div>
      </MDBModal>      
        {/*<MDBBtn id="btn" onClick={() => setStaticModal(!staticModal)}> toggle </MDBBtn> */}
      </>
      
  );
};