export const devices = [
  {"active":true, "value":0,"text":"Neopixel WS2811/WS8212","type":"Aktor","picture":"ws8212.jpg","name":"LED","desc":"individuell ansteuerbare LEDs",
    dashoptions:[3],
    pack:[0,19,53,51,54],
    libs:[{lib:"Adafruit_NeoPixel.h"}], 
    pins:[{piname:"ledPin", pinr:32, pinmode:"OUTPUT", pinread:"analogRead", pintype:"int", pinvar:"wert"}],
    defs:[{def:"#define NUM_LEDS 100"}, {def:"#define BRIGHTNESS 50"}, {def:"Adafruit_NeoPixel strip = Adafruit_NeoPixel(NUM_LEDS, ledPin, NEO_GRB + NEO_KHZ800);"}],
    setups:[], 
    loops:[{loop:"for(int i=0; i < NUM_LEDS; i++) {"}, {loop:"strip.setPixelColor(i, strip.Color(random(255),random(255),random(255)));"}, {loop:"}"}, {loop:"strip.show();"},{loop:"strip.show();"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Regler RGB",
    labels:["text"],
    readsims:[{readsim:"String texts = '#0000FF';"}],
    reads:[{read:"String hexstring = texts;"}, {read:"long number = strtol( &hexstring[1], NULL, 16);"}, {read:"long r = number >> 16;"},{read:"long g = number >> 8 & 0xFF;"},{read:"long b = number & 0xFF;"},{read:"for(int i=0; i < NUM_LEDS; i++) {"},{read:"strip.setPixelColor(i, strip.Color(r,g,b));"}, {read:"}"}, {read:"strip.show();"},{read:"strip.show();"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"Pin 5"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Neopixel",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"Data"},
              {nr:3, name:"5V"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Power Supply",
            pins:[
              {nr:1, name:"GND"}, 
              {nr:2, name:"5V"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:2, pin:1}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:3}, to:{dev:3,pin:2}, color:"rgb(255,0,0)"},
        ],
    }
  },
  
  {"active":true, "value":1,"text":"ESP32 TTGO watch v1","type":"Spezial","picture":"ttgowatch.jpg","name":"ESP Uhr","desc":"ESP32 in Uhrengehäuse mit Touch-Display",
    dashoptions:[1],
    pack:[1,51,54],
    libs:[], 
    pins:[],
    defs:[{def:"#define LILYGO_WATCH_2020_V1"}, {def:"#include <LilyGoWatch.h>;"}, {def:"#include <TTGO.h>;"}, {def:"TTGOClass *ttgo;"}, {def:"int counter = 1;"}, {def:"int16_t x=320;"}, {def:"int16_t y=200;"}],
    setups:[{setup:"ttgo = TTGOClass::getWatch();"}, {setup:"ttgo->begin();"}, {setup:"ttgo->openBL();"}, {setup:"ttgo->tft->setRotation(2);"},{setup:"ttgo->tft->drawRect(0,0,240,100,TFT_RED);"},{setup:"ttgo->tft->drawRect(0,140,240,100,TFT_GREEN);"},{setup:"ttgo->tft->setTextFont(4);"},{setup:"ttgo->tft->setTextColor(TFT_WHITE);"},{setup:'ttgo->tft->drawString("OFF", 100,45);'},{setup:'ttgo->tft->drawString("ON", 100,180);'}], 
    loops:[{loop:"if (ttgo->getTouch(x,y)>0) {"},{loop:"ttgo->tft->fillScreen(TFT_BLACK);"}, {loop:'ttgo->tft->setTextFont(4);'}, {loop:"ttgo->tft->setTextColor(TFT_WHITE);"}, {loop:"if(y<100) {"}, {loop:"wert=0;"}, {loop:"ttgo->tft->fillRect(0,0,240,100,TFT_RED);"}, {loop:"ttgo->tft->drawRect(0,140,240,100,TFT_GREEN);"}, {loop:"}"}, {loop:"if(y>120) {"}, {loop:"ttgo->tft->drawRect(0,0,240,100,TFT_RED);"}, {loop:"ttgo->tft->fillRect(0,140,240,100,TFT_GREEN);"}, {loop:"wert=1;"}, {loop:"}"}, {loop:'ttgo->tft->drawString("OFF", 100,45);'}, {loop:'ttgo->tft->drawString("ON", 100,180);'}, {loop:"Serial.print(wert);"}, {loop:"delay(50);"}, {spec:"write_ioteasy"}, {loop:"}"}],
    funcs:[],
    controls:{  trigger:true},
    dash:"Schalter",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32 TTGO watch",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"5"},
              ]
          }
        ],
      connections:[
        ],
    }
  },
  
  {"active":true, "value":62,"text":"ESP32 TTGO watch v3","type":"Spezial","picture":"ttgowatchv3.jpg","name":"ESP Uhr","desc":"ESP32 in Uhrengehäuse mit Touch-Display",
    dashoptions:[1],
    pack:[1,51,54],
    libs:[], 
    pins:[],
    defs:[{def:"#define LILYGO_WATCH_2020_V1"}, {def:"#include <LilyGoWatch.h>;"}, {def:"#include <TTGO.h>;"}, {def:"TTGOClass *ttgo;"}, {def:"int counter = 1;"}, {def:"int16_t x=320;"}, {def:"int16_t y=200;"}],
    setups:[{setup:"ttgo = TTGOClass::getWatch();"}, {setup:"ttgo->begin();"}, {setup:"ttgo->openBL();"}, {setup:"ttgo->tft->setRotation(2);"},{setup:"ttgo->tft->drawRect(0,0,240,100,TFT_RED);"},{setup:"ttgo->tft->drawRect(0,140,240,100,TFT_GREEN);"},{setup:"ttgo->tft->setTextFont(4);"},{setup:"ttgo->tft->setTextColor(TFT_WHITE);"},{setup:'ttgo->tft->drawString("OFF", 100,45);'},{setup:'ttgo->tft->drawString("ON", 100,180);'}], 
    loops:[{loop:"if (ttgo->getTouch(x,y)>0) {"},{loop:"ttgo->tft->fillScreen(TFT_BLACK);"}, {loop:'ttgo->tft->setTextFont(4);'}, {loop:"ttgo->tft->setTextColor(TFT_WHITE);"}, {loop:"if(y<100) {"}, {loop:"wert=0;"}, {loop:"ttgo->tft->fillRect(0,0,240,100,TFT_RED);"}, {loop:"ttgo->tft->drawRect(0,140,240,100,TFT_GREEN);"}, {loop:"}"}, {loop:"if(y>120) {"}, {loop:"ttgo->tft->drawRect(0,0,240,100,TFT_RED);"}, {loop:"ttgo->tft->fillRect(0,140,240,100,TFT_GREEN);"}, {loop:"wert=1;"}, {loop:"}"}, {loop:'ttgo->tft->drawString("OFF", 100,45);'}, {loop:'ttgo->tft->drawString("ON", 100,180);'}, {loop:"Serial.print(wert);"}, {loop:"delay(50);"}, {spec:"write_ioteasy"}, {loop:"}"}],
    funcs:[],
    controls:{  trigger:true},
    dash:"Schalter",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32 TTGO watch",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"5"},
              ]
          }
        ],
      connections:[
        ],
    }
  },
  
  {"active":true, "value":63,"text":"LED","type":"Aktor","picture":"led.png","name":"LED","desc":"LED light emitting diode",
    dashoptions:[1,15],
    pack:[63,51,54],
    libs:[], 
    pins:[{piname:"ledPin", pinr:32, pinmode:"OUTPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[{loop:"digitalWrite(ledPin, HIGH);"},{loop:"delay(1000);"},{loop:"digitalWrite(ledPin, LOW);"}],
    funcs:[],
    controls:{ trigger:false},
    dash:"Schalter",
    labels:["wert"],
    readsims:[{readsim:"int wert = 1;"}],
    reads:[{read:"if( wert > 0) {"}, {read:"digitalWrite(ledPin, HIGH);}"}, {read:"else {"}, {read:"digitalWrite(ledPin, LOW); }"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"34"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"LED",
            pins:[
              {nr:1, name:"- Pin"},
              {nr:2, name:"+ Pin (lang)"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Widerstand",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:3,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:2, pin:2}, to:{dev:3,pin:2}, color:"rgb(0,255,0)"},
        ],
    }  
  },

  {"active":true, "value":64,"text":"LED","type":"Aktor","picture":"rgbled.jpg","name":"LED","desc":"LED light emitting diode, welche alle Farben aus Rot, Grün & Blau darstellen kann",
    dashoptions:[6,7],
    pack:[65,51,54],
    libs:[], 
    pins:[{piname:"ledPinR", pinr:32, pinmode:"OUTPUT", pinread:"digitalRead", pintype:"int", pinvar:"rwert"}, {piname:"ledPinG", pinr:33, pinmode:"OUTPUT", pinread:"digitalRead", pintype:"int", pinvar:"gwert"}, {piname:"ledPinB", pinr:27, pinmode:"OUTPUT", pinread:"digitalRead", pintype:"int", pinvar:"bwert"}],
    defs:[],
    setups:[],
    loops:[{loop:"digitalWrite(ledPinR, HIGH);"},{loop:"delay(1000);"},{loop:"digitalWrite(ledPinR, LOW);"},{loop:"digitalWrite(ledPinG, HIGH);"},{loop:"delay(1000);"},{loop:"digitalWrite(ledPinG, LOW);"},{loop:"digitalWrite(ledPinB, HIGH);"},{loop:"delay(1000);"},{loop:"digitalWrite(ledPinB, LOW);"}],
    funcs:[],
    controls:{ trigger:false},
    dash:"Ampel",
    labels:["text"],
    readsims:[{readsim:'int wert = "green";'}],
    reads:[{read:"digitalWrite(ledPinR, LOW);"}, {read:"digitalWrite(ledPinG, LOW);"}, {read:"digitalWrite(ledPinB, LOW);"}, {read:'if( texts == "green") {'}, {read:"digitalWrite(ledPinG, HIGH);"}, {read:"}"}, {read:'if( texts == "red") {'}, {read:"digitalWrite(ledPinR, HIGH);"}, {read:"}"}, {read:'if( texts == "yellow") {'}, {read:"digitalWrite(ledPinB, HIGH);"}, {read:"}"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"32"},
              {nr:3, name:"33"},
              {nr:4, name:"34"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"RGBLED",
            pins:[
              {nr:1, name:"- PinR"},
              {nr:2, name:"- PinG"},
              {nr:3, name:"- PinB"},
              {nr:4, name:"+ Pin (lang)"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"R 330 Ohm",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          },
          {
            dev:4,
            typ:"small",
            name:"R 330 Ohm",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          },
          {
            dev:5,
            typ:"small",
            name:"R330 Ohm",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:3,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:4,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:4}, to:{dev:5,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:2, pin:2}, to:{dev:3,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:2, pin:3}, to:{dev:4,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:2, pin:4}, to:{dev:5,pin:2}, color:"rgb(0,255,0)"},
        ],
    }  
  },

  {"active":true, "value":64,"text":"330 Ohm Widerstand","type":"Zubehör","picture":"resistor330.jpg","name":"Widerstand 330Ohm","desc":"330 Ohm Widerstand"},
  
  {"active":true, "value":2,"text":"2pin-Buzzer","type":"Aktor","picture":"buzzer.jpg","name":"Buzzer","desc":"Mini-Lautsprecher",
    dashoptions:[1],
    pack:[2,19,51,54],
    libs:[], 
    pins:[{piname:"buzzerPin", pinr:34, pinmode:"OUTPUT", damode:"analog", pintype:"int", pinvar:"wert"}],
    defs:[{def:"#define channel 0"}, {def:"#define frequency 5000"}, {def:"#define resolution 8"}],
    setups:[{setup:"ledcSetup(channel, frequency, resolution);"}, {setup:"ledcAttachPin(buzzerPin, channel);"}], 
    loops:[{loop:"ledcWriteTone(channel, frequency);"},{loop:"delay(1000);"}, {loop:"ledcWriteTone(channel, 0);"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Schalter",
    labels:["switch"],
    readsims:[{readsim:"int wert = 1;"}],
    reads:[{read:"if(wert > 0) {"},{read:"ledcWriteTone(channel, frequency);"},{read:"delay(1000);"}, {read:"ledcWriteTone(channel, 0);"},{read:"}"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Buzzer",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
        ],
    }
  },
  
  {"active":true, "value":3,"text":"LCD 4x20 IC2","type":"Aktor","picture":"lcd.jpg","name":"LCD Display","desc":"Flüssigkeitskristallanzeige ",
    dashoptions:[6,7,0],
    pack:[3,19,51,54],
    libs:[{lib:"LiquidCrystal_I2C.h"}], 
    pins:[],
    defs:[{def:"#define lcdColumns 20"}, {def:"#define lcdRows 4"}, {def:"LiquidCrystal_I2C lcd(0x3F, lcdColumns, lcdRows);"}],
    setups:[{setup:"lcd.init();"}, {setup:"lcd.backlight();"}], 
    loops:[{loop:"lcd.clear()"},{loop:"lcd.setCursor(0,0)"}, {loop:"lcd.print('Hello from IOTeasy')"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Text",
    labels:["text"],
    readsims:[{readsim:"String text = 'Hello!';"}],
    reads:[{read:"lcd.clear();"},{read:"lcd.setCursor(0,0);"}, {read:"lcd.print(text);"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"21"},
              {nr:4, name:"22"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"LCD display",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:1, name:"SDA"},
              {nr:2, name:"CLK"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
        ],
    }
  },
  
  {"active":true, "value":4,"text":"OLED 0,96 128x128 I2C SSD1306","type":"Aktor","picture":"oled.jpg","name":"OLED Display","desc":"Display auf Basis von OLED Technologie mit I2C Anschluss",
    dashoptions:[6,7,0],
    pack:[4,19,51,54],
    libs:[{lib:"Wire.h"}, {lib:"Adafruit_GFX.h"}, {lib:"Adafruit_SSD1306.h"}], 
    pins:[],
    defs:[{def:"#define OLED_SDA 21"}, {def:"#define OLED_SCL 22"}, {def:"#define OLED_RST 16"}, {def:"#define SCREEN_WIDTH 128"}, {def:"#define SCREEN_HEIGHT 64"}, {def:"Adafruit_SSD1306 display(SCREEN_WIDTH, SCREEN_HEIGHT, &Wire, OLED_RST);"}],
    setups:[{setup:"pinMode(OLED_RST, OUTPUT);"}, {setup:"digitalWrite(OLED_RST, LOW);"}, {setup:"delay(20);"}, {setup:"digitalWrite(OLED_RST, HIGH);"}, {setup:"Wire.begin(OLED_SDA, OLED_SCL);"}, {setup:"display.begin(SSD1306_SWITCHCAPVCC, 0x3c, false, false); // Address 0x3C for 128x32"}, {setup:"display.clearDisplay();"}], 
    loops:[{loop:"display.clearDisplay();"},{loop:"display.setTextColor(WHITE);"}, {loop:"display.setTextSize(1);"}, {loop:"display.setCursor(0,0);"}, {loop:"display.print('hello from IOTeasy...');"}, {loop:"display.display();"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Text",
    labels:["text"],
    readsims:[{readsim:"String text = 'Hello!';"}],
    reads:[{read:"display.clearDisplay();"},{read:"display.setTextColor(WHITE);"}, {read:"display.setTextSize(1);"}, {read:"display.setCursor(0,0);"}, {read:"display.print(text);"}, {read:"display.display();"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"21"},
              {nr:4, name:"22"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"OLED I2C display",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:1, name:"SDA"},
              {nr:2, name:"CLK"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
        ],
    }
  },

  {"active":true, "value":5,"text":"OLED 1,5 128x128 SPI SSD135","type":"Aktor","picture":"oledspi.jpg","name":"OLED Display","desc":"Display auf Basis von OLED Technologie mit SPI Anschluss",
    dashoptions:[6,7,0],
    pack:[5,19,51,54],
    libs:[{lib:"SPI.h"}, {lib:"SSD_13XX.h"}], 
    pins:[],
    defs:[{def:"#define TFT_DC  16"}, {def:"#define TFT_CS 17"}, {def:"SSD_13XX tft = SSD_13XX(TFT_CS, TFT_DC);"}, {def:"uint8_t errorCode = 0;"}],
    setups:[{setup:"tft.begin(false);"}, {setup:"delay(30);"}, {setup:"errorCode = tft.getErrorCode();"}, {setup:"if (errorCode != 0) {"}, {setup:"Serial.print('Init error! ');"}, {setup:"}"}], 
    loops:[{loop:"tft.clearScreen();"},{loop:"tft.setCursor(0, 0);"}, {loop:"tft.setTextColor(RED);"}, {loop:"tft.setTextScale(1);"}, {loop:"tft.println('hello from IOTeasy');"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Text",
    labels:["text"],
    readsims:[{readsim:"String text = 'Hello';"}],
    reads:[{read:"tft.clearScreen();"},{read:"tft.setCursor(0, 0);"}, {read:"tft.setTextColor(RED);"}, {read:"tft.setTextScale(1);"}, {read:"tft.println(text);"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"23"},
              {nr:4, name:"18"},
              {nr:5, name:"17"},
              {nr:6, name:"16"},
              {nr:7, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"OLED SPI display",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"MOSI"},
              {nr:4, name:"CLK"},
              {nr:5, name:"CS"},
              {nr:6, name:"DC"},
              {nr:7, name:"RST"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:5}, to:{dev:2,pin:5}, color:"rgb(255,255,0)"},
            {from:{dev:1, pin:6}, to:{dev:2,pin:6}, color:"rgb(0,255,255)"},
            {from:{dev:1, pin:7}, to:{dev:2,pin:7}, color:"rgb(255,0,255)"},
        ],
    }
  },
  
  {"active":true, "value":6,"text":"7Seg 4-stellig 1'2''","type":"Aktor","picture":"7seg12.jpg","name":"7-Seg Display","desc":"7-Segment Display zum Anzeigen numerischer Werte",
    dashoptions:[2],
    pack:[6,19,51,54],
    libs:[{lib:"Adafruit_GFX.h"}, {lib:"Adafruit_LEDBackpack.h"}], 
    pins:[],
    defs:[{def:"Adafruit_7segment matrix = Adafruit_7segment();"}],
    setups:[{setup:"matrix.begin(0x70);"}], 
    loops:[{loop:"matrix.println(1234);"}, {loop:"matrix.writeDisplay();"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Text",
    labels:["wert"],
    readsims:[{readsim:"int wert = 12;"}],
    reads:[{read:"matrix.println(wert);"}, {read:"matrix.writeDisplay();"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"GND"},
              {nr:3, name:"21"},
              {nr:4, name:"22"},
              {nr:5, name:"3.3V"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"7Seg I2C display",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"GND"},
              {nr:3, name:"SDA"},
              {nr:4, name:"CLK"},
              {nr:5, name:"IO"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:5}, to:{dev:2,pin:5}, color:"rgb(255,0,0)"},
        ],
    }
  },
  
  {"active":true, "value":7,"text":"MQ-x","type":"Sensor","picture":"mqgas.jpg","name":"Gas","desc":"Verschiedene MQ-x Typen unterstützen die Erkennung von Alkohol, Verschmutzung, Ozon, etc.", defaultChecked:true,
    dashoptions:[4,5,8,15],
    pack:[7,19,51,54],
    libs:[], 
    pins:[{piname:"Pin", pinr:34, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[], 
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"34"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"MQ-x",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"A0"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
        ],
    }
  },
  
  {"active":true, "value":8,"text":"ESP32 Bodensensor","type":"Sensor","picture":"espsoil.jpg","name":"ESP32 kombiniert","desc":"ESP32 mit integrierten Sensoren für Temperatur, Luft- & Bodenfeuchtigkeit",
    dashoptions:[9],
    pack:[14,19,51,54],
    libs:[{lib:"DHT.h"}], 
    pins:[{piname:"gPin", pinr:32, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"temp"}],
    defs:[{def:"const int DHTPIN = 22;"}, {def: "#define DHTTYPE DHT11"}, {def: "DHT dht(DHTPIN, DHTTYPE);"}],
    setups:[{setup:"dht.begin();"}],
    loops:[{loop:"float temperatur = dht.readTemperature();"}, {loop:"float luftfeuchtigkeit = dht.readHumidity();"}, {loop:"float bodenfeuchtigkeit = analogRead(gPin);"}, {loop:'Serial.print("Temperatur: ");'}, {loop:"Serial.println(temperatur);"}, {loop:'Serial.print("Luftfeuchtigkeit: ");'}, {loop:"Serial.println(luftfeuchtigkeit);"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik n-Dim",
    labels:["temperatur", "luftfeuchtigkeit", "bodenfeuchtigkeit"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"22"},
              ]
          },
        ],
      connections:[
        ],
    }      
  },
    
  {"active":true, "value":9,"text":"Photo-Widerstand","type":"Sensor","picture":"lightresistance.jpg","name":"Licht","desc":"lichtempfindlicher Widerstand",
    dashoptions:[4,5,8],
    pack:[9,19,51,54,57],
    libs:[], 
    pins:[{piname:"photoPin", pinr:34, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[], 
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"34"},
              {nr:3, name:"GND"}
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"Photo-Widerstand",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Pull Down 10K",
            pins:[
              {tnr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:2, pin:2}, to:{dev:3,pin:1}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:3}, to:{dev:3,pin:2}, color:"rgb(0,0,0)"},
        ],
    }  
  },

  {"active":true, "value":10,"text":"Widerstand (feuchtigkeitsabhängig)","type":"Sensor","picture":"rain.jpg","name":"Regen","desc":"wasserempfindlicher (Tropfen) Widerstand",
    dashoptions:[5,8],
    pack:[10,19,51,54],
    libs:[], 
    pins:[{piname:"rainPin", pinr:34, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[], 
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"34"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Regensensor",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"A0"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
        ],
    }    
  },
  
  {"active":true, "value":11,"text":"PIR Sensor","type":"Sensor","picture":"pir.png","name":"Bewegung","desc":"erkennt Bewegung auf Basis von Infrarot",
    dashoptions:[1],
    pack:[11,19,51,54],
    libs:[], 
    pins:[{piname:"infraPin", pinr:34, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[], 
    loops:[],
    funcs:[],
    controls:{  trigger:true},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"5"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"PIR Sensor",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"Data"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
        ],
    }      
  },

  {"active":true, "value":12,"text":"HC-SR04","type":"Sensor","picture":"ultrasonic.jpg","name":"Distanz","desc":"ermittelt auf Basis von Schall (senden / empfangen) Abstände (bis ca. 3m)",
    dashoptions:[5,8],
    pack:[12,19,51,54],
    libs:[], 
    pins:[],
    defs:[{def:"#define trigPin 34"}, {def: "#define echoPin 35"}, {def: "long duration, distance; // Duration used to calculate distance"}],
    setups:[{setup: "pinMode(echoPin, INPUT);"}, {setup:"pinMode(trigPin, OUTPUT);"}],
    loops:[{loop:"digitalWrite(trigPin, LOW);"}, {loop:"delayMicroseconds(2);"}, {loop: "digitalWrite(trigPin, HIGH);"}, {loop:"delayMicroseconds(10);"}, {loop:"digitalWrite(trigPin, LOW);"}, {loop:"duration = pulseIn(echoPin, HIGH);"}, {loop:"long wert = duration/58.2;"}, {loop:"Serial.println('Wert: '+String(wert));"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"4"},
              {nr:4, name:"5"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"PIR Sensor",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"Trigger"},
              {nr:4, name:"Echo"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
        ],
    }      
  },
  
  {"active":true, "value":13,"text":"DS18B20","type":"Sensor","picture":"temperatur.jpg","name":"Temperatur","desc":"wasserdichter Temperatursensor",
    dashoptions:[4,5,8],
    pack:[13,19,51,54],
    libs:[{lib:"OneWire.h"}, {lib:"DallasTemperature.h"}], 
    pins:[{piname:"ONE_WIRE_BUS", pinr:5, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"temp"}],
    defs:[{def: "OneWire oneWire(ONE_WIRE_BUS);"}, {def: "DallasTemperature sensors(&oneWire);"}],
    setups:[{setup: "sensors.begin();"}],
    loops:[{loop:"sensors.requestTemperatures();"}, {loop:"float Grad = sensors.getTempCByIndex(0);"}, {loop:"Serial.println('Temperatur: ' + String(Grad));"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"DS18B20",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"GND"},
              {nr:2, name:"Data"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Pull Up 4.7K",
            pins:[
              {tnr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:2, pin:1}, to:{dev:3,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:2, pin:3}, to:{dev:3,pin:2}, color:"rgb(0,255,0)"},
        ],
    }  
  },
  
  {"active":true, "value":14,"text":"DHT11/DHT12","type":"Sensor","picture":"humidity.jpg","name":"Luftfeuchtigkeit","desc":"Kombinierter Luftfeuchtigkeits- & Temperatursensor",
    dashoptions:[9],
    pack:[14,19,51,54],
    libs:[{lib:"DHT.h"}], 
    pins:[{piname:"DHTPIN", pinr:22, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"temp"}],
    defs:[{def: "#define DHTTYPE DHT11"}, {def: "DHT dht(DHTPIN, DHTTYPE);"}],
    setups:[],
    loops:[{loop:"float temperatur = dht.readTemperature()"}, {loop:"float luftfeuchtigkeit = dht.readHumidity()"}, {loop:"Serial.println('Temperatur: '+String(temperatur))"}, {loop:"Serial.println('Luftfeuchtigkeit: '+String(luftfeuchtigkeit))"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik n-Dim",
    labels:["temperatur", "luftfeuchtigkeit"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"22"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"DHT11/12",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"Data"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
        ],
    }      
  },
  
  {"active":true, "value":15,"text":"On/Off Schalter","type":"Sensor","picture":"switch.jpg","name":"Schalter","desc":"Schalter (Schaltzustand bleibt bestehen)",
    dashoptions:[1],
    pack:[15,19,51,54],
    libs:[], 
    pins:[{piname:"pushPIN", pinr:34, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:true},
    dash:"Schalter",
    labels:["switch"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"Schalter",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Pull Down 10K",
            pins:[
              {tnr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:2}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:3,pin:2}, color:"rgb(0,255,0)"},
        ],
    }  
  },
  
  {"active":true, "value":16,"text":"Push Button","type":"Sensor","picture":"pushbutton.jpg","name":"Taster","desc":"Impulsetaster (Schaltzustand bleibt nicht bestehen)",
    dashoptions:[1],
    pack:[16,19,51,54],
    libs:[], 
    pins:[{piname:"pushPIN", pinr:34, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:true},
    dash:"Schalter",
    labels:["switch"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"Taster",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Pull Down 10K",
            pins:[
              {tnr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:2}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:3,pin:2}, color:"rgb(0,255,0)"},
        ],
    }  
  },
  
  {"active":true, "value":17,"text":"Relais 3/5V 220V","type":"Aktor","picture":"relais.jpg","name":"Relais","desc":"schaltet 220V auf der Basis von Niedervolt (3V/5V)",
    dashoptions:[1,15],
    pack:[17,19,51,54],
    libs:[], 
    pins:[{piname:"relaisPin", pinr:34, pinmode:"OUTPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[{loop:"digitalWrite(relaisPin, HIGH);"},{loop:"delay(1000);"},{loop:"digitalWrite(relaisPin, LOW);"}],
    funcs:[],
    controls:{ trigger:false},
    dash:"Schalter",
    labels:["wert"],
    readsims:[{readsim:"int wert = 1;"}],
    reads:[{read:"if( wert > 0) {"}, {read:"digitalWrite(relaisPin, HIGH);}"}, {read:"else {"}, {read:"digitalWrite(relaisPin, LOW); }"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Relais",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"Data"},
              {nr:4, name:"N/O"},
              {nr:5, name:"N/C"},
              {nr:6, name:"220 V"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"220V Verbraucher",
            pins:[
              {nr:1, name:"L"},
              {nr:2, name:"N"},
              ]
          },
          {
            dev:4,
            typ:"small",
            name:"220V ",
            pins:[
              {nr:1, name:"L"},
              {nr:2, name:"N"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:3, pin:1}, to:{dev:4,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:2, pin:6}, to:{dev:4,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:5}, to:{dev:3,pin:2}, color:"rgb(0,0,0)"},
        ],
    }  
  },
  
  {"active":true, "value":18,"text":"Nokia 5110","type":"Aktor","picture":"nokia.jpg","name":"LCD Display","desc":"Display auf Basis des historischen NOKIA Telefon-Displays ",
    dashoptions:[0,6,7],
    pack:[18,19,51,54],
    libs:[{lib:"SPI.h"}, {lib:"Adafruit_GFX.h"}, {lib:"Adafruit_PCD8544.h"}], 
    pins:[],
    defs:[{def:"/* Declare LCD object for SPI  Adafruit_PCD8544(CLK,DIN,D/C,CE,RST); */"},{def:"Adafruit_PCD8544 display = Adafruit_PCD8544(18, 23, 4, 15, 2);"}],
    setups:[{setup:"display.begin();"}, {setup:"display.setContrast(60);"}],
    loops:[{loop:"display.clearDisplay();"},{loop:"display.display();"},{loop:"delay(1000)"}, {loop:"display.setTextColor(WHITE, BLACK);"}, {loop:"display.setCursor(0,1);"}, {loop:"display.setTextSize(2);"}, {loop:"display.println('hello from IOTeasy...');"} ],
    funcs:[],
    controls:{  trigger:false},
    dash:"Text",
    labels:["text"],
    readsims:[{readsim:"String text = 'Hello!';"}],
    reads:[{read:"display.clearDisplay();"},{read:"display.display();"},{read:"delay(1000);"}, {read:"display.setTextColor(WHITE, BLACK);"}, {read:"display.setCursor(0,1);"}, {read:"display.setTextSize(2);"}, {read:"display.println(text);"},{read:"display.display();"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"23"},
              {nr:4, name:"18"},
              {nr:5, name:"15"},
              {nr:6, name:"4"},
              {nr:7, name:"2"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Nokia 5110 SPI display",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"MOSI"},
              {nr:4, name:"CLK"},
              {nr:5, name:"CE"},
              {nr:6, name:"DC"},
              {nr:7, name:"RST"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:5}, to:{dev:2,pin:5}, color:"rgb(255,255,0)"},
            {from:{dev:1, pin:6}, to:{dev:2,pin:6}, color:"rgb(0,255,255)"},
            {from:{dev:1, pin:7}, to:{dev:2,pin:7}, color:"rgb(255,0,255)"},
        ],
    }
  },
  
  {"active":false, "value":19,"text":"ESP32","type":"Microprozessor","picture":"esp32.jpg","name":"Spezial","desc":"leistungsfähiger MC mit Bluetooth und WLAN Funktionalität"},
  {"active":false, "value":20,"text":"ESP8266","type":"Microprozessor","picture":"esp8266.jpg","name":"Microprozessor ESP8266","desc":"leistungsfähiger MC mit WLAN Funktionalität"},

  {"active":false, "value":21,"text":"RGB Matrix HUB-75","type":"Aktor","picture":"rgbmatrix.jpg","name":"DRGB Matrix","desc":"InDoor/Outdoor Anzeigen auf Basis LED Technologie (können zu grössenren Anzeigeflächen kombiniert werden)"},
  
  {"active":false, "value":22,"text":"TCS3200","type":"Sensor","picture":"color.png","name":"Farben","desc":"Sensor, welcher Farben erkennen kann"},
  
  {"active":true, "value":23,"text":"MPU-6050","type":"Sensor","picture":"gyros.jpg","name":"Bewegung","desc":"erkennt dreidimensionale Bewegungen im Raum",
    dashoptions:[9],
    pack:[23,19,51,54],
    libs:[{lib:"Adafruit_MPU6050.h"}, {lib:"Adafruit_Sensor.h"}, {lib:"Wire.h"}], 
    pins:[],
    calcs:[{calc:"sensors_event_t a, g, temp;"}, {calc:"mpu.getEvent(&a, &g, &temp);"}, {calc:"int wert = g.gyro.x + g.gyro.y + g.gyro.z;"}],
    defs:[{def:"Adafruit_MPU6050 mpu;"}],
    setups:[{setup:"mpu.begin();"}, {setup:"delay(1000);"}, {setup:"mpu.setAccelerometerRange(MPU6050_RANGE_8_G);"}, {setup:"mpu.setGyroRange(MPU6050_RANGE_500_DEG);"}, {setup:"mpu.setFilterBandwidth(MPU6050_BAND_21_HZ);"} ],
    loops:[{loop:"float x = g.gyro.x;"}, {loop:"float y = g.gyro.y;"}, {loop:"float z = g.gyro.z;"}, {loop:'Serial.print("Rotation X: ");'}, {loop:'Serial.print(g.gyro.x);'}, {loop:'Serial.print(", Y: ");'}, {loop:'Serial.print(g.gyro.y);'}, {loop:'Serial.print(", Z: ");'}, {loop:'Serial.println(g.gyro.z);'}],
    funcs:[],
    controls:{  trigger:true},
    dash:"Graphik n-Dim",
    labels:["x", "y", "z"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"22"},
              {nr:4, name:"21"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"MPU650",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"SCL"},
              {nr:4, name:"SDA"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,255,0)"},
        ],
    }      
  },

  {"active":false, "value":24,"text":"ePaper Display 4.2'","type":"Aktor","picture":"epaper.jpg","name":"ePaper Display","desc":"e_paper Display ermöglichen die Anzeige von Informationen ohne permanente Stromzufuhr"},

  {"active":true, "value":25,"text":"ESP32 LORA TTGO","type":"Microprozessor","picture":"esp32loragps.jpg","name":"ESP32 LORA/GPS","desc":"LoRa-fähiger ESP32 mit integriertem GPS Sensor"},

  {"active":true, "value":26,"text":"NEO6M","type":"Sensor","picture":"gps.jpg","name":"GEO","desc":"empfängt GPS Koordinaten von Satelliten",
    dashoptions:[10],
    pack:[26,19,51,54],
    libs:[{lib:"TinyGPS++.h"}], 
    pins:[],
    defs:[{def:"#define SerialGPS Serial2"}, {def:"TinyGPSPlus gps;"}],
    setups:[{setup:"Serial2.begin(9600, SERIAL_8N1, 16, 17); //17-TX 18-RX" }, {setup:"delay(5000);"}],
    loops:[{loop:"while (Serial2.available());"}, {loop:"gps.encode(Serial2.read());"}, {loop:"float latitude = gps.location.lat();"}, {loop:"float longitude = gps.location.lng();"}, {loop:"Serial.println(gps.location.lat(), 5);"}, {loop:"Serial.println(gps.location.lng(), 5);"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Karte",
    labels:["longitude","latitude"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"16"},
              {nr:3, name:"17"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"GPS Neo 6M",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"TX"},
              {nr:3, name:"RX"},
              {nr:4, name:"5V"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"5V extern",
            pins:[
              {tnr:1, name:"GND"},
              {nr:2, name:"5V"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,0,255)"},
            {from:{dev:2, pin:1}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:4}, to:{dev:3,pin:2}, color:"rgb(255,0,0)"},
        ],
    }  
  },

  {"active":true, "value":27,"text":"SG90","type":"Aktor","picture":"servo.jpg","name":"Servo Motor","desc":"Motor ermöglicht Bewegungen mit Winkelangabe (range 0-180°)",
    dashoptions:[2,15],
    pack:[27,19,51,54],
    libs:[{lib:"ESP32Servo.h"}], 
    pins:[],
    defs:[{def:"#define servoPIN 5"}, {def:"Servo myServo;"}],
    setups:[{setup:"myServo.setPeriodHertz(50);"},{setup:"myServo.attach(servoPIN);"}],
    loops:[{loop:"myServo.write(90);"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Regler",
    labels:["wert"],
    readsims:[{readsim:"int wert = 100;"}],
    reads:[{read:"myServo.write(wert);"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"SG90 Servo",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"Vin"},
              {nr:3, name:"Data"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,0,255)"},
        ],
    }  
  },
  
  {"active":true, "value":28,"text":"ESP32 cam","type":"Sensor","picture":"esp32cam.jpg","name":"ESP32 Kamera","desc":"ESP32 mit integrierter Kamera",
    dashoptions:[0,13],
    pack:[28,19,51,54],
    libs:[], 
    pins:[],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Kamera Stream",
    labels:["text"],
    readsims:[],
    reads:[],
    wiring: {
      devices:[],
      connections:[],
    }
  },

  {"active":true,"value":29,"text":"M5 ESP32","type":"Spezial","picture":"m5stack.jpg","name":"M5 ESP32","desc":"ESP32 Komplettsystem mit OLED Display und drei Tastern",
    dashoptions:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
    pack:[29,19,51,54],
    libs:[], 
    pins:[],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Text",
    labels:["text"],
    readsims:[],
    reads:[],
    wiring: {
      devices:[],
      connections:[],
    }
  },

  {"active":true,"value":30,"text":"M5 cam","type":"Sensor","picture":"m5cam.jpg","name":"ESP32 Kamera","desc":"ESP 32 mit integrierter Kamera",
    dashoptions:[0,13],
    pack:[30,19,51,54],
    libs:[], 
    pins:[],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Kamera Stream",
    labels:["text"],
    readsims:[],
    reads:[],
    wiring: {
      devices:[],
      connections:[],
    }
  },

  {"active":true, "value":31,"text":"Potentiometer 10KOhm","type":"Sensor","picture":"potentiometer.jpg","name":"Widerstand (flex)","desc":"stufenlos veränderbarer Widerstand (range 0-10 KOhm)",
    dashoptions:[2,5,8],
    pack:[31,19,51,54],
    libs:[], 
    pins:[{piname:"potPIN", pinr:34, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Regler",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"34"},
              {nr:3, name:"5V"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Potentiometer",
            pins:[
              {nr:1, name:"left"},
              {nr:2, name:"mid"},
              {nr:3, name:"right"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(255,0,0)"},
        ],
    }
  },
  
  {"active":true, "value":32,"text":"sonic","type":"Sensor","picture":"schallsensor.jpg","name":"Schall","desc":"Sensor mit analogem (Mirophon) und digitalem Ausgangssignal zur Identifikatio von Schall",
    dashoptions:[5,8],
    pack:[32,19,51,54],
    libs:[], 
    pins:[{piname:"noisePIN", pinr:34, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"34"},
              {nr:3, name:"5V"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Schallsensor",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"A0"},
              {nr:3, name:"5V"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(255,0,0)"},
        ],
    }
  },
  
  {"active":true, "value":33,"text":"hall","type":"Sensor","picture":"hall.jpg","name":"Magnet","desc":"Sensor, welche auf magnetische Wellen reagiert (analoges Ausgangssignal)",
    dashoptions:[5,8],
    pack:[33,19,51,54],
    libs:[], 
    pins:[{piname:"hallPIN", pinr:34, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"34"},
              {nr:3, name:"5V"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Magnetsensor",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"A0"},
              {nr:3, name:"5V"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(255,0,0)"},
        ],
    }

  },
  
  {"active":true, "value":34,"text":"CCS811","type":"Sensor","picture":"ccs811.jpg","name":"CO2","desc":"Sensor für Kohlendyoxid (CO2) - gemessen werden ppm \u0026 ppb",
    dashoptions:[5,8],
    pack:[34,19,51,54],
    libs:[{lib:"Adafruit_CCS811.h"}], 
    pins:[],
    defs:[],
    setups:[{setup:"ccs.begin()"}, {setup:"delay(2000)"}],
    loops:[{loop:"float wert = ccs.geteCO2()"}, {loop:"Serial.println('CO2 :'+String(wert))"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik n-Dim",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"21"},
              {nr:4, name:"22"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"CO2 Sensor",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"WAKE"},
              {nr:4, name:"SDA"},
              {nr:5, name:"CLK"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:4}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:5}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:3}, color:"rgb(0,0,0)"},
        ],
    }
  },

  {"active":true, "value":35,"text":"BMP280","type":"Sensor","picture":"bmp280.jpg","name":"Luftdruck","desc":"Sensor, welcher Temperatur, Luftdruck in hPa und Höhe über Meeresspiegel misst",
    dashoptions:[9],
    pack:[35,19,51,54],
    libs:[{lib:"Wire.h"}, {lib:"Adafruit_Sensor.h"}, {lib:"Adafruit_BMP280.h"}], 
    pins:[],
    defs:[{def:"#define BMP_SDA 21"}, {def:"#define BMP_SCL 22"}, {def:"Adafruit_BMP280 bmp; //I2C"}],
    setups:[{setup:"bmp.begin();"}],
    loops:[{loop:"String temperatur = String(bmp.readTemperature())"},{loop:"String luftdruck = String(bmp.readPressure())"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik n-Dim",
    labels:["temperatur", "luftdruck"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"21"},
              {nr:4, name:"22"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Luftdruck Sensor",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"SDO"},
              {nr:4, name:"SDA"},
              {nr:5, name:"CLK"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:4}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:5}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:1}, to:{dev:2,pin:3}, color:"rgb(255,0,0)"},
        ],
    }
  },
  
  {"active":true, "value":36,"text":"WS2812 Neopixel Matrix","type":"Aktor","picture":"neomatrix.jpg","name":"LED Matrix","desc":"Individuell ansteuerbare LEDs im Matrix-Format (zu grösseren Flächen kombinierbar)",
    dashoptions:[3,15],
    pack:[36,19,51,54],
    libs:[{lib:"Adafruit_NeoPixel.h"},{lib:"Adafruit_NeoMatrix.h"}], 
    pins:[{piname:"ledPIN", pinr:34, pinmode:"OUTPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"} ],
    defs:[{def:"Adafruit_NeoMatrix matrix = Adafruit_NeoMatrix(8, 8, ledPin,"},{def:"NEO_MATRIX_COLUMNS + NEO_MATRIX_ZIGZAG,"},{def:"NEO_GRB            + NEO_KHZ800);"}, {def:"int x = matrix.width();"}, {def:"String scrollMessage = '';"}],
    setups:[{setup:"matrix.begin();"},{setup:"matrix.setBrightness(100);"},{setup:"matrix.setTextWrap(false);"},{setup:"matrix.setTextColor(matrix.Color(255,0,0));"}],
    loops:[{loop:"scroll1(scrollMessage);"}],
    funcs:[{func:"void scroll1(String a) {"},{func:"matrix.fillScreen(0);"},{func:"matrix.setCursor(x, 0);"},{func:"matrix.print((a));"},{func:"if(--x < -128) {"},{func:"x = matrix.width();"},{func:"}"},{func:"matrix.show();"},{func:"delay(100);"}, {func:"}"}],
    controls:{  trigger:false},
    dash:"Text",
    labels:["text"],
    readsims:[],
    reads:[{read:"scroll1(texts);"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"Pin 5"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Neopixel Matrix",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"Data"},
              {nr:3, name:"5V"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Power Supply",
            pins:[
              {nr:1, name:"GND"}, 
              {nr:2, name:"5V"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:2, pin:1}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:3}, to:{dev:3,pin:2}, color:"rgb(255,0,0)"},
        ],
    }
  },
  
  {"active":true, "value":65,"text":"LED Matrix monochrom","type":"Aktor","picture":"max7219.jpg","name":"LED Matrix MAX7219","desc":"LED Bausteine (8x8) mit SPI Interface (chainable)",
    dashoptions:[6,7],
    pack:[65,19,51,54],
    libs:[{lib:"MD_Parola.h"},{lib:"MD_MAX72xx.h"},{lib:"SPI.h"}], 
    pins:[],
    defs:[{def:"// Uncomment according to your hardware type"},{def:"//#define HARDWARE_TYPE MD_MAX72XX::FC16_HW"},{def:"#define HARDWARE_TYPE MD_MAX72XX::GENERIC_HW"}, {def:"#define MAX_DEVICES 1"}, {def:"#define CS_PIN 5"}, {def:"MD_Parola Display = MD_Parola(HARDWARE_TYPE, CS_PIN, MAX_DEVICES);"}, {def:"int counter = 10;"}],
    setups:[{setup:"Display.begin();"},{setup:"Display.setIntensity(100);"}],
    loops:[{loop:"if (counter>0) {"},{loop:"counter=counter-1;}"},{loop:"else {"},{loop:"counter=10;}"},{loop:"Display.setTextAlignment(PA_CENTER);"},{loop:"Display.print(String(counter));"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Laufschrift",
    labels:["text"],
    readsims:[],
    reads:[{read:"Display.setTextAlignment(PA_CENTER);"}, {read:"Display.print(text);"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"VIN"},
              {nr:2, name:"GND"},
              {nr:3, name:"23"},
              {nr:4, name:"5"},
              {nr:5, name:"18"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"MAX7219",
            pins:[
              {nr:1, name:"VIN"},
              {nr:2, name:"GND"},
              {nr:3, name:"MOSI/DIN"},
              {nr:2, name:"CS"},
              {nr:3, name:"CLK"}
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:5}, to:{dev:2,pin:5}, color:"rgb(255,0,255)"},
        ],
    }
  },

  {"active":true, "value":37,"text":"KeyPad","type":"Sensor","picture":"matrixkeypad.jpg","name":"Taster (mahrfach)","desc":"KeyPad (Taster) zur Eingabe verschiedener Optionen",
    dashoptions:[0,9],
    pack:[37,19,51,54],
    libs:[], 
    pins:[{piname:"opt1PIN", pinr:14, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert1"}, {piname:"opt2PIN", pinr:15, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert2"}, {piname:"opt3PIN", pinr:16, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert3"}, {piname:"opt4PIN", pinr:17, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert4"}],
    calcs:[{calc:"int wert = wert1 + wert2 + wert3 + wert4;"}],
    defs:[],
    setups:[],
    loops:[{loop:"// Logik für Kumulation tbd "}, {loop:"//..."}],
    funcs:[],
    controls:{  trigger:true},
    dash:"Schalter",
    labels:["wert1", "wert2", "wert3", "wert4"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"5V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"Key Pad",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"Pin 2"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Pull Down 10K",
            pins:[
              {tnr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:2}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:2}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:3,pin:2}, color:"rgb(0,255,0)"},
        ],
    }  
  },
  
  {"active":true, "value":38,"text":"TAL220","type":"Sensor","picture":"waage.jpg","name":"Wägezelle","desc":"elektronische Waage zur Gewichtsermittlung",
    dashoptions:[5,8],
    pack:[38,19,51,54],
    libs:[{lib:"HX711.h"}], 
    pins:[],
    defs:[{def:"#define DOUTPIN 14"}, {def:"#define PD_SCKPIN 15"}, {def:"HX711 scale(DOUTPIN, PD_SCKPIN)"}],
    setups:[{setup:"scale.read_average(20)"}, {setup:"scale.get_value(5)"}, {setup:"scale.get_units(5)"}, {setup:"scale.set_scale(2280.f)"}, {setup:"scale.tare()"}, {setup:"scale.read()"}],
    loops:[{loop:"scale.power_up()"}, {loop:"float wert = scale.get_units(10)"}, {loop:"scale.power_down()"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"14"},
              {nr:4, name:"15"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Wägezelle",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"SCK"},
              {nr:4, name:"DT"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
        ],
    }
  },
  
  {"active":true, "value":39,"text":"KY-039","type":"Sensor","picture":"heartrate.jpg","name":"Herzschlag","desc":"Herzschlag-Sensor, welcher aufgrund von 3-Punkt-Messungen die Erstellung eines Kardiogramms ermöglicht",
    dashoptions:[5,8],
    pack:[39,19,51,54],
    libs:[], 
    pins:[{piname:"loplusPIN", pinr:35, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"loplus"},{piname:"lominusPIN", pinr:34, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"lominus"},{piname:"ratePIN", pinr:34, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"rate1"}],
    defs:[],
    setups:[],
    loops:[{loop:"if((digitalRead(lominusPIN) == HIGH) || (digitalRead(loplusPIN) == HIGH)) {"}, {loop:"int wert = analogRead(ratePIN)"}, {loop:"Serial.println(wert)"}, {loop:"}"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"4"},
              {nr:4, name:"5"},
              {nr:5, name:"34"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Herzschlag Sensor",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"LO+"},
              {nr:4, name:"LO-"},
              {nr:5, name:"OUT"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:5}, to:{dev:2,pin:5}, color:"rgb(0,255,255)"},
        ],
    }
  },
  
  {"active":true, "value":40,"text":"IR flame 1-5 channel","type":"Sensor","picture":"firesensor5c.jpg","name":"Feuer","desc":"erkennt Feuer und lokalisiert Flammen im Winkel von 180 Grad (5-Kanal)",
    dashoptions:[1],
    pack:[40,19,51,54],
    libs:[], 
    pins:[{piname:"fireC1PIN", pinr:13, pinmode:"INPUT", pinread:"analogRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:false},
    dash:"Schalter",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              {nr:4, name:"34"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Feuer Sensor",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"D1"},
              {nr:4, name:"A1"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
        ],
    }
  },
  
  {"active":true, "value":41,"text":"Vibrationsmotor","type":"Aktor","picture":"virba.jpg","name":"Vibrationsmotor","desc":"Der Vibrationsmotor ermöglicht haptische Information und Alarmierung",
    dashoptions:[1,15],
    pack:[41,19,51,54],
    libs:[], 
    pins:[{piname:"vibraPin", pinr:34, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[{loop:"digitalWrite(vibraPin, HIGH);"}, {loop:"delay(1000);"}, {loop:"digitalWrite(vibraPin, LOW);"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Schalter",
    labels:["wert"],
    readsims:[{readsim:"int wert = 1;"}],
    reads:[{read:"if( wert > 0) {"}, {read:"digitalWrite(relaisPin, HIGH);}"}, {read:"else {"}, {read:"digitalWrite(relaisPin, LOW); }"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Vibra Motor",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"D1"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
        ],
    }
  },

  {"active":true, "value":42,"text":"Reed","type":"Sensor","picture":"reed.jpg","name":"Magnetschalter","desc":"Der Magnetschalter (Reedsensor) löst den Schaltungszustand über ein Magnet aus (Alarmsysteme)",
    dashoptions:[1],
    pack:[42,19,51,54],
    libs:[], 
    pins:[{piname:"reedPIN", pinr:34, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:true},
    dash:"Schalter",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Magnet Schalter",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"D1"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
        ],
    }
  },

  {"active":true, "value":43,"text":"Heltec LORA Board OLED","type":"Spezial","picture":"loraheltecoled.jpg","name":"ESP LORA","desc":"LORA Board mit ESP32 Microprozessor, LORA Chip und OLED Display"},

  {"active":true, "value":44,"text":"Y25/Y26 Wassserspiegel","type":"Sensor","picture":"waterlevel.jpg","name":"Wasserspiegel","desc":"erkennt den Wasserspiegel kontaktlos und kann ausserhalb des Waserbehälters platziert werden",
    dashoptions:[1],
    pack:[44,19,51,54],
    libs:[], 
    pins:[{piname:"levelPIN", pinr:34, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:true},
    dash:"Schalter",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Wasserspiegel Schalter",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:3, name:"D1"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(255,255,0)"},
        ],
    }
  },
  
  {"active":true, "value":45,"text":"Mercury Switch","type":"Sensor","picture":"mercury.jpg","name":"Erschütterung","desc":"Erkennt mechanische Bewegungen/Erschütterungen - Winkelsensor, Tilt-Sensor, Taster",
    dashoptions:[1],
    pack:[45,19,51,54],
    libs:[], 
    pins:[{piname:"tiltPIN", pinr:34, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[],
    loops:[],
    funcs:[],
    controls:{  trigger:true},
    dash:"Schalter",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"5"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Tilt Sensor",
            pins:[
              {nr:1, name:"Vin"},
              {nr:3, name:"D1"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"Pull Down 10K",
            pins:[
              {nr:1, name:"Pin 1"},
              {nr:2, name:"Pin 2"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:2}, color:"rgb(255,255,0)"},
            {from:{dev:1, pin:2}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:2}, to:{dev:3,pin:2}, color:"rgb(255,255,0)"},
        ],
    }
  },

  {"active":true, "value":46,"text":"JSN SR04","type":"Sensor","picture":"ultrawaterproof.jpg","name":"Distanz","desc":"analog zum klassischen Distanz-Sensor aber wasserdicht und deshalb auch für den Outdoor-Einsatz geeignet",
    dashoptions:[5,8],
    pack:[46,19,51,54],
    libs:[], 
    pins:[{piname:"echoPin", pinr:34, pinmode:"INPUT", pinread:"digitalRead", pintype:"int", pinvar:"wert"}, {piname:"trigPin", pinr:35, pinmode:"OUTPUT", pinread:"digitalRead", pinwrite: "digitalWrite", pintype:"int", pinvar:"wert"}],
    defs:[{def: "long duration, distance; // Duration used to calculate distance"}],
    setups:[],
    loops:[{loop:"digitalWrite(trigPin, LOW)"}, {loop:"delayMicroseconds(2)"}, {loop: "digitalWrite(trigPin, HIGH)"}, {loop:"delayMicroseconds(10)"}, {loop:"digitalWrite(trigPin, LOW)"}, {loop:"duration = pulseIn(echoPin, HIGH)"}, {loop:"long wert = duration/58.2"}, {loop:"Serial.println('Wert: '+String(wert))"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Graphik",
    labels:["wert"],
    readsims:[],
    reads:[],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"4"},
              {nr:4, name:"5"}
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"Ultraschall Sensor",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              {nr:3, name:"Trigger"},
              {nr:4, name:"Echo"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
        ],
    }      
  },

  {"active":true, "value":47,"text":"TB6600","type":"Aktor","picture":"tb6600.jpg","name":"Motor-Driver","desc":"ermöglicht die einfache Ansteuerung von Schrittmotoren; DIP Switch bspw . NEMA17",
    dashoptions:[2,15],
    pack:[47,48,19,51,54],
    libs:[], 
    pins:[{piname:"dirPin", pinr:0, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"}, {piname:"stepPin", pinr:4, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"},{piname:"groundPin", pinr:0, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[{setup:"digitalWrite(groundPin, LOW);"}],
    loops:[{loop:"int revolution = 10;"},{loop:"int speed = 100;"},{loop:"digitalWrite(dirPIN, HIGH); //HIGH clockwise LOW anticlockwise"}, {loop:"for(int k=0; k < revolution; k++) {"}, {loop:"for(int i=0; i<200; i++) {"}, {loop:"digitalWrite(stepPIN, HIGH);"}, {loop:"delayMicroseconds(mapspeed);"}, {loop:"digitalWrite(stepPIN, LOW);"}, {loop:"delayMicroseconds(mapspeed);"}, {loop:"}"}, {loop:"}"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Schrittmotor",
    labels:["wert"],
    readsims:[{readsim:"int wert = 100;"}],
    reads:[{read:"int speed = 100;"},{read:"digitalWrite(dirPIN, HIGH); //HIGH clockwise LOW anticlockwise"}, {read:"for(int k=0; k < wert; k++) {"}, {read:"for(int i=0; i<200; i++) {"}, {read:"digitalWrite(stepPIN, HIGH);"}, {read:"delayMicroseconds(mapspeed);"}, {read:"digitalWrite(stepPIN, LOW);"}, {read:"delayMicroseconds(mapspeed);"}, {read:"}"}, {read:"}"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"15"},
              {nr:3, name:"2"},
              {nr:4, name:"0"},
              {nr:5, name:"4"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"TB6600",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"Pul+"},
              {nr:3, name:"Dir+"},
              {nr:4, name:"Dir-"},
              {nr:5, name:"Pul-"},
              {nr:6, name:"5 V"},
              {nr:7, name:"B-"},
              {nr:8, name:"B+"},
              {nr:9, name:"A-"},
              {nr:10, name:"A+"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"5V",
            pins:[
              {nr:1, name:"-"},
              {nr:2, name:"+"},
              ]
          },
          {
            dev:4,
            typ:"small",
            name:"NEMA",
            pins:[
              {nr:1, name:"1"},
              {nr:2, name:"2"},
              {nr:3, name:"3"},
              {nr:4, name:"4"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,255,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,255,255)"},
            {from:{dev:1, pin:5}, to:{dev:2,pin:5}, color:"rgb(0,0,255)"},

            {from:{dev:2, pin:1}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:6}, to:{dev:3,pin:2}, color:"rgb(255,0,0)"},

            {from:{dev:2, pin:7}, to:{dev:4,pin:1}, color:"rgb(0,0,255)"},
            {from:{dev:2, pin:8}, to:{dev:4,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:2, pin:9}, to:{dev:4,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:2, pin:10}, to:{dev:4,pin:4}, color:"rgb(0,0,0)"},

        ],
    }  
  },
  
  {"active":true, "value":48,"text":"NEMA17","type":"Aktor","picture":"nema.jpg","name":"Schrittmotor","desc":"Schrittmotor bzw. Stepper können präzise Bewegungen umsetzen (Anschluss bspw. über TB6600 Motor-Driver Modul)",
    dashoptions:[2],
    pack:[48,47,19,51,54],
    libs:[], 
    pins:[{piname:"dirPin", pinr:0, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"}, {piname:"stepPin", pinr:4, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"},{piname:"groundPin", pinr:0, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[{setup:"digitalWrite(groundPin, LOW);"}],
    loops:[{loop:"int revolution = 10;"},{loop:"int speed = 100;"},{loop:"digitalWrite(dirPIN, HIGH); //HIGH clockwise LOW anticlockwise"}, {loop:"for(int k=0; k < revolution; k++) {"}, {loop:"for(int i=0; i<200; i++) {"}, {loop:"digitalWrite(stepPIN, HIGH);"}, {loop:"delayMicroseconds(mapspeed);"}, {loop:"digitalWrite(stepPIN, LOW);"}, {loop:"delayMicroseconds(mapspeed);"}, {loop:"}"}, {loop:"}"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Schrittmotor",
    labels:["Richtung","Geschwindigkeit"],
    readsims:[{readsim:"int wert = 100;"}],
    reads:[{read:"int speed = 100;"},{read:"digitalWrite(dirPIN, HIGH); //HIGH clockwise LOW anticlockwise"}, {read:"for(int k=0; k < wert; k++) {"}, {read:"for(int i=0; i<200; i++) {"}, {read:"digitalWrite(stepPIN, HIGH);"}, {read:"delayMicroseconds(mapspeed);"}, {read:"digitalWrite(stepPIN, LOW);"}, {read:"delayMicroseconds(mapspeed);"}, {read:"}"}, {read:"}"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"15"},
              {nr:3, name:"2"},
              {nr:4, name:"0"},
              {nr:5, name:"4"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"TB6600",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"Pul+"},
              {nr:3, name:"Dir+"},
              {nr:4, name:"Dir-"},
              {nr:5, name:"Pul-"},
              {nr:6, name:"5 V"},
              {nr:7, name:"B-"},
              {nr:8, name:"B+"},
              {nr:9, name:"A-"},
              {nr:10, name:"A+"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"5V",
            pins:[
              {nr:1, name:"-"},
              {nr:2, name:"+"},
              ]
          },
          {
            dev:4,
            typ:"small",
            name:"NEMA",
            pins:[
              {nr:1, name:"1"},
              {nr:2, name:"2"},
              {nr:3, name:"3"},
              {nr:4, name:"4"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,255,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,255,255)"},
            {from:{dev:1, pin:5}, to:{dev:2,pin:5}, color:"rgb(0,0,255)"},

            {from:{dev:2, pin:1}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:6}, to:{dev:3,pin:2}, color:"rgb(255,0,0)"},

            {from:{dev:2, pin:7}, to:{dev:4,pin:1}, color:"rgb(0,0,255)"},
            {from:{dev:2, pin:8}, to:{dev:4,pin:2}, color:"rgb(255,0,0)"},
            {from:{dev:2, pin:9}, to:{dev:4,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:2, pin:10}, to:{dev:4,pin:4}, color:"rgb(0,0,0)"},

        ],
    }      
  },
  
  {"active":true, "value":49,"text":"Sonoff TH16","type":"Aktor","picture":"sonoffTH16.jpg","name":"Relais","desc":"Das Sonoff TH16 bietet ESP8266 und Relais in einem Gehäuse. Im Gegensatz zum Vorgänger-Modell ist das TH16 auch CE konform",
    dashoptions:[1,15],
    pack:[49,19,51,54],
    libs:[], 
    pins:[{piname:"wlanPIN", pinr:13, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"}, {piname:"relaisPIN", pinr:12, pinmode:"OUTPUT", pinread:"digitalWrite", pintype:"int", pinvar:"wert"}],
    defs:[],
    setups:[{setup:"digitalWrite(wlanPIN, LOW);"}, {setup:"digitalWrite(relaisPIN, LOW);"}],
    loops:[{loop:"digitalWrite(relaisPIN, HIGH);"},{loop:"delay(1000);"},{loop:"digitalWrite(relaisPIN, LOW);"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Schalter",
    labels:["wert"],
    readsims:[{readsim:"int wert = 1;"}],
    reads:[{read:"if( wert > 0) {"}, {read:"digitalWrite(relaisPin, HIGH);}"}, {read:"else {"}, {read:"digitalWrite(relaisPin, LOW); }"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"SONOFF TH16",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"RX"},
              {nr:3, name:"TX"},
              {nr:4, name:"Vin"},
              {nr:5, name:"220V L (in)"},
              {nr:6, name:"220V N (in)"},
              {nr:7, name:"220V L (out)"},
              {nr:8, name:"220V N (out)"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"UART FTDI",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"TX"},
              {nr:3, name:"RX"},
              {nr:4, name:"Vin"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"220 V",
            pins:[
              {nr:1, name:"L"},
              {nr:2, name:"N"},
              ]
          },
          {
            dev:4,
            typ:"small",
            name:"Verbraucher",
            pins:[
              {nr:1, name:"L"},
              {nr:2, name:"N"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(255,255,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,255,255)"},
            {from:{dev:1, pin:5}, to:{dev:3,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:6}, to:{dev:3,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:7}, to:{dev:4,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:8}, to:{dev:4,pin:2}, color:"rgb(0,0,0)"},

        ],
    }      
  },

  {"active":true, "value":50,"text":"ESP32 VGA","type":"Aktor","picture":"esp32vga.jpg","name":"ESP32 VGA","desc":"ESP32, welcher in der Lage ist einen VGA Monitor anzusteuern - zusätzlich bietet das Board noch einen PS/2 Maus und Keyboard Anschluss", 
    dashoptions:[0,6,7],
    pack:[50,19,51,54],
    libs:[{lib:"fabgl.h"}], 
    pins:[],
    defs:[{def:"#define USE_8_COLORS  0"}, {def:"#define USE_64_COLORS 1"}, {def:"#if USE_8_COLORS"}, {def:"#define VGA_RED    GPIO_NUM_22"}, {def:"#define VGA_GREEN  GPIO_NUM_21"}, {def: "#define VGA_BLUE   GPIO_NUM_19"}, {def:"#define VGA_HSYNC  GPIO_NUM_18"}, {def:"#define VGA_VSYNC  GPIO_NUM_5"}, {def:"#elif USE_64_COLORS"}, {def: "#define VGA_RED1   GPIO_NUM_22"}, {def:"#define VGA_RED0   GPIO_NUM_21"}, {def:"#define VGA_GREEN1 GPIO_NUM_19"}, {def:"#define VGA_GREEN0 GPIO_NUM_18"}, {def:"#define VGA_BLUE1  GPIO_NUM_5"}, {def:"#define VGA_BLUE0  GPIO_NUM_4"}, {def:"#define VGA_HSYNC  GPIO_NUM_23"}, {def:"#define VGA_VSYNC  GPIO_NUM_15"}, {def:"#endif"}, {def:"TerminalClass Terminal;"}],
    setups:[{setup:"#if USE_8_COLORS"}, {setup:"VGAController.begin(VGA_RED, VGA_GREEN, VGA_BLUE, VGA_HSYNC, VGA_VSYNC)"}, {setup:"#elif USE_64_COLORS"}, {setup:"VGAController.begin(VGA_RED1, VGA_RED0, VGA_GREEN1, VGA_GREEN0, VGA_BLUE1, VGA_BLUE0, VGA_HSYNC, VGA_VSYNC);"}, {setup:"#endif"}, {setup:"VGAController.setResolution(QVGA_320x240_60Hz, 320, 240);"}, {setup:"//VGAController.setResolution(VGA_640x350_70HzAlt1, 640, 350);"}, {setup:"//VGAController.setResolution(VGA_640x240_60Hz);    // select to have more free memory"}, {setup:"Terminal.begin();"}, {setup:"Canvas.setPenColor(Color::Yellow);"}, {setup:"Canvas.setBrushColor(Color::Black);"}, {setup:"Canvas.clear();"}, {setup:"Canvas.fillRectangle(0, 0, Canvas.getWidth() - 1, Canvas.getHeight() - 1);"}, {setup:"Canvas.drawRectangle(0, 0, Canvas.getWidth() - 1, Canvas.getHeight() - 1);"}, {setup:"Canvas.setPenColor(Color::BrightYellow);"}, {setup:"Canvas.selectFont(Canvas.getPresetFontInfo(20, 14));"}, {setup:"Canvas.drawText(50, 20,    'Hello from IOTeasy !!!');"}],
    loops:[{loop:"Canvas.setBrushColor(Color::Black);"}, {loop:"//Canvas.clear();"}, {loop:"Canvas.fillRectangle(40,50,200,50);"}, {loop:"Canvas.setPenColor(Color::White);"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Text",
    labels:["text"],
    readsims:[{readsim:"String text = 'Hello';"}],
    reads:[{read:"Canvas.setBrushColor(Color::Black);"}, {read:"//Canvas.clear();"}, {read:"Canvas.fillRectangle(40,50,200,50);"}, {read:"Canvas.setPenColor(Color::White);"}, {read: "Canvas.drawText(50,50,text);"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32 VGA",
            pins:[
              {nr:1, name:"VGA"},
              ]
          },
          {
            dev:2,
            typ:"small",
            name:"Monitor",
            pins:[
              {nr:1, name:"VGA"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,0,255)"},
        ],
    }      
  },
  
  {"active":true, "value":51,"text":"Netzteil 5V 1000mA microUSB","type":"Zubehör","picture":"netzteil5v.jpg","name":"Netzteil (microUSB)","desc":"Ein zusätzliches Netzteil ermöglicht es Dir Dein IOT Gerät auch ohne Anschluss an den Computer zu nutzen"},
  {"active":true, "value":52,"text":"Netzteil 5V 1000mA USB C","type":"Zubehör","picture":"netzteil5v.jpg","name":"Netzteil (USB C)","desc":"Ein zusätzliches Netzteil ermöglicht es Dir Dein IOT Gerät auch ohne Anschluss an den Computer zu nutzen"},
  {"active":true, "value":53,"text":"Netzteil 5V 5A ","type":"Zubehör","picture":"netzteil5v5a.jpg","name":"Netzteil 5V 5A", "desc":"externe Spannungsquelle für stabile Stromversorgung der Komponenten"},
  {"active":true, "value":54,"text":"USB Kabel microUSB","type":"Zubehör","picture":"usbkabel.jpg","name":"microUSB Kabel", "desc":"Verbindungskabel Microprozessor / Computer"},
  {"active":true, "value":55,"text":"USB Kabel USB C","type":"Zubehör","picture":"usbkabel.jpg","name":"USB C Kabel","desc":"Verbindungskabel Microprozessor / Computer"},
  {"active":true, "value":56,"text":"Dupont Male/Male","type":"Zubehör","picture":"dupont.jpg","name":"Verbindungskabel","desc":"Dupont Verbindungskabel in den Ausprägungen Stift/Stift, Buchse, Buchse, Buchse/Stift"},
  {"active":true, "value":57,"text":"10 KOhm Widerstand","type":"Zubehör","picture":"resistor10k.jpg","name":"Widerstand 10K","desc":"10 KOhm Widerstand"},
  {"active":true, "value":58,"text":"4.7 KOhm Widerstand","type":"Zubehör","picture":"resistor4p7.jpg","name":"Widerstand 4.7K","desc":"4.7 KOhm Widerstand"},
  {"active":true, "value":59,"text":"TTL/USB","type":"Zubehör","picture":"ttlusb.jpg","name":"TTL/USB Connector","desc":"verbindet den USB mit TTL - USB TTL Adapter Modul zum Programmieren von Mikroprozessors ohne eigenen TTL"},
  
  {"active":true, "value":60,"text":"28BYJ-48 Stepper Motor with ULN2003 driver","type":"Aktor","picture":"stepper5v.jpg","name":"Schrittmotor 5V","desc":"günstiger Schrittmotor 5V mit akzeptablen Drehmoment",
    dashoptions:[2],
    pack:[60,19,51,54],
    libs:[{lib:"Stepper.h"}], 
    pins:[],
    defs:[{def:"const int stepsPerRevolution = 2048;"}, {def:"#define IN1 19"}, {def:"#define IN2 18"}, {def:"#define IN3 5"}, {def:"#define IN4 17"}, {def:"Stepper myStepper(stepsPerRevolution, IN1, IN3, IN2, IN4);"}],
    setups:[{setup:"myStepper.setSpeed(5);"}],
    loops:[{loop:"myStepper.step(stepsPerRevolution);"},{loop:"delay(1000);"},{loop:"myStepper.step(-stepsPerRevolution);"},{loop:"delay(1000);"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Schrittmotor",
    labels:["Richtung","Geschwindigkeit"],
    readsims:[{readsim:"int wert = 100;"}],
    reads:[{read:"myStepper.step(wert);"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"19"},
              {nr:2, name:"18"},
              {nr:3, name:"5"},
              {nr:4, name:"17"},
              {nr:5, name:"GND"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"LN Driver",
            pins:[
              {nr:1, name:"1"},
              {nr:2, name:"2"},
              {nr:3, name:"3"},
              {nr:4, name:"4"},
              {nr:5, name:"GND"},
              {nr:6, name:"5V"},
              ]
          },
          {
            dev:3,
            typ:"small",
            name:"5V Power",
            pins:[
              {nr:1, name:"GND"},
              {nr:2, name:"5V"},
              ]
          },
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,255)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,255)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(255,255,0)"},
            {from:{dev:1, pin:5}, to:{dev:2,pin:5}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:5}, to:{dev:3,pin:1}, color:"rgb(0,0,0)"},
            {from:{dev:2, pin:6}, to:{dev:3,pin:2}, color:"rgb(255,0,0)"},
        ],
    }        
  },

  {"active":true, "value":61,"text":"OLED 1,3 128x128 I2C SH1106","type":"Aktor","picture":"oled.jpg","name":"OLED Display","desc":"Display auf Basis von OLED Technologie mit I2C Anschluss",
    dashoptions:[6,7,0],
    pack:[61,19,51,54],
    libs:[{lib:"Wire.h"}, {lib:"Adafruit_GFX.h"}, {lib:"Adafruit_SH1106.h"}], 
    pins:[],
    defs:[{def:"#define OLED_SDA 21"}, {def:"#define OLED_SCL 22"}, {def:"#define OLED_RST 16"}, {def:"#define SCREEN_WIDTH 128"}, {def:"#define SCREEN_HEIGHT 64"}, {def:"Adafruit_SH1106 display(OLED_SDA, OLED_SCL);"}],
    setups:[{setup:"pinMode(OLED_RST, OUTPUT);"}, {setup:"digitalWrite(OLED_RST, LOW);"}, {setup:"delay(20);"}, {setup:"digitalWrite(OLED_RST, HIGH);"}, {setup:"Wire.begin(OLED_SDA, OLED_SCL);"}, {setup:"display.begin(SH1106_SWITCHCAPVCC, 0x3c); // Address 0x3C for 128x32"}, {setup:"display.clearDisplay();"}], 
    loops:[{loop:"display.clearDisplay();"},{loop:"display.setTextColor(WHITE);"}, {loop:"display.setTextSize(1);"}, {loop:"display.setCursor(0,0);"}, {loop:"display.print('hello from IOTeasy...');"}, {loop:"display.display();"}],
    funcs:[],
    controls:{  trigger:false},
    dash:"Text",
    labels:["text"],
    readsims:[{readsim:"String text = 'Hello';"}],
    reads:[{read:"display.clearDisplay();"},{read:"display.setTextColor(WHITE);"}, {read:"display.setTextSize(1);"}, {read:"display.setCursor(0,0);"}, {read:"display.print(text);"}, {read:"display.display();"}],
    wiring:{
      devices:[
          {
            dev:1,
            typ:"big",
            name:"ESP32",
            pins:[
              {nr:1, name:"3.3V"},
              {nr:2, name:"GND"},
              {nr:3, name:"21"},
              {nr:4, name:"22"},
              ]
          },
          {
            dev:2,
            typ:"big",
            name:"OLED I2C display",
            pins:[
              {nr:1, name:"Vin"},
              {nr:2, name:"GND"},
              {nr:1, name:"SDA"},
              {nr:2, name:"CLK"},
              ]
          }
        ],
      connections:[
            {from:{dev:1, pin:1}, to:{dev:2,pin:1}, color:"rgb(255,0,0)"},
            {from:{dev:1, pin:2}, to:{dev:2,pin:2}, color:"rgb(0,0,0)"},
            {from:{dev:1, pin:3}, to:{dev:2,pin:3}, color:"rgb(0,255,0)"},
            {from:{dev:1, pin:4}, to:{dev:2,pin:4}, color:"rgb(0,0,255)"},
        ],
    }
  },
  
  ];

export const deviceTypes = [
    {value:0, text:"Sensor", defaultChecked:true},
    {value:1, text:"Aktor"},
    {value:2, text:"Spezial"},
    {value:3, text:"Zubehör"},
  ];

export const connectTypes = [
    {value:0, text:"WLAN", defaultChecked:true},
    {value:1, text:"LORA"},
    {value:2, text:"GSM"},
  ];

export const loraTypes = [
    {value:0, text:"node", defaultChecked:true},
    {value:1, text:"gateway"}
  ];

export const loraBands = [
    {value:0, text:"433", defaultChecked:true},
    {value:1, text:"868"},
    {value:2, text:"915"}
  ];

export const deviceCats = [
    {typ: "Sensor", value:0, text:"Feuer, Wasser & Luft", devs:[7,8,10,34,40,44], defaultChecked:true},
    {typ: "Sensor", value:1, text:"Bewegungen", devs:[23,45]},
    {typ: "Sensor", value:2, text:"Kameras", devs:[28,30]},
    {typ: "Sensor", value:3, text:"Schall", devs:[12,32,46]},
    {typ: "Sensor", value:4, text:"Körperfunktionen", devs:[38,39]},
    {typ: "Sensor", value:5, text:"Schalter und Regler", devs:[15,16,31]},
    {typ: "Sensor", value:6, text:"Lichtstärke & Farben", devs:[9,22]},
    {typ: "Sensor", value:7, text:"Geographische Ortung", devs:[26]},
    {typ: "Aktor", value:8, text:"Licht", devs:[0,21]},
    {typ: "Aktor", value:9, text:"Ton", devs:[2]},
    {typ: "Aktor", value:10, text:"Displays", devs:[3,4,5,6,18,24,36,50]},
    {typ: "Aktor", value:11, text:"Relais", devs:[17]},
    {typ: "Aktor", value:12, text:"Motoren", devs:[27,41,47,48,62]},
    {typ: "Spezial", value:13, text:"Spezial", devs:[1,19,20,25,29,43]}
  ];

export const linkits = [
    {key:1, name: "IOTeasy in a nutshell", picture: "IOTeasy_dashboard.png", claim: "IOteasy so funktioniert's", desc: "Das Video zeigt wie einfach der IOTeasy Generator funktioniert", link: "https://tgiotbucket.s3.amazonaws.com/videos/howto.mov" },
    {key:2, name: "IOTeasy Tutorial and Videos", picture: "facebook.jpg", claim: "Facebook Seite IOTeasy", desc: "auf der Facebook Seite findet Ihr alles was Ihr wissen müsst", link: "https://www.facebook.com/IOTeasy.ch" },
    //{key:3, name: "IOTeasy am Handgelenk", picture: "fitbit.png", claim: "Fitbit", desc: "Laden Sie die Fitbit IOTeasy App herunter um...", link: "https://gallery.fitbit.com/details/dfb46edf-3391-4214-b883-86880e021eb0" },
    {key:3, name: "Arduino IDE", picture: "arduino.png", claim: "Installation Arduino Entwicklungs-umgebung IDE", desc: "Schritt für Schritt Erklärung zur Installation der Entwicklungsumgebung für Arduino-lkompatible Microprozessor", link: "https://www.arduino.cc/en/Main/Software" },
    {key:4, name: "ESP32 Installation", picture: "esp32.jpg", claim: "Board Installation des ESP32 in der Arduino Entwicklungs-umgebung IDE", desc: "Schritt für Schritt Erklärung zur Installation der notwendigen Software-Komponenten zur Unterstützung des ESP32 Microprozessors", link: "https://randomnerdtutorials.com/installing-the-esp32-board-in-arduino-ide-windows-instructions" },
    {key:5, name: "ESP32 Grundlagen", picture: "esp32.jpg", claim: "Grundlagen der Programmierung des ESP32", desc: "Für alle die den Einstieg nun gefunden haben und mehr wollen", link: "https://randomnerdtutorials.com/learn-esp32-with-arduino-ide/" }
    ];
    
export const dashOptions = [
    {value:0, text:"QRCode", img: 'espsoil.jpg', selected:true},
    {value:1, text:"Schalter", img: 'espsoil.jpg'},
    {value:2, text:"Regler", img: 'espsoil.jpg'},
    {value:3, text:"Regler RGB", img: 'espsoil.jpg'},
    {value:4, text:"Ampel", img: 'espsoil.jpg'},
    {value:5, text:"Tachometer", img: 'espsoil.jpg'},
    {value:6, text:"Text", img: 'espsoil.jpg'},
    {value:7, text:"Laufschrift", img: 'espsoil.jpg'},
    {value:8, text:"Graphik", img: 'espsoil.jpg'},
    {value:9, text:"Graphik n-Dim", img: 'espsoil.jpg'},
    {value:10, text:"Karte", img: 'espsoil.jpg'},
    {value:11, text:"Bild", img: 'espsoil.jpg'},
    {value:12, text:"Video", img: 'espsoil.jpg'},
    {value:13, text:"URL check", img: 'espsoil.jpg'},
    {value:14, text:"Kamera Stream", img: 'espsoil.jpg'},
    {value:15, text:"Komplex", img: 'espsoil.jpg'},
  ];
    
export default devices;