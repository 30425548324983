import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import {
  MDBBtn
} from 'mdb-react-ui-kit';


export default function MyTest(props) {

  return (
    <>
      <img src="arduino.jpg" width="100%" />
      <div className="hero-text">
        <h1 style={{fontSize:"160px"}}>Willkommen bei IOTeasy</h1>
        <p>Bitte melde Dich an</p>
        <MDBBtn className="btn btn-primary" onClick={() => alert("Hi")} type="Submit" className="btn btn-info">Anmelden</MDBBtn>
      </div>
    </>
  );
}