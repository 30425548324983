import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MDBContainer, MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';

import axios from 'axios';

import MyDialogProject from './myDialogProject';
//import MyYesNoBtn from './myYesNoBtn';
import { Rating } from 'react-simple-star-rating'
//import MyDummy from './myDummyM';

export default function MyProjects (props) {

  const [myProjects, setMyProjects] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [rating, setRating] = useState(0) // initial rating value

  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [projectID, setProjectID] = useState(null);

  const [user, setUser] = useState(props.user);

  //life-cycle hooks
  useEffect(() => {
    readProjects();
    const timer = setInterval(() => {
       readProjects();
    }, 30000);
    
    return () => {
      clearInterval(timer);
      //ReactDOM.render("", document.getElementById('modal'));
    }    
    
  },[]);
  
  const readProjects = () => {
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/projects?key='+user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        setMyProjects(res.data.projects);
        const k = res.data.projects;
        console.log(k);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  
  
  const manageProject = (id) => {
    setProjectID(id);
    setShowProjectDialog(true);
  }
  
  const deleteProject = (id, name) => {
    if(window.confirm("Soll Projekt " + name + " wirklich gelöscht werden?")) {
      const killString = 'https://ioteasybackend.herokuapp.com/api/v1/projects/' + id;
      console.log(killString);
      axios.delete(killString)
        .then(res => {
          console.log(res.data);
          readProjects();
          //this.setState({ isloading: true})
        })
        .catch((error) => {
          // Error
          console.log("error deleting data...");
          if (error.res) {
            console.log(error.res);
          }
        })
    }
    ReactDOM.render("", document.getElementById('modal'));
  }

  const genProjects = () => {
    return(
      myProjects.map((p, i) => 

        <MDBCol key={i} sm="12" md="12" lg="4" xl="3" xxl="2">
          <div  style={{textAlign:'center', height:550}}
             className="
                    bg-glass
                    shadow-4-strong
                    rounded-6
                    p-4
                    ripple
                    "
            >
            <img
              src={p.imgs.length === 0 ? "iotprojekte.jpg" : p.imgs[0]}
              //src={p.imgs[0]}
              width="200px"
              height="200px"        
              objectfit="cover"
            />
            <hr />
            <p className="yellow mid">{p.created_at}</p>
            {/*<p className="cyan mid">{p.rating.toFixed(1)}/{p.ratanz}</p>*/}
            <Rating onClick={(ratingValue) => {alert(ratingValue);setRating(ratingValue)}} ratingValue={p.rating.toFixed(1)/5*100} readonly={true} fillColor={"rgba(50, 200, 200, 0.8)"} /* Available Props */ />/{p.ratanz}
            <hr />
            <h4>
              {p.name}
            </h4>
            <div className="p-1">
            </div>
            {props.user.name !== "IOTeasy" ?
            <div className="kachelbtn">
              <div onClick={() => deleteProject(p.id, p.name)} style={{position:"absolute", bottom:30, left:30}}><i className="fas fa-trash p-2"></i></div>
              <div onClick={() => manageProject(p.id)} style={{position:"absolute", bottom:30, right:30}}><i className="fas fa-wrench p-2"></i></div>
            </div>
            :
            <div className="kachelbtn">
              <div onClick={() => alert("disabled in DEMO mode")} style={{position:"absolute", bottom:30, left:30}}><i className="fas fa-trash p-2"></i></div>
              <div onClick={() => alert("disabled in DEMO mode")} style={{position:"absolute", bottom:30, right:30}}><i className="fas fa-wrench p-2"></i></div>
            </div>
            }
          </div>
        
        </MDBCol>
        
      )
    )
  }

  const closeme = () => {
    setShowProjectDialog(false);
    readProjects();
  }

    return(
      <React.Fragment>
      <MDBContainer fluid className="cool" style={{width:"100%", marginTop:"75px"}}>
        <div className="myheader">
            IOT Projekte  {" "}
            <MDBBtn onClick={() => manageProject(null)} className="btn btn-lg btn-danger">{" "} Neues Projekt</MDBBtn> 
        </div>
          {isloading ? 
            <div className="loader">
              <MDBIcon className="fa-10x" spin fas icon='spinner' />
              <br /><br />
              <h1>loading Projects...</h1>
            </div> 
            :
            <MDBRow>
              {genProjects()}
            </MDBRow>}
            
    </MDBContainer>
    {showProjectDialog ?
      <>
      {/* <MyStripe user={props.user} id={aboID} showme={showAboDialog} closeme={hideDialog} /> */}
      <MyDialogProject id={projectID} user={props.user} showme={showProjectDialog} closeme={closeme} />
      </>
      :null
    }
    </React.Fragment>
    )
}
