import { 
  MDBContainer
  
} from 'mdb-react-ui-kit';

export default function CodeRead_gen(props) {

    let loraser = []; 
    if (props.device.connectivity !== "nocon") {
        for(let i=0; i<props.device.crits.length;i++) {
            loraser.push('doc2["' + props.device.crits[i].name + '"] = doc["daten"][' + String(i) + "];"  );
        }
    }

    return(

<MDBContainer className="lightgrey">
{(props.devices[props.did].type === "Aktor" && props.device.connectivity === "WLAN") || (props.devices[props.did].type === "Aktor" && props.device.loramode === "gateway") ?
<>
// ********************************************************************************************<br />
<h1>// Read IOTeasy Cloud </h1>
// ********************************************************************************************<br />
void read_{props.device.kpi_id}() {"{"}<br />
<div className="tab1">
boolean readOK = false;<br />
DynamicJsonDocument doc(2000);<br />
if (WiFi.status() == WL_CONNECTED) {"{"}<br />
</div>
<div className="tab2">
String ws;<br />
HTTPClient http;<br />
http.begin("https://ioteasybackend.herokuapp.com/api/v1/kpis/getlastkpidata/" + String(kpi_id) + "?key=" + kpi_key);<br />
int httpCode = http.GET();<br />
if(httpCode {">"} 0) {"{"}<br />
</div>
<div className="tab3">
if(httpCode == HTTP_CODE_OK) {"{"}<br/>
</div>
<div className="tab4">
String payload = http.getString();<br/>
Serial.println(payload);<br/>
DeserializationError err = deserializeJson(doc, payload);<br/>
if(err){"{"}<br/>
</div>
<div className="tab5">
Serial.printf("[JSON] ... failed, error: %s\n");<br/>
</div>
<div className="tab4">
{"}"} else {"{"}<br/>
</div>
<div className="tab5">
Serial.printf("[JSON] ... ok"); <br/>
readOK = true;<br/>
</div>
<div className="tab4">
{"}"}<br/>
</div>
<div className="tab3">
{"}"} else {"{"}<br/>
</div>
<div className="tab4">
Serial.printf("[HTTP] GET... failed, error: %s\n", http.errorToString(httpCode).c_str());<br/>
</div>
<div className="tab3">
{"}"}<br/>
</div>
<div className="tab2">
{"}"}<br/>
http.end();<br/>
{"}"}<br/>
</div>
<div className="tab2">
if(readOK) {"{"}<br/>
</div>

{props.loratype === "gateway" ?
<>
  <div className="tab3">

  DynamicJsonDocument doc2(1000);<br />
  doc2["kpi"] = {props.device.kpi_id};<br />
  {loraser.map((l,i) =>
    <>{l}<br/ ></>
  )}
  
  String myOutput = ""; <br />
  serializeJson(doc2, myOutput); <br />
  Serial.println(myOutput);<br/>
  LoRa.beginPacket(); <br/>
  LoRa.print( myOutput );<br/>

  //LoRa.beginPacket(); <br/>
  //LoRa.print( payload );<br/>

  display.clearDisplay();<br/>
  display.setCursor(0,0);<br/>
  display.print("sending...");<br/>
  display.setCursor(0,10);<br/>
  display.print(String(myOutput));<br/>
  display.display();<br/>

  LoRa.endPacket();  <br/>
  </div>

</>
:
<>
<div className="tab3">
// ********************************************************************************************<br />
<h3>// Data / Device specific</h3>
// ********************************************************************************************<br />
    {props.device.connectivity !== "nocon" ?
        <>
        {props.device.crits.map((c,i) => 
            <div>
                <br/>
                    {c.typ === "Numerisch" ? 
                    <>
                        float {c.name} = doc["daten"][{i}];<br/>
                    </> : null}
                    {c.typ === "Alphanumerisch" ?
                    <>
                        const char* {c.name} = doc["daten"][{i}];<br/>
                        char {c.name}c [100] = {"{}"};<br />
                        String {c.name}s = String({c.name});<br />
                        {c.name}s.toCharArray({c.name}c, 100);<br />
                    </> : null}
            </div>
        )}
        </>
        :null
    }

    {props.devices[props.did].reads.map((r,i) =>
      <div key={i}>
          <>
              {r.read}< br />
          </>
      </div>
    )}

</div>
</>
}

<div className="tab2">
{"}"}<br/>
</div>
{"}"}
</>
:null
}

</MDBContainer>

)}