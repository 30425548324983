import { 
  MDBContainer
  
} from 'mdb-react-ui-kit';

export default function CodeFunc_gen(props) {

    return(
  

  <MDBContainer className="lightgrey">
  { props.devices[props.did].funcs.length>0 ? 
  <div>
  // ********************************************************************************************<br />
  <h1>// Special Functions </h1>
  // ********************************************************************************************<br />
  {props.devices[props.did].funcs.map(f => 
    <>
      {f.func}<br />
    </> 
  )}
  <br />
  </div>
  : null}
  </MDBContainer>
)}