import React, { useState, useEffect } from 'react';
import { MDBCol, MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import axios from 'axios';
import ManageBtn from './myBtnMgr';

export default function MyImager(props) {

    const [kpi, setKpi] = useState(JSON.parse(props.kpi));

    useEffect(() => {
    },[])
    
    useEffect(() => {
      setKpi(JSON.parse(props.kpi));
    },[props.kpi])

    const chartStyle = {
      width: 100,
      height:100
    }  
    
    return(
                <div
                 className="
                        bg-glass
                        shadow-4-strong
                        rounded-6
                        p-4
                        ripple
                        "
                >
                    <h3>
                      {kpi.name}{kpi.iotype}
                    </h3><br />
                    {kpi.iotype === "Bild"  ? 
                      <>
                      <img src={kpi.picture2[0]} className="img-fluid" alt="..." />
                      </>
                    : null}
                    {kpi.iotype ===  "IP Kamera Link" ? 
                      <>
                      <img src={kpi.picture2[0]} className="img-fluid" alt="..." />
                      </>
                    : null}
                    {kpi.iotype == "Video" ? 
                      <iframe className="embed-responsive-item" src={kpi.data2[0]} allowFullScreen></iframe>
                    : null  
                    }
                  <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
                </div>
        );
}
