import { 
  MDBContainer
} from 'mdb-react-ui-kit';

export default function CodeInit_gen(props) {
    
    let showIncludes = true;
    let showInits = true;
    if(props.device) {
        if (props.device.connectivity === "LORA" && props.device.loramode === "gateway") {
            showIncludes = false;
            showInits = false;
        }
    }


    return(
        
        

<MDBContainer className="lightgrey">

// ********************************************************************************************<br />
<h1>// Libraries </h1>
// ********************************************************************************************<br />

{showIncludes ? 
<>
{props.devices[props.did].libs.map((l,i) =>
    <div key={i}>
        <>
        #include {"<"+l.lib+">"}< br />
        </>
    </div>
)}
</>
:null}

{props.device !== null ?
<>
{props.device.connectivity === "LORA" || props.device.connectivity === "WLAN" ? 
    <>
    #include {"<ArduinoJson.h>"}<br />

    {props.device.connectivity === "WLAN" || props.device.loratype === "gateway" ?
        <>
        #include {"<WiFi.h>"}<br />
        #include {"<HTTPClient.h>"}<br />
        </>
    :null
    }

    {props.device.connectivity === "LORA" ?
        <>
        {"#include <SPI.h>"}<br />
        {"#include <LoRa.h>"}<br />
        {"#include <Wire.h>"}<br />
        {"#include <Adafruit_GFX.h>"}<br />
        {"#include <Adafruit_SSD1306.h>"}<br />  
        </>
    : null
    }
    </>
    : null
}
</>
:null}

<br />
// ********************************************************************************************<br />
<h1>// Initialization </h1>
// ********************************************************************************************<br />
{showInits ? 
<>
{props.devices[props.did].pins.map((p,i) =>
    <div key={i}>
        <>
        #define {p.piname} {p.pinr}< br />
        </>
    </div>
)}
<br />
{props.devices[props.did].defs.map((d,i) =>
    <div key={i}>
        <>
        {d.def}< br />
        </>
    </div>
)}
<br />
</>
:null}

{props.device !== null ?
<>
{props.device.connectivity === "LORA" || props.device.connectivity === "WLAN" ?
    <>
    {props.device.connectivity==="WLAN" || props.loratype === "gateway" ?
        <>
        // WLAN <br />
        const char* ssid = "{props.device.ssid}";<br />
        const char* password = "{props.device.password}";<br />
        <br />
        // IOTeasy Access <br />
        const int kpi_id = {props.device.kpi_id};<br />
        String kpi_key = "{props.device.key}";<br />
        </>
        :
        null
    }
    <br />

    {props.device.connectivity === "LORA" ?
        <>
            // Lora mit OLED <br />
            #define SCK     5    // GPIO5  -- SX1278's SCK <br/>
            #define MISO    19   // GPIO19 -- SX1278's MISO <br/>
            #define MOSI    27   // GPIO27 -- SX1278's MOSI<br/>
            #define SS      18   // GPIO18 -- SX1278's CS<br />
            //#define RST     14   // GPIO14 -- SX1278's RESET<br/>
            #define DI0     26   // GPIO26 -- SX1278's IRQ(Interrupt Request)<br/>
            #define BAND    868E6 // 433, 915, 868 <br/>
            #define OLED_SDA 21 <br />
            #define OLED_SCL 22 <br />
            #define OLED_RST 16 <br />
            #define SCREEN_WIDTH 128 <br />
            #define SCREEN_HEIGHT 64 <br />
            Adafruit_SSD1306 display(SCREEN_WIDTH, SCREEN_HEIGHT, &Wire, OLED_RST); <br/>
        </>
    : null
    }

    </>
    :null
}
</>
:null}
<br />

// Trigger <br />
{!props.devices[props.did].controls.trigger ?
<>
int delays = 30 * 1000;<br />
long millisave = 0-delays;<br />
</>
:null}

{props.devices[props.did].controls.trigger ?
<>
int wert = 0;<br />
long wertsave = 0;<br />
</>
:null}

<br />
</MDBContainer>

)}
