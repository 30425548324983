import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import {MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon,   MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselElement, MDBCarouselCaption, MDBContainer } from 'mdb-react-ui-kit';

import MyAdminDialog from './myAdminDialog';

export default function MyAdmin(props) {

  const [admin, setAdmin] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  const [throttle, setThrottle] = useState(0);
  const [data, setData] = useState(0);

  const [showAdminDetails, setShowAdminDetails] = useState(false);
  const [adminTyp, setAdminTyp] = useState(null);
  
  const readThrottles = () => {
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/throttles';
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data.admin);
        
        setAdmin(res.data.admin);
        
        setThrottle ( (res.data.admin.throttles.rejected / (res.data.admin.throttles.rejected + res.data.admin.throttles.accepted) * 100).toFixed(1) );
        setData ( (res.data.admin.values.critical / res.data.admin.values.critanz * 100).toFixed(1) );

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  

const adminDetails = (typ) => {
  setShowAdminDetails(true);
  setAdminTyp(typ);
  //ReactDOM.render(<MyAdminDialog typ={typ} cleaner={cleaner} user={props.user}/>, document.getElementById('modal'));
}

const closeme = () => {
  setShowAdminDetails(false);
  setAdminTyp(null);
  }

  //life-cycle hooks
  useEffect(() => {
    readThrottles();
    const timer = setInterval(() => {
       readThrottles();
    }, 30000);
    
    return () => {
      clearInterval(timer);
    }    
    
  },[]);

const adminItem = (typ) => {
    return(
          <MDBCol key={typ} sm="12" md="12" lg="4" xl="2" xxl="2">
            <div  style={{textAlign:'center', height:400}}
               className="
                      bg-glass
                      shadow-4-strong
                      rounded-6
                      p-4
                      ripple
                      "
              >
              <h3>
                {typ}
              </h3>
              <br />
              {typ === "Blocked TRX" ?
                <>
                  {throttle > 5 ? 
                    <div className="red big">{throttle+" %"} </div>
                    :
                    <div className="green big">{throttle+" %"} </div>
                  }
                </>
                :null
              }
              
              <div className="red big">
                {typ === "Blocked IPs" ? admin.ips.blocked : null}
                {typ === "Data" ? data+" %" : null}
                {typ === "Blocked SMS" ? ((admin.sms.blocked/admin.sms.smsanz)*100).toFixed(1) + " %" : null}
              </div>

              <div className="green big">
                {typ === "User" ? admin.users.anz : null}
                {typ === "Dashboards" ? admin.dashboards.anz : null}
                {typ === "Dashboard-Items" ? admin.kpis.anz : null}
                {typ === "Devices" ? admin.devices.anz : null}
                {typ === "Abos" ? admin.values.aboactive : null}
              </div>
              <hr />

              <div className="yellow mid">
                {typ === "User" ? 
                  <>
                  dashboard items: {admin.kpis.anz}<br />
                  devices: {admin.devices.anz}<br />
                  abos: {admin.values.aboactive}
                  </>
                : null}

                {typ === "Blocked TRX" ? 
                  <>
                  blocked: {admin.throttles.rejected}<br />
                  accepted: {admin.throttles.accepted}
                  </>
                : null}

                {typ === "Data" ? 
                  <>
                  critical items: {admin.values.critical}<br />
                  total items: {admin.values.critanz}<br />
                  total data: {admin.values.valanz}
                  </>
                : null}

                {typ === "Blocked SMS" ? 
                  <>
                  blocked: {admin.sms.blocked}<br />
                  accepted: {admin.sms.smsanz}
                  </>
                : null}

              </div>

              <div className="kachelbtn">
                <div onClick={() => adminDetails(typ)} style={{position:"absolute", bottom:20, right:20}}><i className="fas fa-wrench p-2"></i></div>
              </div>

            </div>
          </MDBCol>
      )
  }

  return(
      isloading ? 
        <div className="loader">
          <MDBIcon className="fa-10x" spin fas icon='spinner' />
          <br /><br />
          <h1>loading Admin Board...</h1>
        </div> 
      :
        <React.Fragment>
        <MDBContainer fluid className="cool" style={{width:"100%", marginTop:"75px"}}>
          <div className="myheader">IoTeasy HealthCheck</div>
          <MDBRow>
            {adminItem("Blocked TRX")}
            {adminItem("Blocked IPs")}
            {adminItem("Data")}
            {adminItem("User")}
            {adminItem("Blocked SMS")}
          </MDBRow>
        </MDBContainer>
        {showAdminDetails ? 
          <MyAdminDialog typ={adminTyp} showme={showAdminDetails} closeme={closeme} user={props.user}/>
          :null
        }
        </React.Fragment>
    )

}