import React, { useState, useEffect } from 'react';
import { MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import ColorPicker from '@radial-color-picker/react-color-picker';
import '@radial-color-picker/react-color-picker/dist/react-color-picker.min.css';
import ManageBtn from './myBtnMgr';
import axios from 'axios';

export default function MyColorPicker(props) {

    const [kpi, setKpi] = useState(JSON.parse(props.kpi));
    const [color, setColor] = useState({hue: 150, saturation: 60, luminosity: 10, alpha: 1,});
    const [myColor, setMycolor] = useState(null);
  
    useEffect(() => {
    },[])
    
    useEffect(() => {
      setKpi(JSON.parse(props.kpi));
      hextohsv(kpi.data2[0]);      
    },[props.kpi])


    const onSelect = (e) => {
      const event = Date.now();
      const addString = 'https://ioteasybackend.herokuapp.com/api/v1/kpis/addkpidata/' + kpi.id + '?event=' + event + '&key=' + kpi.iotnumber + '&text=' + hsvtorgb(e,100,100);
      console.log(addString);

      axios.post(addString)
        .then(res => {
          console.log(res.data);
        })
        .catch((error) => {
          // Error
          console.log("error writing data...");
          if (error.res) {
            console.log(error.res);
          }
        })

  }

  const hextohsv=(H) => {
  // Convert hex to RGB first
  let r = 0, g = 0, b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r,g,b),
      cmax = Math.max(r,g,b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

  if (delta == 0)
    h = 0;
  else if (cmax == r)
    h = ((g - b) / delta) % 6;
  else if (cmax == g)
    h = (b - r) / delta + 2;
  else
    h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0)
    h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  //return "hsl(" + h + "," + s + "%," + l + "%)";
  return(h);
}

  const hsvtorgb=(h,s,v) => {
        if (h == "") h = 0;
        if (s == "") s = 0;
        if (v == "") v = 0;
        h = parseFloat(h);
        s = parseFloat(s);
        v = parseFloat(v);
        if (h < 0) h = 0;
        if (s < 0) s = 0;
        if (v < 0) v = 0;
        if (h >= 360) h = 359;
        if (s > 100) s = 100;
        if (v > 100) v = 100;
                s /= 100;
                v /= 100;
                let C = v * s;
                let hh = h / 60;
                let X = C * (1 - Math.abs(hh % 2 - 1));
                let r = 0;
                let g = 0;
                let b = 0;
        if (hh >= 0 && hh < 1) {
                    r = C;
                    g = X;
                }
        else if (hh >= 1 && hh < 2) {
                    r = X;
                    g = C;
                }
        else if (hh >= 2 && hh < 3) {
                    g = C;
                    b = X;
                }
        else if (hh >= 3 && hh < 4) {
                    g = X;
                    b = C;
                }
        else if (hh >= 4 && hh < 5) {
                    r = X;
                    b = C;
                }
        else {
                    r = C;
                    b = X;
                }
                let m = v - C;
                r += m;
                g += m;
                b += m;
                r *= 255.0;
                g *= 255.0;
                b *= 255.0;
                r = Math.round(r);
                g = Math.round(g);
                b = Math.round(b);
                let hex = r * 65536 + g * 256 + b;
                hex = hex.toString(16, 6);
                let len = hex.length;
        if (len < 6)
            for (let i = 0; i < 6 - len; i++)
                hex = '0' + hex;
                //hex="#"+ hex.toUpperCase();
                hex="%23"+ hex;
            return(hex);
  }  

  const chartStyle = {
    width: 140,
    height:140,
    textAlign:"center"
  }  

    return(
            <div 
             className="
                    bg-glass
                    shadow-4-strong
                    rounded-6
                    p-4
                    ripple"
                    style={{textAlign:"center"}}>
              <h2>{kpi.name}</h2>
              <MDBRow>
                <MDBCol style={{display:"flex", flexDirection: "column", alignItems:"center"}}>
                  <ColorPicker hue={hextohsv(kpi.data2[0])} initiallyCollapsed={false} onSelect={onSelect} stylei={chartStyle} /> 
                  <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
                </MDBCol>
              </MDBRow>
           </div>
        );
}
