import React, { useState, useEffect } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBAccordion, 
  MDBAccordionItem,
  MDBRadio,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBValidation,
  MDBIcon,
  MDBScrollbar
} from 'mdb-react-ui-kit';

import axios from 'axios';

export default function MyProfile(props) {

  const [staticModal, setStaticModal] = useState(true);
  const [showMe, setShowMe] = useState(props.showme);

  const [id, setId] = useState(null);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [phone1, setPhone1] = useState("");
  const [whatsappapikey, setWhatsappapikey] = useState("");
  const [url, setUrl] = useState(null);
  const [image, setImage] = useState("");
  const [newImage, setNewimage] = useState(false);

  //const toggleShow = () => setStaticModal(!staticModal);

  const handleProfile = () => {
    var formData = new FormData();
    formData.append('name', name);
    formData.append('lastname', lastname);
    formData.append('address1', address1);
    formData.append('address2', address2);
    formData.append('address3', address3);
    formData.append('whatsappapikey', whatsappapikey);
    formData.append('phone1', phone1);
    if(image) {
      formData.append('avatar', image);
    }
    
    // CREATE USER
    if(props.id==0) {
      axios({
        method: "post",
        //url: "https://tgiot.herokuapp.com/api/v1/users/",
        url: "https://ioteasybackend.herokuapp.com/api/v1/users/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      // UPDATE USER
      axios({
        method: "put",
        //url: "https://tgiot.herokuapp.com/api/v1/users/"+props.user.id,
        url: "https://ioteasybackend.herokuapp.com/api/v1/users/"+props.user.id,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });

    }
    props.closeme();
  }
  
  const readUser = (user) => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/users/'+user;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/users/'+user;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log("ok...");
        setId(res.data.user.id);
        setEmail(res.data.user.email);
        setName(res.data.user.name);
        setLastname(res.data.user.lastname);
        setAddress1(res.data.user.address1);
        setAddress2(res.data.user.address2);
        setAddress3(res.data.user.address3);
        setWhatsappapikey(res.data.user.whatsappapikey);
        setPhone1(res.data.user.phone1);
        setUrl(res.data.user.url);
        setImage(res.data.user.image);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }

  const deleteUser = (id, name) => {
    //alert("ID is.. "+id);
    if(window.confirm("Soll der User " + name+ " und alle Daten unwiderruflich gelöscht werden?")) 
      {
      //const killString = 'https://tgiot.herokuapp.com/api/v1/kpis/' + id;
      const killString = 'https://ioteasybackend.herokuapp.com/api/v1/users/' + id;
      console.log(killString);
      axios.delete(killString)
        .then(res => {
          console.log(res.data);
          this.setState({ isloading: true})
          props.closeme();
        })
        .catch((error) => {
          // Error
          console.log("error deleting data...");
          if (error.res) {
            console.log(error.res);
          }
        })
      } else 
      {
        //alert("nothing to do !")
      }
      //props.closeme();
  }
  
  const handleNameChange = (event) => {
    setName(event.target.value);
  }
  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  }
  const handleAdress1Change = (event) => {
    setAddress1(event.target.value);
  }
  const handleAdress2Change = (event) => {
    setAddress2(event.target.value);
  }
  const handleAdress3Change = (event) => {
    setAddress3(event.target.value);
  }
  const handlePhone1Change = (event) => {
    setPhone1(event.target.value);
  }
  const handleWhatsappapikeyChange = (event) => {
    setWhatsappapikey(event.target.value);
  }
  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setImage(event.target.files[0] );  
      setNewimage(true);
    }
  }

  useEffect(() => {
    //setStaticModal(true);
    //alert('readID');
    readUser(props.user.id)
  },[]);
  
  useEffect(() => {
    //setStaticModal(true);
    //alert('readID');
    //readUser(props.user.id)
  },[props.showme]);

  return (
    <>
        
      <MDBModal className="bg-glasst" staticBackdrop show={props.showme} >
        <div className="myheader">Profile {name} {lastname}</div>
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />

        <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">

                <MDBRow className="m-3 p-3">
                  <MDBCol sm="12" md="6" lg="6" xl="6" xxl="6">

                  <h4>Profilbild</h4>
                  <MDBInput 
                    className="btn btn-lg btn-info"
                    type="file" 
                    name="main_image" 
                    accept="image/png, image/jpeg, image/jpg" 
                    multiple={false}
                    onChange={handleImageChange} 
                    />
                    { props.user.url !== "" ? 
                    <img 
                        src={url}  
                        alt="responsive-image"
                        className="img-fluid"
                    /> :
                    <img
                        src="https://mdbootstrap.com/img/Photos/Others/images/58.jpg"
                        alt=""
                        className="img-fluid"
                    />}
                    {/*props.user.fname*/}
                  </MDBCol>
                  <MDBCol sm="12" md="6" lg="6" xl="6" xxl="6">
                  <h4>eMail</h4>
                  <div className="yellow mid">{email}</div>
                  <h4>Name</h4>
                  <MDBInput 
                    label="Name" 
                    value={name}
                    group type="text" 
                    onChange={handleNameChange}
                  />
                  <br />
                  <MDBInput 
                    label="LastName" 
                    value={lastname}
                    group type="text" 
                    onChange={handleLastnameChange}
                  />

                  <br />
                  <h4>Adresse</h4>
                  <MDBInput 
                    label="Adresse1" 
                    value={address1}
                    group type="text" 
                    onChange={handleAdress1Change}
                  />
                  <MDBInput 
                    label="Adresse2" 
                    value={address2}
                    group type="text" 
                    onChange={handleAdress2Change}
                  />
                  <MDBInput 
                    label="Adresse3" 
                    value={address3}
                    group type="text" 
                    onChange={handleAdress3Change}
                  />
                  <br />
                  <h4>Telefon</h4>

                  <MDBInput 
                    label="Telefon (SMS Alarmierungen)" 
                    value={phone1}
                    group type="text" 
                    onChange={handlePhone1Change}
                  />
                  <MDBInput 
                    label="WhatsApp Api-key" 
                    value={whatsappapikey}
                    group type="text" 
                    onChange={handleWhatsappapikeyChange}
                  />
                  <br />
                  <div style={{textAlign:"right"}}>
                    <MDBBtn onClick={() => handleProfile()} type="Submit" className="btn btn-info">Speichern</MDBBtn>
                    <MDBBtn onClick={() => props.closeme()} type="Submit" className="btn btn-dark">Abbrechen</MDBBtn>
                    <MDBBtn onClick={() => deleteUser(id, name)} type="Submit" className="btn btn-danger">Löschen</MDBBtn>
                  </div>

                  </MDBCol>
                </MDBRow>
        </div>
      </MDBModal>
      </>
  );
}