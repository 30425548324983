import { 
  MDBContainer
  
} from 'mdb-react-ui-kit';

export default function CodeSetup_gen(props) {
    
    return(

<MDBContainer className="lightgrey">
// ********************************************************************************************<br />
<h1>// Setup </h1>
// ********************************************************************************************<br />
void setup() {"{"}<br />

<div className="tab1">
Serial.begin (115200);<br />
<br />
{props.device !== null ?
<>
{(props.device.connectivity === "WLAN" || props.loratype === "gateway") ?
<>
<div className="tab1">
// WLAN < br/>
WiFi.disconnect();<br />
WiFi.mode(WIFI_STA);<br />
WiFi.begin(ssid, password);<br />
Serial.print("Connecting");<br />
int counter=0;<br />
while (WiFi.status() != WL_CONNECTED){"{"}<br />
</div>
<div className="tab2">
delay(2000);<br />
counter++;<br />
Serial.print(".");<br />
if (counter {">"} 20) {"{"}<br />
</div>
<div className="tab3">
ESP.restart();<br />
</div>
<div className="tab2">
{"}"}<br />
</div>
<div className="tab1">
{"}"}<br />
Serial.print("Connected, IP address: ");<br />
Serial.println(WiFi.localIP());<br />
</div>
</>
:null}

{props.device.connectivity === "LORA" ?
<>
<div className="tab1">
  // LORA <br />
  SPI.begin(SCK,MISO,MOSI,SS);<br />
  LoRa.setPins(SS,14,DI0);<br />
  if (!LoRa.begin(BAND)) {"{"} <br />
    Serial.println("Starting LoRa failed!"); <br />
    while (1);<br />
  {"}"}<br />
  Serial.println("LORA ok"); <br /> 

  pinMode(OLED_RST, OUTPUT);< br/>
  digitalWrite(OLED_RST, LOW); <br />
  delay(20);<br />
  digitalWrite(OLED_RST, HIGH);<br />
  Wire.begin(OLED_SDA, OLED_SCL);<br />
  display.begin(SSD1306_SWITCHCAPVCC, 0x3c, false, false); // Address 0x3C for 128x32 <br />
  display.clearDisplay();<br />
  display.setTextColor(WHITE);<br />
  display.setTextSize(1);<br />
  display.setCursor(0,0);<br />
  display.print("LORA ok...");<br />
  display.display();<br />
  delay(2000);<br />

</div>
</>
:null}
</>
:null}

<br />

{props.loratype !== "gateway" ?
<>
<div className="tab1">
<br />
// Device specific <br />
{props.devices[props.did].pins.map((p,i) =>
    <div key={i}>
        <>
            pinMode({p.piname}, {p.pinmode});< br />
        </>
    </div>
)}
{props.devices[props.did].setups.map((s,i) =>
    <div key={i}>
        <>
            {s.setup}< br />
        </>
    </div>
)}
</div>
</>
:null}

</div>

{"}"}
</MDBContainer>

)}