import React, { useState, useEffect } from 'react';
//import { GoogleMap, LoadScript, Marker, InfoWindow, MarkerLabel} from '@react-google-maps/api';
//import { MDBSelect, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardUp, MDBAvatar, MDBCardBody, MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { MDBChart, MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import GoogleMapReact from 'google-map-react';
import ManageBtn from './myBtnMgr';

import ReactDOM from 'react-dom'
import axios from 'axios'

export default function MyMap(props) {

  const [kpi, setKpi] = useState(JSON.parse(props.kpi));
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [locs, setLocs] = useState([]);
  const [zoom, setZoom] = useState(12);

  const MyMarkers = () => {
      const len = locs.length;
      return(
        locs.map((l,i) =>
          <div key={i} onClick={() => alert(l.datum)} style={{height:dimvar(locs,i), width:dimvar(locs,i), borderRadius:'50%', backgroundColor:colvar(locs,i)}}
            lat={l.lat} lng={l.lng}>
          </div>
          )
      );
  }
  
  const colvar = (locs,i) => {
    if (i==locs.length-1) {
      var rgb = "rgb(255,0,0)";
    } else {
      var step = 155+(100/locs.length) * i;
      var rgb = "rgb("+step+",50,50)";
      //rgb = "hsl(184, 100%, 47%)";
    }
    return(
        rgb
      )
  }

  const dimvar = (locs,i) => {
    if (i==locs.length-1) {
      var dim = 30;
    } else {
      var dim = 5 + (25/locs.length) * i;
    }
    return(
        dim
      )
  }

  useEffect(() => {
    setKpi(JSON.parse(props.kpi));

    let tlocs=[]
    if(kpi.data1.length==2) {
      for(let i=0; i<kpi.data1[0].length; i++) {
        tlocs.push({lat:kpi.data1[1][i], lng: kpi.data1[0][i]});
      }
    }
    setLocs(tlocs);
    setLat(kpi.data2[1]);
    setLng(kpi.data2[0]);

  }, [props.kpi]);

    return (
        <div style={{textAlign:'center'}}
         className="
                bg-glass
                shadow-4-strong
                rounded-6
                p-4
                ripple
                "
        >
         <h2>{kpi.name} [{locs.length}] </h2>
         <div style={{ height: '30vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAP0PoTXZAKRi5IO3fM5yRO39puZPiR0iw' }}
            defaultCenter={{lat: kpi.data2[1], lng: kpi.data2[0]}}
            defaultZoom={14}
            onChange={ ({center,zoom}) => {setLat(center.lat);setLng(center.lng);setZoom(zoom);} }
            center={{lat:lat, lng:lng}}
            //yesIWantToUseGoogleMapApiInternals
            //onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          >
            {MyMarkers()}
          </GoogleMapReact>
            <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
         </div>
         </div>
    )
}