import React, { useState, useEffect } from 'react';

import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBIcon,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBRadio,
  MDBScrollbar,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselElement,
  MDBCarouselCaption,
  MDBLightbox, 
  MDBLightboxItem
} from 'mdb-react-ui-kit';

import axios from 'axios';

import { Rating } from 'react-simple-star-rating'

export default function MyHomeProjectData(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [staticModal, setStaticModal] = useState(true);
  const [project, setProject] = useState(null);
  const [user, setUser] = useState(JSON.parse(props.user));

  const [ratings, setRatings] = useState([]);

  const [rateedit, setRateedit] = useState(false);

  const [rating, setRating] = useState(0);
  const [ratingId, setRatingId] = useState(null);
  const [header, setHeader] = useState(" ");
  const [description, setDescription] = useState(" ");

  useEffect(() => {
  },[])
  
  useEffect(() => {
      readProject(props.project);
  },[props.project])

  const readProject = (id) => {
    //alert(user.userkey)
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/projects/'+id+"?key="+user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        setProject(res.data.project);
        const k = res.data.project;
        console.log(k);
        readRating(res.data.project.id)
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }   

  const readRating = (project_id) => {
    //alert(user.userkey);
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/ratings?key='+user.userkey+"&project_id="+project_id;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        setRatings(res.data.ratings);
        if(res.data.ratings.length > 0) {
          setHeader(res.data.ratings[0].header);
          setDescription(res.data.ratings[0].description);
          setRating(res.data.ratings[0].rating);
          setRatingId(res.data.ratings[0].id);
        }
        const k = res.data.ratings;
        console.log(k);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }   
  
  const deleteRating = () => {
    const killString = 'https://ioteasybackend.herokuapp.com/api/v1/ratings/' + ratingId+"?userkey="+user.userkey;
    console.log(killString);
    //axios.delete(killString)

      axios.delete(
        "https://tgiot.herokuapp.com/api/v1/ratings/"+ratingId+"?userkey="+user.userkey, 
        {headers: {'X-Requested-With': 'XMLHttpRequest'}},
        )

      .then(res => {
        console.log(res.data);
        setRateedit(!rateedit);
        readProject(project.id);
      })
      .catch((error) => {
        // Error
        console.log("error deleting data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }

  const bewerten = () => {
    setRateedit(!rateedit);
  }

  const handleRating = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('project_id', project.id);
    bodyFormData.append('user_id', user.id);
    bodyFormData.append('header', header);
    bodyFormData.append('description', description);
    bodyFormData.append('rating', rating);

    // CREATE RATING
    if(ratings.length === 0) {
      axios({
        method: "post",
        url: "https://ioteasybackend.herokuapp.com/api/v1/ratings/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      // UPDATE RATING
      axios({
        method: "put",
        url: "https://ioteasybackend.herokuapp.com/api/v1/ratings/"+ratingId,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    //props.cleaner();
    setRateedit(!rateedit);
    readProject(project.id)
    readRating(project.id)
  }

  return (
    <>

      <MDBModal className="bg-glasst" staticBackdrop show={props.showme} >
        <div className="myheader">Projekt {!isLoading ? <>{project.name}</> :null} </div>
          <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />

                <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">

                  {isLoading ? 
                    <div className="loader">
                      <MDBIcon className="fa-10x" spin fas icon='spinner' />
                      <br /><br />
                      <h1>loading Project...</h1>
                    </div> 
                    :
                    <>
                    <h4>{project.description}</h4>
                    <br />

                    <Rating ratingValue={project.rat.toFixed(1)/5*100} readonly={true} fillColor={"rgba(50, 200, 200, 0.8)"} />/{project.ratanz + " " }
                    <MDBBtn onClick={() => bewerten()} className="btn btn-info">Bewerten</MDBBtn>
                    <br />
                    {rateedit ?
                      <>
                      <hr />
                      <MDBRow>
                        <MDBCol>
                          <Rating onClick={(rate) => setRating(rate/20)} ratingValue={rating.toFixed(1)/5*100} readonly={false} fillColor={"rgba(50, 200, 200, 0.8)"} />
                        </MDBCol>
                        <MDBCol>
                        <MDBInput 
                          className="m-2 p-2"                  
                          input={header}
                          label="Bewertung" 
                          value={header}
                          type="text" 
                          onChange={(e) => {setHeader(e.target.value)}}
                        />
                        </MDBCol>
                        <MDBCol>
                        <MDBInput 
                          textarea
                          input={description}
                          rows="4"
                          label="Beschreibung" 
                          value={description}
                          type="text" 
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        </MDBCol>
                      </MDBRow>
                      <br />
                      <MDBBtn className="btn btn-info float-end" style={{textAlign:"right"}} onClick={() => handleRating()} >Speichern</MDBBtn>
                      <MDBBtn className="btn btn-light float-end" onClick={() =>setRateedit(false)} >Abbrechen</MDBBtn>
                      {ratings.length > 0 || true?
                        <MDBBtn className="btn btn-danger float-end" onClick={() => deleteRating()} >Löschen</MDBBtn>
                      :null}
                      <br /><br />
                      </>
                      :null
                    }
                    <hr />

                    <MDBContainer style={{width:"50%", color:"cyan"}}>
                      <div className="myheader">Bilder</div> 
                      <MDBLightbox>
                        <MDBRow>
                          {project.addons.filter(t => t.atype === "Bild").map((t,i) =>
                            <MDBCol key={i} lg='4'>
                              <MDBLightboxItem
                                src={t.filename}
                                fullscreenSrc={t.filename}
                                className='w-100'
                              />
                            </MDBCol>
                            )}
                        </MDBRow>
                      </MDBLightbox>
                  </MDBContainer>
                  <MDBContainer>
                    <div className="myheader">Anhänge</div> 
                    <MDBRow>
                      <MDBTable>
                        <MDBTableHead>
                              <tr style={{color:"lightgrey"}}>
                              <th scope='col'>Typ</th>
                              <th scope='col'>Name</th>
                              <th scope='col'>Beschreibung</th>
                            </tr>
                          </MDBTableHead>
                        <MDBTableBody>
                          {project.addons.filter(t => t.atype !== "Bild").map((t,i) =>
                            <tr style={{color:"lightgrey"}} key={i}>
                              <td>
                              {t.atype === "Link" ?
                                <a href={"https://"+t.name} target="_blank"><MDBIcon style={{color:"grey"}} fas icon="link" size="3x"/></a>
                              :null
                              }
    
                              {t.atype === "Dokument" ?
                                <a href={"https://"+t.filename} target="_blank"><MDBIcon style={{color:"grey"}} fas icon="file" size="3x"/></a>
                              :null
                              }
    
                              {t.atype === "Code" ?
                                <a href={"https://"+t.filename} target="_blank"><MDBIcon style={{color:"grey"}} fas icon="code" size="3x"/></a>
                              :null
                              }
                              </td>
                              <td>
                                {t.name}
                              </td>
                              <td>
                                {t.description}
                              </td>
                              
                            </tr>
    
                            )}
                        </MDBTableBody>
                      </MDBTable>
                    </MDBRow>
                  </MDBContainer>
                  <br />
                  <div className="myheader">Bewertungen</div> 
                  <MDBTable>
                    <MDBTableHead>
                        <tr style={{color:"lightgrey"}}>
                          <th scope='col'>Rating</th>
                          <th scope='col'>Kommentar</th>
                          <th scope='col'>Beschreibung</th>
                          <th scope='col'>von</th>
                          <th scope='col'>am</th>
                        </tr>
                      </MDBTableHead>
                    <MDBTableBody>
                      {project.ratings.map((r,i) =>
                        <tr style={{color:"lightgrey"}} key={i}>
                          <td>
                            <Rating ratingValue={r.rat.toFixed(1)/5*100} readonly={true} fillColor={"rgba(50, 200, 200, 0.8)"} />
                          </td>
                          <td>
                            {r.header}
                          </td>
                          <td>
                            {r.description}
                          </td>
                          <td>
                            {r.user}
                          </td>
                          <td>
                            {r.datum}
                          </td>
                          
                        </tr>

                        )}
                    </MDBTableBody>
                  </MDBTable>
                  </>
                  }
                  
                  
                </div>

      </MDBModal> 
      

    </>
  );
}