import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import MyDialogKpi from './myDialogKpi';
import axios from 'axios';

import { MDBBtn } from 'mdb-react-ui-kit';

export default function ManageBtn (props) {

  const [kpi, setKpi] = useState(JSON.parse(props.kpi));
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setKpi(JSON.parse(props.kpi));
  },[props.kpi])

  return(
    <div className="p3 test">
      {props.but ?
      <div className="kachelbtn">
        <div onClick={() => props.delete(JSON.parse(props.kpi).id)} style={{position:'absolute', left:30}}><i className="fas fa-trash p-2"></i></div>
        <div onClick={() => props.edit(JSON.parse(props.kpi).id)} style={{position:'absolute',  right:30}}><i className="fas fa-wrench p-2"></i></div>
      </div>
      :
      <div className="kachelbtn">
        <div onClick={() => alert("disabled in DEMO mode")} style={{position:'absolute', left:30}}><i className="fas fa-trash p-2"></i></div>
        <div onClick={() => alert("disabled in DEMO mode")} style={{position:'absolute',  right:30}}><i className="fas fa-wrench p-2"></i></div>
      </div>

    }
    </div>
  )
  
}