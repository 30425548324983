import React, { useState } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBInput,
  MDBIcon
} from 'mdb-react-ui-kit';

import axios from 'axios';
import TestCaptcha from './myCaptchaNew';
import MyIPScanner from './myIPScanner';

export default function MyRegistration(props){

  const [code, setCode] = useState("1234");
  const [ecode, setEcode] = useState("");
  const [verified, setVerified] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setComfirmpassword] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");

  const [mode, setMode] = useState(props.mode);

  const [info, setInfo] = useState("");

  const register = (mode, user) => {
    
      if((mode==="register" || mode==="pwreset") && password !== confirmpassword) {
        setInfo("Passwörter unterschiedlich !");
        return;
      }
    
      var bodyFormData = new FormData();

      if(mode==="pwlink") {
        bodyFormData.append('email', email);
      }
      if(mode==="pwreset") {
        bodyFormData.append('email', props.email);
        bodyFormData.append('token', props.token);
        bodyFormData.append('password', password);
        bodyFormData.append('confirmpassword', confirmpassword);
      }
      if(user==="user") {
        bodyFormData.append('email', email);
        bodyFormData.append('password', password);
      }
      if(user==="demo") {
        bodyFormData.append('email', "iotest@bluewin.ch");
        bodyFormData.append('password', "iotest");
      }
      if(user==="test") {
        bodyFormData.append('email', "horst.wurm@bluewin.ch");
        bodyFormData.append('password', "emelie");
      }

      if(mode==="register") {
        //alert("register");
        bodyFormData.append('email', email);
        bodyFormData.append('password', password);
        bodyFormData.append('confirmpassword', confirmpassword);
        bodyFormData.append('name', name);
        bodyFormData.append('lastname', lastname);
        bodyFormData.append('address1', address1);
        bodyFormData.append('address2', address2);
        bodyFormData.append('address3', address3);
        bodyFormData.append('phone1', phone1);
        bodyFormData.append('phone2', phone2);
      }

      axios({
        method: "post",
        url: "https://ioteasybackend.herokuapp.com/api/v1/users?mode="+mode,
        //url: "https://tgiot.herokuapp.com/api/v1/users?mode="+mode,
        //url: "https://3e45edd655db4d4ab7c827fd97f9a27e.vfs.cloud9.us-east-1.amazonaws.com/api/v1/users?mode="+mode,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response.data.message);
          if(response.data.status==="OK") {
            console.log(response.data.user);
            props.setkey(mode, response.data.user);
          } else {
            setInfo(response.data.message);
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response.message);
        });

  };
  
  const toggleLoginRegist = () => {
    if (mode==="login") {
      setMode("register");
      setEmail("");
      setPassword("");
    }
  }
  
  const toggleNewPassword = () => {
    //props.closeme();
    //props.setkey("pwlink", null);
    register("pwlink",null)
  }

  const updateCode = (c) => {
    setEcode(c);
    if(c===code){
      setVerified(true);
    } else {
      setVerified(false);
    }
  }

  const setCaptchaCode = (c) => {
    //alert("setting code to "+c);
    setCode(c);
  };

  return(
    
      <MDBModal className="bg-glasst w30" staticBackdrop show={props.showme}  >
        <div className="myheader">
          {mode === "register" ? <>Registrieren</> :null}
          {mode === "login" ? <>Login</> :null}
          {mode === "pwreset" ? <>Passwort setzen</> :null}
        </div>
        <div className="yellow" ><MyIPScanner /></div>
        <div className="red">{info}</div>
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />
        <br/>
        
      
        <MDBContainer className="tabcon" >
            
              <MDBRow>
                <MDBCol md="12">

                    <div className="m-3 p-3">
                      <MDBRow>
                        <MDBCol>
                          <TestCaptcha setCaptchaCode={setCaptchaCode} />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput 
                            value={ecode}
                            className=""
                            type="text" 
                            label={verified ? <>"verified"</> : "ich bin kein Roboter !" } 
                            onChange={(e) => updateCode(e.target.value)} 
                            />
                        </MDBCol>
                      </MDBRow>
                    </div>

                    <div className="m-3 p-3">
                      <MDBInput 
                        value={mode==="pwreset" ? props.email :email}
                        className=""
                        type="text" 
                        label="email" 
                        onChange={(e) => setEmail(e.target.value)} 
                        disabled={mode==="pwreset" ? "disabled" :null}
                        />
                    </div>
    
                    <div className="m-3 p-3">
                      <MDBInput 
                        className=""
                        value={password}
                        type="password" 
                        label="Passwort" 
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {mode === "register" || mode === "pwreset" ?
                      <MDBInput 
                        className=""
                        value={confirmpassword}
                        type="password" 
                        label="Passwort bestätigen"
                        onChange={(e) => setComfirmpassword(e.target.value)}
                      />
                      : null}
                    </div>
                    
                    {mode === "register" ?
                    <>
                    <h3 className="m-3">Name</h3>
                    <div className="m-3 p-3">
                      <MDBInput 
                        value={name}
                        className=""
                        type="text" 
                        label="Name" 
                        onChange={(e) => setName(e.target.value)} 
                        />
                      <MDBInput 
                        value={lastname}
                        className=""
                        type="text" 
                        label="Nachname" 
                        onChange={(e) => setLastname(e.target.value)} 
                        />
                    </div>
                    <h3 className="m-3">Adresse</h3>
                    <div className="m-3 p-3">
                      <MDBInput 
                        value={address1}
                        className=""
                        type="text" 
                        label="Adresse 1" 
                        onChange={(e) => setAddress1(e.target.value)} 
                        />
                      <MDBInput 
                        value={address2}
                        className=""
                        type="text" 
                        label="Adresse 2" 
                        onChange={(e) => setAddress2(e.target.value)} 
                        />
                      <MDBInput 
                        value={address3}
                        className=""
                        type="text" 
                        label="Adresse 3" 
                        onChange={(e) => setAddress3(e.target.value)} 
                        />
                    </div>
                    <h3 className="m-3">Telefon</h3>
                    <div className="m-3 p-3">
                      <MDBInput 
                        value={phone1}
                        className=""
                        type="text" 
                        label="Telefon 1" 
                        onChange={(e) => setPhone1(e.target.value)} 
                        />
                      <MDBInput 
                        value={phone2}
                        className=""
                        type="text" 
                        label="Telefon 2" 
                        onChange={(e) => setPhone2(e.target.value)} 
                        />
                    </div>
                    </>
                    : null}

                </MDBCol>
              </MDBRow>
              <MDBRow style={{textAlign:"right"}}>
              <MDBCol md="12">
              {verified ?
              <div className="m-3 p-3">
                {mode==="login" ?
                  <>
                    <MDBBtn onClick={() => toggleNewPassword()} className="btn btn-light">Passwort vergessen</MDBBtn>
                    <MDBBtn onClick={() => toggleLoginRegist()} className="btn btn-danger">Registrieren</MDBBtn>
                    <MDBBtn onClick={() => register("login", "user")} className="btn btn-info">Login</MDBBtn>
                    {false ?
                    <>
                    <MDBBtn onClick={() => register("login", "demo")} className="btn btn-success">Demo</MDBBtn>
                    <MDBBtn onClick={() => register("login", "test")} className="btn btn-success">Dev</MDBBtn>
                    </>
                    :null}
                  </>
                :null}
                {mode==="register" ?
                  <MDBBtn onClick={() => register("register", "")} className="btn btn-info">Registrieren</MDBBtn>
                :null                }
                {mode==="pwreset" ?
                  <MDBBtn onClick={() => register("pwreset", "")} className="btn btn-info">Passwort setzen</MDBBtn>
                :null                }
              </div>
              :null}
              </MDBCol>
              </MDBRow>
        </MDBContainer>
      </MDBModal>
    
    );
  
}