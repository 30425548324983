import React, { useState, useEffect } from 'react';

import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBIcon,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBRadio,
  MDBScrollbar
} from 'mdb-react-ui-kit';

import axios from 'axios';

export default function MyDialogData(props) {

  const [kpiData, setKpiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [newData, setNewData] = useState(false);
  const [critData, setCritData] = useState(null);
  const [myEvent, setMyEvent] = useState("Event"+Math.floor(Math.random() * 10000));
  const [myImage, setMyImage] = useState(null);
  
  const readKpi = (id, key) => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/kpis/getkpidata/'+id+"?key="+key;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/kpis/getkpidata/'+id+"?key="+key;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log("reading data..." + res.data);
        setKpiData(res.data);
        const ta = [];
        for(let j=0; j<res.data.label1.length; j++) {
          ta.push(" ");
        }
        setCritData(ta);
        setIsLoading(false);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
          setIsLoading(true);
        }
      })
  }
  
  useEffect(() => {
    readKpi(props.kpi, props.kpikey);
  },[])
  
  useEffect(() => {
    readKpi(props.kpi, props.kpikey);
  },[props.kpi])

  const writeData = (i) => {
    var bodyFormData = new FormData();
    bodyFormData.append('key', props.kpikey);
    bodyFormData.append('event', Date.now());
    
    for(let j=0; j<kpiData.label1.length; j++) {
      bodyFormData.append(kpiData.label1[j], kpiData.data1[j][i]);
    }

    if(props.iotype === "Bild" && myImage !== null){
      bodyFormData.append('image', myImage);
    }

    // CREATE new Data
    axios({
      method: "post",
      //url: "https://tgiot.herokuapp.com/api/v1/kpis/addkpidata/"+props.kpi,
      url: "https://ioteasybackend.herokuapp.com/api/v1/kpis/addkpidata/"+props.kpi,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      //handle success
      setInterval(true);
      readKpi(props.kpi, props.kpikey);
      console.log(response);
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
    //props.cleaner();
  }

  const makeDataTable = () => {
    //alert(kpi);
    let dt = [];

    for(let i=0; i<kpiData.label.length; i++) {
      let t=[];
      for(let k=0; k<kpiData.label1.length; k++) {
        t.push({label: kpiData.label1[i], data: kpiData.data1[k][i]});
      }
      dt.push({event: kpiData.label[i], datasets: t, pic: kpiData.picture1[0][i]});
    }
    
    return(
      dt.map((e,i) => 
        <tr>
          <td>{e.event}</td>
          {e.datasets.map((d,i) =>
            <td>
              {d.data}
            </td>
          )}
          <td>
            <img src={e.pic} className="img-fluid" alt="..." />
          </td>
          <td>
            <MDBIcon className="m-1 p-2 default" onClick={() => writeData(i)} fas icon="wrench"/>
            <MDBIcon className="m-1 p-2 red" onClick={() => delData(e.event, props.kpi)} fas icon="trash"/>
          </td>
        </tr>
      )
    )
  }
  
  const newval = () => {
    setNewData(true);
  }
  
  const setCritVal = (i,v) => {
    //alert(" length is " + critData.length + " set "+ i +" to "+v);
    const ta = [];
    for(let j=0; j<kpiData.label1.length; j++) {
      if(j==i) {
        ta.push(v);
      } else {
        ta.push(critData[j]);
      }
    }
    setCritData(ta);
  }
  
  const delData = (event, kpi_id) => {
    //alert(event+" "+kpi_id);
    var bodyFormData = new FormData();
    bodyFormData.append('event', event); 
    // POST del Data
    axios({
      method: "post",
      url: "https://ioteasybackend.herokuapp.com/api/v1/kpis/delkpidata/"+kpi_id,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      console.log(response);
      readKpi(props.kpi, props.kpikey);
    })
    .catch(function (response) {
      console.log(response);
    });
  };

  const writeNewData = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('key', props.kpikey);
    bodyFormData.append('event', myEvent);
    for(let j=0; j<kpiData.label1.length; j++) {
      bodyFormData.append(kpiData.label1[j], critData[j]);
    }
    if(props.iotype === "Bild" && myImage !== null){
      bodyFormData.append('imageFile', myImage);
    }

    // CREATE new Data
    axios({
      method: "post",
      //url: "https://tgiot.herokuapp.com/api/v1/kpis/addkpidata/"+props.kpi,
      url: "https://ioteasybackend.herokuapp.com/api/v1/kpis/addkpidata/"+props.kpi,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      //handle success
      //setInterval(true);
      readKpi(props.kpi, props.kpikey);
      console.log(response);
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
    //props.cleaner();
    setNewData(false);
  }

  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setMyImage(event.target.files[0] );  
    }
  }

  return (
    <>
    
      {!isLoading && kpiData.label1 ?
      <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">

      {newData ?
        <>
        <h1>Neue Daten {critData}</h1>
        <MDBInput
          input={"event"}
          className="m-2 p-2"                  
          label={"Event"} 
          value={myEvent}
          type="text" 
          onChange={(e) => setMyEvent(e.target.value)}
        />
        {kpiData.label1.map((l,i) =>
          <MDBInput
            input={l}
            className="m-2 p-2"                  
            label={l} 
            value={critData[i]}
            type="text" 
            onChange={(e) => setCritVal(i, e.target.value)}
          />
        )}
        
        {props.iotype === "Bild" ?
        <MDBInput 
          className="btn btn-lg btn-info"
          type="file" 
          name="main_image" 
          accept="image/png, image/jpeg, image/jpg" 
          multiple={false}
          onChange={handleImageChange} 
        />
        : null}

        <div style={{textAlign:"right"}}>
          <MDBBtn onClick={() => writeNewData()} type="Submit" className="m-3 btn btn-info">Speichern</MDBBtn>
          <MDBBtn onClick={() => setNewData(false)} type="Submit" className="m-3 btn-dark">Abbrechen</MDBBtn>
        </div>
        </>
        :null
      }

      <h1>Cloud Daten <MDBBtn className="btn btn-danger" onClick={() => newval()} >Daten hinzufügen</MDBBtn></h1>
      <MDBScrollbar style={{ textAlign:'center', position: 'relative', width: '600px', height: '500px' }}>
        <MDBTable style={{color:"#A0A0A0"}}>
          <MDBTableHead>
            <tr>
              <th scope='col'>Event</th>
              {kpiData.label1.map((l,i) => <th key={i} scope="col">{l}</th>)}
              <th scope='col'>Pic</th>
              <th scope='col'>Set</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {makeDataTable()}
          </MDBTableBody>
        </MDBTable>
      </MDBScrollbar>
      </div>
      : null}

    </> 
  );
}