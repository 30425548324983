import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import { MDBNavbar, MDBContainer, MDBNavbarBrand, MDBNavbarToggler, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBDropdownLink,
         MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';

import MyDash from './myComponents/myDash';
import MyDevices from './myComponents/myDevices';
import MyRules from './myComponents/myRules';
import MyAlerts from './myComponents/myAlerts';
import MyProjects from './myComponents/myProjects';
import AllDevices from './myComponents/allDevices';
import MyHome from './myComponents/myHome';
import MyProfile from './myComponents/myProfile';
import MyRegistration from './myComponents/myRegistration';
import MyNewPassword from './myComponents/myNewPassword';
import MyAdmin from './myComponents/myAdmin';
//import MyCaptcha from './myComponents/myCaptcha';
//import TestCaptcha from './myComponents/test';
import MyAbos from './myComponents/myAbos';
import MyNavigation from './myComponents/myNavigation';
import MyImpressum from './myComponents/myImpressum';
import MyContact from './myComponents/myContact';
import MyDialogDevice from './myComponents/myDialogDevice';
//import MyIPScanner from './myComponents/myIPScanner';
import MyTest from './myComponents/myTest';

import axios from 'axios';

export default function App (props) {

  let navigate = useNavigate();
  
  const [mySection, setMySection] = useState(null);

  const [showMenu, setShowMenu] = useState(true);
  const [showUser, setShowUser] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showIP, setShowIP] = useState(true);

  const [mode, setMode] = useState("login");
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);

  const [slimOpen, setSlimOpen] = useState(true);
  const [slimMode, setSlimMode] = useState(false);
  const [slimCollapse0, setSlimCollapse0] = useState(false);
  const [slimCollapse1, setSlimCollapse1] = useState(false);
  const [slimCollapse2, setSlimCollapse2] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [userKey, setUserKey] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [gruppe, setGruppe] = useState(null);

  const setKey=(mode, u) => {
    
    setShowRegistration(false);

    if(mode==="shortcut") {
      u={id:1, name:"Horst", lastname:"Wurm", userkey:"57dSbR", email: "horst.wurm@bluewin.ch"}
    }    

    if(u===null) {
      logout()
    } else {
      sessionStorage.setItem('userKey', u.userkey);
      setUser(u);
      //alert(u.userkey);
      readDashboards(u.userkey);
    }
    
    if(mode==="pwlink") {
    }

  };

  const logout=() => {
    sessionStorage.setItem('userKey', null);
    setUser(null);
    setDashboards([]);
    //showHome("TOP");
  };

  const genDash=() =>{
    return(
      user !== null && dashboards.length>0?
      dashboards.map((d,index) => 
        <MDBSideNavLink key={index} onClick={() => menu("mydashboard", index)}>
          <MDBIcon fas icon="tachometer-alt" className='fa-fw me-3' style={{marginLeft:"20px"}} />
          {d.dashboard}
        </MDBSideNavLink>
      ) : null
    );
  };
  
  useEffect(() => {
    
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get('token') !=null && queryParams.get('email') !=null)  {
      //alert("pwreset mode");
      setToken(queryParams.get('token'));
      setEmail(queryParams.get('email'));
      queryParams.delete('email');
      queryParams.delete('token');
      menu("pwreset", "pwreset");
    }
    
    if (user==null) {
      sessionStorage.setItem('userKey', null);
    }
    //showHome("TOP");

    const timer = setInterval(() => {
       if (userKey!==null) {
         readDashboards(userKey);
       }
    }, 30000);
    
    return () => {
      clearInterval(timer);
    };
  },[]);
  
  useEffect(() => {
    if (dashboards.length>0) {
      menu("mydashboard",0);
    }
  }, [dashboards]);

  const readDashboards=(key) =>{
    if(key===null) {
      return
    }
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards?key='+key;
    console.log(readString);

    axios.get(readString)
      .then(res => {
        setDashboards(res.data.dashboards);
        console.log(res.data.dashboards);
        setGruppe(res.data.dashboards[0].dashboard);
        setIsLoading(false);
        menu("mydashboard",0)
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      });
  };
  

  const menu = (p,i) => {
    
    switch (p) {
      case "login":
        setMode("login");
        window.scrollTo(0, 0);
        setShowRegistration(true);
        break;

      case "pwreset":
        setMode("pwreset");
        window.scrollTo(0, 0);
        setShowRegistration(true);
        break;

      case "testlogin":
        setUser({id:13, name:"IOTeasy", lastname:"IOTeasy", userkey:"B387nAAx", email: "ioteasy.ioteasy@bluewin.ch", abo:false});
        readDashboards("B387nAAx");
        break;

      case "register":
        setMode("register");
        setShowRegistration(true);
        break;

      case "logout":
        setUser(null);
        navigate('/home');
        break;

      case "mydevices":
        navigate('/devices');
        break;

      case "myrules":
        navigate('/rules');
        break;

      case "myalerts":
        navigate('/alerts');
        break;

      case "myabos":
        navigate('/abos');
        break;

      case "myadmin":
        navigate('/admin');
        break;

      case "myprojects":
        navigate('/projects');
        break;

      case "mygenerator":
        navigate('/mygenerator');
        break;

      case "mydashboard":
        setGruppe(dashboards[i].dashboard);
        navigate('/dashboards');
        break;

      case "mynewdashboard":
        setGruppe("Neue Gruppe");
        navigate('/dashboards');
        break;

      case "myprofile":
        setShowUser(true);
        break;

      case "mytest":
        navigate('/test');
        break;

      case "HOME":
        setMySection(i);
        navigate('/home');
        break;

      /*case "HOME":
        switch(i) {
          case "IMPRESSUM":
            modal.render(<MyImpressum />);
            break;

          case "EMAIL":
            modal.render(<MyContact user={user} />);
            break;

          default:
            page.render(<MyHome menu={menu} login={login} target={i} user={JSON.stringify(user)} />)
            break;
        }
        */

    }
  }
  
  const closeUserWin = () => {
    setShowUser(false);
  }

  const openRegistration = () => {
    setShowRegistration(true);
  }
  const closeRegistration = () => {
    setShowRegistration(false);
  }

  return(
    <>
    <MyNavigation user={user} dashboards={dashboards} menu={menu} />
    
    
    <Routes>
      <Route path="/" element={<MyHome user={null}/>} />
      <Route path="/home" element={<MyHome user={JSON.stringify(user)} target={mySection} />} />
      <Route path="/dashboards" element={<MyDash user={user} gruppe={gruppe}/>} />
      <Route path="/mygenerator" element={<AllDevices user={user} />} />
      <Route path="/devices" element={<MyDevices user={user} createDevice={menu} />} />
      <Route path="/rules" element={<MyRules user={user} />} />
      <Route path="/alerts" element={<MyAlerts user={user} />} />
      <Route path="/abos" element={<MyAbos user={user} />} />
      <Route path="/projects" element={<MyProjects user={user} />} />
      <Route path="/admin" element={<MyAdmin user={user} />} />
      <Route path="/test" element={<MyTest />} />
    </Routes>
    
    {showUser ? <MyProfile user={user} showme={showUser} closeme={closeUserWin} /> : null}

    {showRegistration ? <MyRegistration mode={mode} user={user} showme={showRegistration} closeme={closeRegistration} setkey={setKey} token={token} email={email} /> : null}

    </>
    );

  
}
