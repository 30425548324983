import React, { useState, useEffect } from 'react';
import { MDBChart, MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import TrafficLight from 'react-trafficlight';
import ManageBtn from './myBtnMgr';

import axios from 'axios';

export default function MyTrafficLight(props) {

    const [kpi, setKpi] = useState(JSON.parse(props.kpi));

    useEffect(() => {
    },[])
    
    useEffect(() => {
      setKpi(JSON.parse(props.kpi));
    },[props.kpi])

        return(
                <div style={{textAlign:'center'}}
                 className="
                        bg-glass
                        shadow-4-strong
                        rounded-6
                        p-4
                        ripple
                        "
                >
                {/*<MDBBtn onClick={() => this.work()} class="btn btn-sm btn-secondary rounded" style={{backgroundColor:"darkgrey", opacity:0.2, float:"right"}}><i class="fas fa-wrench"></i></MDBBtn>*/}
                <h2>{kpi.name} </h2>
                {kpi.data2[0] == "green" ? <TrafficLight GreenOn /> : null}
                {kpi.data2[0] == "yellow" ? <TrafficLight YellowOn /> : null}
                {kpi.data2[0] == "red" ? <TrafficLight RedOn /> : null}
                  <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
                </div>
        );

}