import React, { useState, useEffect } from 'react';
import { MDBChart, MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import ManageBtn from './myBtnMgr';

import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  HorizontalBar,
  Pie,
  Radar,
  Scatter,
  Chart
} from 'react-chartjs-2'; 


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';


import axios from 'axios';

export default function MyCharter(props) {

    const [kpi, setKpi] = useState(JSON.parse(props.kpi));

    useEffect(() => {
      setKpi(JSON.parse(props.kpi));
    },[])
    
    useEffect(() => {
      setKpi(JSON.parse(props.kpi));
    },[props.kpi])



  //gra = {};
  
  const genChart=(ctx) => {
    
            //alert(kpi.label1);
    
            const color1 = 'rgba(0,255,255,1)';
            const color2 = 'rgba(255,0,255,1)';
            const color3 = 'rgba(255,255,0,1)';
            const color4 = 'rgba(255,0,0,1)';
            const color5 = 'rgba(0,255,0,1)';
            const color6 = 'rgba(0,0,255,1)';
            const color7 = 'rgba(169,169,169,1)';
            const colors = [color1, color2, color3, color4, color5, color6, color7];

            const color1b = 'rgba(0,255,255,0.1)';
            const color2b = 'rgba(255,0,255,0.1)';
            const color3b = 'rgba(255,255,0,0.1)';
            const color4b = 'rgba(255,0,0,0.1)';
            const color5b = 'rgba(0,255,0,0.1)';
            const color6b = 'rgba(0,0,255,0.1)';
            const color7b = 'rgba(169,169,169,0.1)';
            const colorsb = [color1b, color2b, color3b, color4b, color5b, color6b, color7b];

            //const color1 = '#a9a9a9';
            //const color2 = '#00FFFF';
            //const color3 = '#FF00FF';
            //const color4 = '#FFFF00';
            //const color5 = '#FF0000';
            //const color6 = '#00FF00';
            //const color7 = '#0000FF';
            

            {/*
            const gradient1 = ctx.createLinearGradient(0, 100, 0, 450);
            gradient1.addColorStop(0, 'rgba(169, 169, 169, 0.4)');
            gradient1.addColorStop(0.1, 'rgba(169, 169, 196, 0.2)');
            gradient1.addColorStop(1, 'rgba(0, 0, 0, 0.1)');

            const gradient2 = ctx.createLinearGradient(0, 100, 0, 450);
            gradient2.addColorStop(0, 'rgba(0,255,255,0.4)');
            gradient2.addColorStop(0.1, 'rgba(0,255,255,0.2)');
            gradient2.addColorStop(1, 'rgba(0,255,255,0.1)');

            const gradient3 = ctx.createLinearGradient(0, 100, 0, 450);
            gradient3.addColorStop(0, 'rgba(255,0,255,0.4)');
            gradient3.addColorStop(0.1, 'rgba(255,0,255,0.2)');
            gradient3.addColorStop(1, 'rgba(255,0,255,0.1)');

            const gradient4 = ctx.createLinearGradient(0, 100, 0, 450);
            gradient4.addColorStop(0, 'rgba(255,255,0,0.4)');
            gradient4.addColorStop(0.1, 'rgba(255,255,0,0.2)');
            gradient4.addColorStop(1, 'rgba(255,255,0,0.1)');

            const gradient5 = ctx.createLinearGradient(0, 100, 0, 450);
            gradient5.addColorStop(0, 'rgba(255,0,0,0.3)');
            gradient5.addColorStop(0.1, 'rgba(255,0,0,0.2)');
            gradient5.addColorStop(1, 'rgba(255,0,0,0.1)');

            const gradient6 = ctx.createLinearGradient(0, 100, 0, 450);
            gradient6.addColorStop(0, 'rgba(0,255,0,0.3)');
            gradient6.addColorStop(0.1, 'rgba(0,255,0,0.2)');
            gradient6.addColorStop(1, 'rgba(0,255,0,0.1)');

            const gradient7 = ctx.createLinearGradient(0, 100, 0, 450);
            gradient7.addColorStop(0, 'rgba(0,0,255,0.3)');
            gradient7.addColorStop(0.1, 'rgba(0,0,255,0.2)');
            gradient7.addColorStop(1, 'rgba(0,0,255,0.1)');

            const gradients = [gradient1, gradient2, gradient3, gradient4, gradient5, gradient6, gradient7];
            */}
            
            const d = kpi.label1;
            var ds = d.map((label, i) => {
              return{
                        fill : 'start',
                        label: label,
                        //backgroundColor : gradients[i], // Put the gradient here as a fill color
                        borderColor : colors[i],
                        backgroundColor: colorsb[i],
                        borderWidth: 1,
                        pointColor : colors[i],
                        pointStrokeColor : colors[i],
                        pointHighlightFill: colors[i],
                        pointHighlightStroke: colors[i],
                        data: kpi.data1[i]
              };
            });
            
            //this.gra = gradient1;
            //console.log(ds);

            return ({
                labels: kpi.label,
                datasets: ds
            }) 
  }
  
  const divStyle = {
        width: 200,
        height: 200
    };
    
  const options = {
        responsive: true,
        datasetStrokeWidth : 3,
        pointDotStrokeWidth : 4,
        legend: {
          labels: {
            fontColor: "#f00",
          }
        }
    };
    
    const data = () => {
        //const ctx = canvas.getContext("2d");
        //console.log(this.genChart(ctx));
        //alert(ctx);
        //return(genChart(ctx));
        return(genChart(0));
    }

    return(
            <div style={{textAlign:'center'}}
             className="
                    bg-glass
                    shadow-4-strong
                    rounded-6
                    p-4
                    ripple
                    "
            >
            <h2>{kpi.name}</h2>
            <div className="p-3 bg-theme rounded-4">
              <Line data={data()} options={options} style={divStyle} />
            </div>
            <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
          </div>

        /* ====== Test für MDBChart !!!
        <div class="p-2 bg-glass shadow-4-strong rounded-6 ripple">
          <div classi="p-3 bg-theme rounded-4">
            <MDBChart
              type='line'
              data={{ labels:["1","2"], datasets:[{label:"Label", data:[12,13], borderColor: '#0000ff', fill: 'start', backgroundColor:this.gra}] }}
              data={{
                labels: this.state.daten.label,
                datasets: [
                  // First dataset (bar)
                  {
                    fill : 'start',
                    label: this.state.daten.label1[0],
                    backgroundColor : this.gra, // Put the gradient here as a fill color
                    borderColor : '#0000ff',
                    borderWidth: 1,
                    pointColor : "#fff",
                    pointStrokeColor : '#0000ff',
                    pointHighlightFill: "#0000ff",
                    pointHighlightStroke: '#0000ff',
                    data: this.state.daten.data1[0]
                  }
                    ],
                  }}
                stylei={this.divStyle}
            />
          </div>
      </div> */
      
    );
}
