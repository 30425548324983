import React, { useState, useEffect } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBInputGroup,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBAccordion, 
  MDBAccordionItem,
  MDBRadio,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBValidation,
  MDBIcon,
  MDBScrollbar
} from 'mdb-react-ui-kit';

export default function MyImpressum(props){

  return (
      <MDBModal className="bg-glasst" staticBackdrop show={props.show} >
      
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />
        <br />< br />

        <div className="myheader">Kontakt</div>
        <p>Plattform IOTeasy<br/>
        c/o<br/>
        Hörnliblick 11<br/>
        9556 Zezikon<br/>
        </p>
        
        <p>Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO) und des Bundesgesetzes über den Datenschutz (DSG) ist IOTeasy c/o. Jede betroffene Person kann sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt via E-Mail an die oben genannte Adresse wenden. Ausserdem besteht die Möglichkeit ein Kontaktformular auf der Webseite zu senden</p>
        
        <div className="myheader">Datenschutz</div>
        <p>Die Nutzung der Internetseite https://ioteasyfrontend.herokuapp.com ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Um die Dienste jedoch nutzen zu können, ist eine entsprechende Registrierung notwendig; dann ist auch eine Verarbeitung personenbezogener Daten erforderlich. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.
        Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und den geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
        Die IOTeasy Plattform hat technische und organisatorische Massnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen Person frei, uns personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch, zu übermitteln. Die Löschung eines IOTeasy Accounts durch den Nutzer löscht alle Kundendaten unwiderruflich</p>
        
        <div className="myheader">Rechtsgrundlage</div>
        <p>Die Website https://ioteasyfrontend.herokuapp.com unterliegt dem schweizerischem Datenschutzrecht, insbesondere dem Bundesgesetz über den Datenschutz (DSG) sowie allenfalls anwendbarem ausländischem Datenschutzrecht wie beispielsweise der Datenschutz-Grundverordnung (DSGVO) der Europäischen Union (EU). Die EU anerkennt, dass das schweizerische Datenschutzrecht einen angemessenen Datenschutz gewährleistet.
        Wir können unsere Datenschutzerklärung jederzeit durch Veröffentlichung auf dieser Website anpassen.</p>
        
        <div className="myheader">Geltungsbereich</div>
        <p>Die Datenschutzerklärung gilt für ioteasyfrontend.herokuapp.com. Zu Webseiten, zu welchen ein Link besteht, wird keine Gewähr für die Einhaltung der Datenschutzbestimmungen übernommen.</p>
        
        <div className="myheader">Haftungsausschluss</div>
        <p>Die Betreiberin der Webseite übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.
        Haftungsansprüche gegen die Betreiberin der Webseite wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.
        Alle Angebote sind unverbindlich. Die Betreiberin der Webseite behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
        
        <div className="myheader">Haftung für Links</div>
        <p>Unsere Webseite enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstösse überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
        
        <div className="myheader">Urheberrechte</div>
        <p>Sämtliche Texte, Fotos und PDF-Files auf https://ioteasyfrontend.herokuapp.com sind urheberrechtlich geschützt. Diese Daten dürfen nur mit Zustimmung des Urheberrechtsinhabers verwendet werden.</p>
        
        <div className="myheader">Überwachung</div>
        <p>Der Zugriff auf unsere Website unterliegt – wie jede Internet-Nutzung – der allgemeinen, anlasslosen, unterschiedlosen und verdachtsunabhängigen Massenüberwachung durch Sicherheitsbehörden in der Schweiz, in der EU, in den USA und in anderen Staaten. Die Betreiberin der Webseite hat keinen direkten Einfluss auf die entsprechende Bearbeitung von Personendaten durch Geheimdienste, Polizeistellen und sonstigen Sicherheitsbehörden.</p>
        
        <div className="myheader">Verschlüsselung und Logfiles</div>
        <p>Der Zugriff auf https://ioteasyfrontend.herokuapp.com erfolgt mittels Transportverschlüsselung (SSL/TLS). Das Hosting der Applikation und der Daten erfolgt bei SALESFORCE (HEROKU) und AMAZON. Für den Betrieb werden Logfiles gespeichert.</p> 
        
        <div className="myheader">Weitergabe von Personendaten</div>
        <p>Es werden keinerlei personenbezogene Daten ausserhalb von IOTeasy mit Dritten geteilt.</p>
        
        <div className="myheader">Cookies</div>
        <p>Die Internetseite https://ioteasyfrontend.herokuapp.com verwendet Cookies. Cookies sind Textdateien, welche über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert werden. Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.
        Der Einsatz von Cookies erhöht die Benutzerfreundlichkeit.</p>
        
        <p>Diese Datenschutzerklärung wurde am 25. Oktober 2022 zuletzt angepasst und kann jederzeit angepasst werden.</p>

      </MDBModal>
     
  );
}