import React, { useState, useEffect } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBInputGroup,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBAccordion, 
  MDBAccordionItem,
  MDBRadio,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBValidation,
  MDBIcon,
  MDBScrollbar
} from 'mdb-react-ui-kit';

import axios from 'axios';
//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export default function MyContact(props){

  const [staticModal, setStaticModal] = useState(true);
  const toggleShow = () => setStaticModal(!staticModal);

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [info, setInfo] = useState("");

  const sendEmail = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('email_from', email);
    //bodyFormData.append('email_to', email_to);
    bodyFormData.append('header', header);
    bodyFormData.append('body', body);

      axios({
        method: "post",
        url: "https://ioteasybackend.herokuapp.com/api/v1/emails",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response.data.message);
          if(response.data.status=="OK") { 
            props.closeme();
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response.message);
          setInfo("error sending email!");
        });
  };
  
  useEffect(() => {
    if(props.user) {
      //alert(props.user);
      setUser(props.user);
      setEmail(props.user.email);
    }
  }, [props.user])


  return (

      <MDBModal className="bg-glasst w30" staticBackdrop show={props.show} >
        <div className="myheader">Kontakt</div>
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />

              <MDBRow>
                <MDBCol>
                  <div className="m-3 p-3">
                    <MDBInput 
                      value={email}
                      className=""
                      type="text" 
                      label="your email" 
                      onChange={(e) => setEmail(e.target.value)} 
                      />
                  </div>

                  <div className="m-3 p-3">
                    <MDBInput 
                      className=""
                      value={header}
                      type="text" 
                      label="subject" 
                      onChange={(e) => setHeader(e.target.value)}
                    />
                    <MDBInput 
                      textarea
                      className=""
                      value={body}
                      type="text" 
                      label="text" 
                      rows={4}
                      onChange={(e) => setBody(e.target.value)}
                    />
                  </div>
          
                </MDBCol>
              </MDBRow>
              <MDBBtn onClick={() => sendEmail()} className="btn btn-info">Senden</MDBBtn>
              <br /><br />

            {/*
            <MDBModalFooter className="modalfooter">
              <MDBBtn onClick={() => sendEmail()} className="btn btn-dark">Senden</MDBBtn>
            </MDBModalFooter>
            */}
      </MDBModal>
    
  );
}