import React from 'react';

import { MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

export default function MyYesNoBtn(props) {

    return(
      <MDBModal staticBackdrop show={true} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader className="modalheader">
              <MDBModalTitle>{props.title} ({props.id})</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => props.delete(0)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="bgnew"><h1>{props.question} </h1></MDBModalBody>
  
            <MDBModalFooter className="modalfooter">
              <MDBBtn className="btn btn-danger" onClick={() => props.delete(true, props.id)}>Löschen</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    )

}