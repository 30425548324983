import React, { useState, useEffect } from 'react';
import { 
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBScrollbar,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBIcon
} from 'mdb-react-ui-kit';

import {devices, deviceTypes, deviceCats, connectTypes, loraTypes, loraBands, dashOptions} from './param';
import ErrorHints from './errorHints';
//import CodeIntro from './codeIntro';
//import CodeInit from './codeInit';
//import CodeSetup from './codeSetup';
//import CodeLoop from './codeLoop';
import CodeFunc_gen from './codeFunc_gen';
import MyWirer from './myWirer';

import CodeIntro_gen from './codeIntro_gen';
import CodeInit_gen from './codeInit_gen';
import CodeSetup_gen from './codeSetup_gen';
import CodeLoop_noCon from './codeLoop_noCon';

export default function MyCoder(props) {

  const [fillActive, setFillActive] = useState('tab1');
  const [staticModal, setStaticModal] = useState(true);
  
  const device = {connectivity:"nocon"};

  useEffect(() => {
  }, [props.did]);
  
  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }
    setFillActive(value);
  };
  
  const clipcopy = () => {

    //var copyText = document.getElementById("clip").textContent.toString();
    //var copyText = document.getElementById("clip").textContent;

    var text = document.getElementById("clip").innerText;
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });

  }
  
  return (
    <>
      <div className="bg-glasst" staticBackdrop show={props.showme} >
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />
        <br />
        <MDBContainer className="tabcon">

              <MDBTabs fill className='mb-3' >
                <MDBTabsItem>
                  <MDBTabsLink  className="tl" onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
                    Bauteile und Verkabelung
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink  className="tl" onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
                    MC Programmcode
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink  className="tl" onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
                    Fehlerhandling
                  </MDBTabsLink>
                </MDBTabsItem>
                {/*
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleFillClick('tab4')} active={fillActive === 'tab4'}>
                    Widgets
                  </MDBTabsLink>
                </MDBTabsItem>
                */}
              </MDBTabs>

              <MDBTabsContent>
                <MDBTabsPane show={fillActive === 'tab1'}>
                  <MyWirer did={props.did} />
                </MDBTabsPane>

                <MDBTabsPane show={fillActive === 'tab2'}>

                    <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                      <MDBScrollbar style={{ textAlign:'left', position: 'relative', width: '100%', height: '500px' }}>
                    
                        <MDBBtn onClick={() => clipcopy()} className="btn btn-warning" style={{margin:"10px"}}>Copy to Clipboard</MDBBtn>

                        <code>
                        <pre>
                        <div id="clip" style={{color:"lightgrey"}}>
                          <CodeIntro_gen did={props.did} devices={props.devices} device={null} />
                          <CodeInit_gen did={props.did} devices={props.devices} device={null}/>
                          <CodeSetup_gen did={props.did} devices={props.devices} device={null}/>
                          <CodeLoop_noCon did={props.did} devices={devices} device={null}/>
                          <CodeFunc_gen did={props.did} devices={props.devices} device={null} />
                        </div>
                        </pre>
                        </code>
                        
                    </MDBScrollbar>
                  </div>
                      
                </MDBTabsPane>

                <MDBTabsPane show={fillActive === 'tab3'}>
                  <ErrorHints nocon={true}/>
                </MDBTabsPane>

                <MDBTabsPane show={fillActive === 'tab4'}>

                  <h1>Unterstützte Widgets</h1>
                  <MDBContainer>
                    <MDBRow>
                      {devices[props.did].dashoptions.map((d,i) => 
                          <MDBCol key={i} className="m-2 p-2">
                          {dashOptions[devices[props.did].dashoptions[i]].text+".png"}
                          <img src={dashOptions[devices[props.did].dashoptions[i]].text+".png"} 
                            width="200px"
                            height="200px"        
                            objectfit="cover" 
                          />
                          </MDBCol>
                          )}
                    </MDBRow>
                  </MDBContainer>

                </MDBTabsPane>
              </MDBTabsContent>
        </MDBContainer>
      </div>
      
    </>
  );
}