import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon,   MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselElement, MDBCarouselCaption, MDBContainer } from 'mdb-react-ui-kit';

export default function MyAbo(props) {

  const [isloading, setIsLoading] = useState(false);

  const [abos, setAbos] = useState(0);

  const readAbos = () => {
    return
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/abos';
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data.admin);
        
        setAbos(res.data.abos);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  

  //life-cycle hooks
  useEffect(() => {
    readAbos();
    const timer = setInterval(() => {
       readAbos();
    }, 30000);
    
    return () => {
      clearInterval(timer);
    }    
    
  },[]);
  

  const aboItem = (typ, price) => {
    return(
          <MDBCol key={typ} sm="12" md="6" lg="3" xl="3" xxl="3">
            <div  style={{textAlign:'center', height:600}}
               className="
                      bg-glass
                      shadow-4-strong
                      rounded-6
                      p-4
                      ripple
                      "
              >
              <h3>
                {typ}
              </h3>
              <br />
              <hr />
                {typ=="Freemium" ?
                    <div style={{textAlign:"center"}}>
                        <div className="green mid">
                            {price.toFixed(2)} CHF
                        </div>
                        <hr />
                        Dashboard-Items: 3<br/>
                        Datensätze pro Dashboard-Item: max 20<br/>
                        Throttle: Reads/Writes pro Minute max 5<br/>
                        IoT-Devices: 5<br/>
                        Code-Generator: Vollversion<br/>
                        Alarmierung Dashboard: ja<br/>
                        Alarmierung eMail/SMS: nein<br/>
                        Regel-Engine: nein<br/>
                        Support: nein<br/>
                    </div>
                    :null
                }

                {typ=="Premium M" ?
                    <div style={{textAlign:"center"}}>
                        <div className="cyan mid">
                            {price.toFixed(2)} CHF monatlich<br/>
                        </div>
                        <hr />
                        Dashboard-Items: unbeschränkt<br/>
                        Datensätze pro Dashboard-Item: max 200<br/>
                        Throttle: Reads/Writes pro Minute max 20<br/>
                        IoT-Devices: unbeschränkt<br/>
                        Code-Generator: Vollversion<br/>
                        Alarmierung Dashboard: ja<br/>
                        Alarmierung eMail/SMS: ja<br/>
                        Regel-Engine: ja<br/>
                        Support: eMail<br/>
                    </div>
                    :null
                }

                {typ=="Premium Y" ?
                    <div style={{textAlign:"center"}}>
                        <div className="cyan mid">
                            {price.toFixed(2)} CHF jährlich<br/>
                        </div>
                        <hr />
                        Dashboard-Items: unbeschränkt<br/>
                        Datensätze pro Dashboard-Item: max 200<br/>
                        Throttle: Reads/Writes pro Minute max 20<br/>
                        IoT-Devices: unbeschränkt<br/>
                        Code-Generator: Vollversion<br/>
                        Alarmierung Dashboard: ja<br/>
                        Alarmierung eMail/SMS: ja<br/>
                        Regel-Engine: ja<br/>
                        Support: eMail<br/>
                    </div>
                    :null
                }

                {typ=="Starter Kit" ?
                    <div style={{textAlign:"center"}}>
                        <div className="yellow mid">
                            {price.toFixed(2)} CHF<br/>
                        </div>
                        <hr />
                        Das Starter Kit enthält neben dem Premium Y Paket auch ein Konvolut mit ca. 30 Sensoren & Aktoren, sowie 2 Microprozessoren ESP32, Dupont- & USB-Kabel. Damit kann man sofort loslegen und in die IOT-Welt eintauchen.
                    </div>
                    :null
                }

              <div className="kachelbtn">
                {typ!= "Freemium" && props.user != null ?
                <div onClick={() => props.buy(typ, price)} style={{position:"absolute", bottom:20, right:20}}><i className="fas fa-credit-card p-2"></i></div>
                : null}
              </div>

            </div>
          </MDBCol>
      )
  }

  return(
      isloading ? 
        <div className="loader">
          <MDBIcon className="fa-10x" spin fas icon='spinner' />
          <br /><br />
          <h1>loading ABOs...</h1> 
        </div> 
      :
          <MDBContainer fluid className="cool">
          <div className="myheader">Was kostet IoTeasy ?</div>
          <MDBRow>
            {aboItem("Freemium",0)}
            {aboItem("Premium M",4.9)}
            {aboItem("Premium Y",49)}
            {aboItem("Starter Kit",149)}
          </MDBRow>
          </MDBContainer>
    )

}