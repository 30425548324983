import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBIcon,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBRadio,
  MDBCard, 
  MDBCardBody, 
  MDBCardTitle, 
  MDBCardText, 
  MDBCardImage, 
  MDBRipple  
} from 'mdb-react-ui-kit';

import MyOnOffButton from './myOnOffButton';
import MyGaugeChart from './myGaugeChart';
import MyColorPicker from './myColorPicker';
import MyCircleSlider from './myCircleSlider';
import MyCharter from './myCharter';
import MyMap from './myMap';
import MyTrafficLight from './myTrafficLight';
import MyImager from './myImager';
import MyTexter from './myTexter';
import MyURLChecker from './myURLChecker';
import MyQRCoder from './myQRCoder';
import MyDialogData from './myDialogData';

import axios from 'axios';

export default function MyDialogKpi(props) {

  const [kpi, setKpi] = useState(props.kpi);
  const [kpiID, setKpiID] = useState(null);
  const [kpiKey, setKpiKey] = useState(null);

  const [searcher, setSearcher] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [first, setFirst] = useState(true);

  const [staticModal, setStaticModal] = useState(true);

  const [kpiUser, setKpiuser] = useState(props.user.id);
  const [kpiIndex, setKpiIndex] = useState(1);
  const [kpiIotype, setKpiIotype] = useState("Schalter");
  const [kpiKtype, setKpiKtype] = useState("Value");
  const [kpiIotNumber, setKpiIotNumber] = useState(props.iotnumber);
  const [kpiGruppe, setKpiGruppe] = useState(props.gruppe);
  const [kpiName, setKpiName] = useState("Test");
  const [kpiLabel, setKpiLabel] = useState("Grad");
  const [kpiKeepVal, setKpiKeepVal] = useState(5);
  const [kpiSmartwatch, setKpiSmartwatch] = useState(false);

  const [kpiCrits, setKpiCrits] = useState([]);

  const [newKpiUser, setNewKpiUser] = useState(false);
  const [kpiUsers, setKpiUsers] = useState([]);

  const [kpiUG, setKpiUG] = useState(0);
  const [kpiOG, setKpiOG] = useState(25);
  const [kpiAlerthigh, setKpiAlerthigh] = useState(true);

  const [newCrit, setNewCrit] = useState(false);
  const [updCrit, setUpdCrit] = useState(false);
  
  const [critId, setCritId] = useState(0);
  const [critKpiId, setCritKpiId] = useState(0);
  const [critName, setCritName] = useState("Test");
  const [critUwert, setCritUwert] = useState(0);
  const [critOwert, setCritOwert] = useState(100);
  const [critSwert1, setCritSwert1] = useState(20);
  const [critSwert2, setCritSwert2] = useState(50);
  const [critAlertHigh, setCritAlertHigh] = useState(true);
  const [critAlert, setCritAlert] = useState(false);
  const [critAlertDash, setCritAlertDash] = useState(false);
  const [critAlertEmail, setCritAlertEmail] = useState(false);
  const [critAlertWhatsapp, setCritAlertWhatsapp] = useState(false);
  const [critAlertSms, setCritAlertSms] = useState(false);
  const [critTyp, setCritTyp] = useState("Numerisch");
  const [critTextAlert, setCritTextAlert] = useState("");
  
  const [users, setUsers] = useState([]);
  
  const searchuser = "";
  
  const [fillActive, setFillActive] = useState('tab1');

  useEffect(() => {
    //alert("hook "+kpi);
    if (props.kpi !== null) {
      /*setKpi(JSON.parse(props.kpi));
      const id = JSON.parse(props.kpi).id;*/
      readUsers();
      readKpi(props.kpi);
      readCrits(props.kpi);
      readKpiUsers(props.kpi);
    }
  },[props.kpi]); 
  
  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }
    setFillActive(value);
  };
  
  const dashOptions = [
    {value:1, text:"Schalter", img: 'espsoil.jpg', selected:true},
    {value:2, text:"Regler", img: 'espsoil.jpg'},
    {value:3, text:"Regler RGB", img: 'espsoil.jpg'},
    {value:4, text:"Ampel", img: 'espsoil.jpg'},
    {value:5, text:"Tachometer", img: 'espsoil.jpg'},
    {value:6, text:"Text", img: 'espsoil.jpg'},
    {value:16, text:"QRCode", img: 'espsoil.jpg'},
    {value:7, text:"Laufschrift", img: 'espsoil.jpg'},
    {value:8, text:"Graphik", img: 'espsoil.jpg'},
    {value:9, text:"Graphik n-Dim", img: 'espsoil.jpg'},
    {value:10, text:"Karte", img: 'espsoil.jpg'},
    {value:11, text:"Bild", img: 'espsoil.jpg'},
    {value:12, text:"Video", img: 'espsoil.jpg'},
    {value:13, text:"URL check", img: 'espsoil.jpg'},
    {value:14, text:"Kamera Stream", img: 'espsoil.jpg'},
    {value:15, text:"Komplex", img: 'espsoil.jpg'},
  ];

  const toggleShow = () => setStaticModal(!staticModal);

  const readUsers = () => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/users';
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/users';
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log("Anzahl User ok..." + res.data.users.length);
        setUsers(res.data.users);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  
  
  const readKpi = (kpi) => {
    setIsLoading(true);
    //const readString = 'https://tgiot.herokuapp.com/api/v1/kpis/'+kpi;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/kpis/'+kpi;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log("ok..." + res.data.kpi.iotype);
        setKpiID(res.data.kpi.id);
        setKpiKey(res.data.kpi.iotnumber);
        setKpi(res.data.kpi);
        setKpiuser(res.data.kpi.user_id);
        setKpiName(res.data.kpi.name);
        setKpiGruppe(res.data.kpi.gruppe);
        setKpiKtype(res.data.kpi.ktype);
        setKpiIotype(res.data.kpi.iotype);
        setKpiIotNumber(res.data.kpi.iotnumber);
        setKpiLabel(res.data.kpi.label);
        setKpiKeepVal(res.data.kpi.keepval);
        setKpiSmartwatch(res.data.kpi.smartwatch);
        setIsLoading(false);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
          setIsLoading(true);
        }
      })
  }
  
  const readCrit = (crit) => {
    setNewCrit(false);
    setIsLoading(true);
    //const readString = 'https://tgiot.herokuapp.com/api/v1/crits/'+crit;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/crits/'+crit;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log("ok..." + res.data.crit.id);
        setCritId(res.data.crit.id);
        setCritName(res.data.crit.name);
        setCritAlertHigh(res.data.crit.alerthigh);
        setCritUwert(res.data.crit.uwert);
        setCritOwert(res.data.crit.owert);
        setCritSwert1(res.data.crit.swert1);
        setCritSwert2(res.data.crit.swert2);
        setCritAlert(res.data.crit.alert);
        setCritAlertDash(res.data.crit.alertdash);
        setCritAlertEmail(res.data.crit.alertemail);
        setCritAlertWhatsapp(res.data.crit.alertwhatsapp);
        setCritAlertSms(res.data.crit.alertsms);
        setCritTyp(res.data.crit.typ);
        setCritTextAlert(res.data.crit.textalert);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  setIsLoading(false);
  }
  
  const readCrits = (k) => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/crits?kpi_id='+k;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/crits?kpi_id='+k;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log("ok..." + res.data.crits);
        setKpiCrits(res.data.crits);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  
  
  const readKpiUsers = (k) => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/kpis/kpiusers/'+k;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/kpis/kpiusers/'+k;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log("ok Anzahl KPIUser..." + res.data.users.length);
        setKpiUsers(res.data.users);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  

  const handleKpi = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData();
    bodyFormData.append('user_id', kpiUser);
    bodyFormData.append('name', kpiName);
    bodyFormData.append('gruppe', kpiGruppe);
    bodyFormData.append('ktype', "Value");
    bodyFormData.append('iotnumber', kpiIotNumber);
    bodyFormData.append('iotype', kpiIotype);
    bodyFormData.append('label', kpiLabel);
    bodyFormData.append('keepval', kpiKeepVal);
    bodyFormData.append('smartwatch', kpiSmartwatch);
    
    // CREATE KPI
    if(kpi==null) {
      bodyFormData.append('uwert', 0);
      bodyFormData.append('owert', 100);
      axios({
        method: "post",
        //url: "https://tgiot.herokuapp.com/api/v1/kpis/",
        url: "https://ioteasybackend.herokuapp.com/api/v1/kpis/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          //alert("success");
          console.log(response);
          //alert(response.data.id);
          setKpiID(response.data.id);
          setKpiKey(response.data.iotnumber);
          readKpi(response.data.id);
          readCrits(response.data.id);
          readUsers();
          setIsLoading(false);
        })
        .catch(function (response) {
          //handle error
          //alert("failure");
          console.log(response);
        });
    } else {
      // UPDATE KPI
      axios({
        method: "put",
        //url: "https://tgiot.herokuapp.com/api/v1/kpis/"+kpi.id,
        url: "https://ioteasybackend.herokuapp.com/api/v1/kpis/"+kpi.id,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          props.close();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }
  
  const handleUpdateDash = (i) => {
    //console.log("you pressed"+i);
    //setKpiIndex(i);
  };

  const handleIoType = (typ) => {
    //console.log(index);
    //setKpiType(dashOptions[index].text);
    setKpiIotype(typ.text);
    setKpiIndex(typ.value);
  };

  const handleKpiName = (e) => {
    console.log(e.target.label);
    setKpiName(e.target.value);
  };
  const handleKpiGruppe = (e) => {
    console.log(e.target.label);
    setKpiGruppe(e.target.value);
  };
  const handleUG = (e) => {
    setKpiUG(e.target.value);
  };
  const handleOG = (e) => {
    setKpiOG(e.target.value);
  };
  const handleKpiLabel = (e) => {
    setKpiLabel(e.target.value);
  };
  const handleSmart = (e) => {
    setKpiSmartwatch(e.target.checked);
  };
  const handleAnz = (e) => {
    setKpiKeepVal(e.target.value);
  };
  const newKey = (e) => {
    setKpiIotNumber(e.target.value);
  };

  const handleCrit = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData();
    bodyFormData.append('kpi_id', kpi.id);
    bodyFormData.append('name', critName);
    bodyFormData.append('uwert', critUwert);
    bodyFormData.append('owert', critOwert);
    bodyFormData.append('swert1', critSwert1);
    bodyFormData.append('swert2', critSwert2);
    bodyFormData.append('alerthigh', critAlertHigh);
    bodyFormData.append('alert', critAlert);
    bodyFormData.append('alertsms', critAlertSms);
    bodyFormData.append('alertwhatsapp', critAlertWhatsapp);
    bodyFormData.append('alertdash', critAlertDash);
    bodyFormData.append('alertemail', critAlertEmail);
    bodyFormData.append('typ', critTyp);
    bodyFormData.append('textalert', critTextAlert);

    // CREATE CRIT
    if(newCrit) {
      setNewCrit(false);
      axios({
        method: "post",
        //url: "https://tgiot.herokuapp.com/api/v1/crits/",
        url: "https://ioteasybackend.herokuapp.com/api/v1/crits/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          readCrits(kpi.id)
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      // UPDATE CRIT
      axios({
        method: "put",
        //url: "https://tgiot.herokuapp.com/api/v1/crits/"+critId,
        url: "https://ioteasybackend.herokuapp.com/api/v1/crits/"+critId,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          //alert("waiting...");
          readCrits(kpi.id);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    //props.cleaner();
    setUpdCrit(false);
    //readCrits(kpi.id)
  }
  
  const updateCrit = (e) => {
    switch (e.target.id) {
      case "critName":
      {
        setCritName(e.target.value);
        break;
      }
      case "critUwert":
      {
        setCritUwert(e.target.value);
        break;
      }
      case "critOwert":
      {
        setCritOwert(e.target.value);
        break;
      }
      case "critSwert1":
      {
        setCritSwert1(e.target.value);
        break;
      }
      case "critSwert2":
      {
        setCritSwert2(e.target.value);
        break;
      }
      case "critAlert":
      {
        setCritAlert(e.target.checked);
        break;
      }
      case "critAlertHigh":
      {
        setCritAlertHigh(e.target.checked);
        break;
      }
      case "critAlertDash":
      {
        setCritAlertDash(e.target.checked);
        break;
      }
      case "critAlertEmail":
      {
        setCritAlertEmail(e.target.checked);
        break;
      }
      case "critAlertWhatsapp":
      {
        //alert(e.target.id + " ===> " + e.target.checked);
        setCritAlertWhatsapp(e.target.checked);
        break;
      }
      case "critAlertSms":
      {
        //alert(e.target.id + " ===> " + e.target.checked);
        setCritAlertSms(e.target.checked);
        break;
      }
      case "Numerisch":
      {
        setCritTyp(e.target.id);
        break;
      }
      case "Alphanumerisch":
      {
        setCritTyp(e.target.id);
        break;
      }
      case "critTextAlert":
      {
        //alert(e.target.id + " ===> " + e.target.checked);
        setCritTextAlert(e.target.value);
        break;
      }
    }
  }
  
  const addKpiUser = (id, kid) => {
    //alert("adding user: " +  id + " to KPI "+kid);
    var bodyFormData = new FormData();
    bodyFormData.append('user_id', id);
    axios({
      method: "post",
      //url: "https://tgiot.herokuapp.com/api/v1/kpis/newkpiuser/"+kid,
      url: "https://ioteasybackend.herokuapp.com/api/v1/kpis/newkpiuser/"+kid,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        readKpiUsers(kpi.id);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  setNewKpiUser(false);
}

  const delKpiUser = (id, kid) => {
    //alert("deleting user: " + id);
    var bodyFormData = new FormData();
    bodyFormData.append('user_id', id);
    axios({
      method: "post",
      //url: "https://tgiot.herokuapp.com/api/v1/kpis/delkpiuser/"+kid,
      url: "https://ioteasybackend.herokuapp.com/api/v1/kpis/delkpiuser/"+kid,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        readKpiUsers(kpi.id);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const delCrit = (id) => {
    //alert("Löschen..critID:"+id);
    //const killString = 'https://tgiot.herokuapp.com/api/v1/crits/' + id;
    const killString = 'https://ioteasybackend.herokuapp.com/api/v1/crits/' + id;
    console.log(killString);
    axios.delete(killString)
      .then(res => {
        console.log(res.data);
        readCrits(kpi.id);
      })
      .catch((error) => {
        // Error
        console.log("error deleting data...");
        if (error.res) {
          console.log(error.res);
        }
      });
  };
  
  const handleNewCrit = () => {
    setNewCrit(true);
    setUpdCrit(true);
    setCritName("newField");
    setCritTyp("Numerisch");
    setCritUwert(0);
    setCritOwert(100);
    setCritSwert1(20);
    setCritSwert2(80);
    setCritAlertHigh(true);
    setCritAlert(false);
    setCritAlertDash(false);
    setCritAlertEmail(false);
    setCritAlertWhatsapp(false);
    setCritAlertSms(false);
    setCritTextAlert("");
  }
  
  const critUpdate = (id) => {
    setUpdCrit(true);
    //alert(id);
    readCrit(id);
  }

  const cancelUpdate = (id) => {
    setUpdCrit(false);
  }

  const searcherFunc = (e) => {
    //alert(e.target.value);
    setSearcher(e.target.value);
  }
  
  const addNewKpiUser = () => {
    setNewKpiUser(true);
  }
  
  const closeme = () => {
    //setStaticModal(false);
    //props.cleaner();
    props.close();
  }

  return (
    <>
      <MDBModal className="bg-glasst" staticBackdrop show={props.showme} >
        <div className="myheader">
        Dashboard Komponente
        {kpi ? 
            <>{" " + kpi.name}</>
          : <>{" "}neu</>
        }
        </div>
        <MDBIcon className='bclose' onClick={() => props.close()} fas icon="times" size="2x" />
        
        {true ?
        <div className="tabcon">
        
              <MDBTabs className='mb-3'  >
                <MDBTabsItem >
                  <MDBTabsLink className="tl" onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
                    Dashboard
                  </MDBTabsLink>
                </MDBTabsItem>
                
                {kpi !== null ?
                <>
                <MDBTabsItem >
                  <MDBTabsLink className="tl" onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
                    Daten und Alarmierung
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem >
                  <MDBTabsLink className="tl" onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
                    Freigaben
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem >
                  <MDBTabsLink className="tl" onClick={() => handleFillClick('tab4')} active={fillActive === 'tab4'}>
                    API
                  </MDBTabsLink>
                </MDBTabsItem>
                </>
                :null}
              </MDBTabs>

              <MDBTabsContent>
                <MDBTabsPane show={fillActive === 'tab1'}>

                  <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                  <h1>Dashboard Komponente</h1>

                    <MDBRow >
                      <MDBCol sm="6" md="6" lg="6" xl="6" xxl="6" >
                          {kpiIotype === "Schalter" ? 
                              <MyOnOffButton kpi={JSON.stringify({name:kpiName, data2:[1]})} user={props.user} but={false} />
                            :null
                          }
                          {kpiIotype === "Regler" ? 
                              <MyCircleSlider kpi={JSON.stringify({name:kpiName, data2:[100], bound:[{uwert:50, owert:150}] })} user={props.user} but={false} />
                            :null
                          }
                          {kpiIotype === "Regler RGB" ? 
                              <MyColorPicker kpi={JSON.stringify({name:kpiName, data2:["#ff00ff"]})} user={props.user}   but={false} />
                            :null
                          }
                          {kpiIotype === "Ampel" ? 
                              <MyTrafficLight kpi={JSON.stringify({name:kpiName, data2:["red"]})} user={props.user}  but={false}  />
                            :null
                          }
                          {kpiIotype === "Tachometer" ? 
                              <MyGaugeChart kpi={JSON.stringify({name:kpiName, data2:[500]})} user={props.user}   but={false} />
                            :null
                          }
                          {kpiIotype === "Text" ? 
                              <MyTexter kpi={JSON.stringify({name:kpiName, data2:["ich bin ein Text..."]})} user={props.user}   but={false} />
                            :null
                          }
                          {kpiIotype === "QRCode" ? 
                              <MyQRCoder kpi={JSON.stringify({name:kpiName, data2:[kpiName]})} user={props.user}  but={false}  />
                            :null
                          }
                          {kpiIotype === "Laufschrift" ? 
                              <MyTexter kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, data2:[kpiName]})} user={props.user}   but={false} />
                            :null
                          }
                          {kpiIotype === "Graphik" ? 
                              <MyCharter kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, label:["1","2","3","4","5","6","7","8","9","10"], data1:[[0,100,24,32,44,5,33,4,54,6]], label1:["1st Dataset"]})} user={props.user}  but={false}  />
                            :null
                          }
                          {kpiIotype === "Graphik n-Dim" ? 
                              <MyCharter kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, label:["1","2","3","4","5","6","7","8","9","10"], data1:[[0,100,24,32,44,5,33,4,54,6],[100,24,32,44,5,33,4,54,6,99]], label1:["1st Dataset", "2nd Dataset"]})} user={props.user} but={false}   />
                            :null
                          }
                          {kpiIotype === "Karte" ? 
                              <MyMap kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, data1:[[8.5400],[47.3786 ]], data2:[8.5400, 47.3786] })} user={props.user}  but={false}  />
                            :null
                          }
                          {kpiIotype === "Bild" ? 
                              <MyImager kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, picture2:["https://mdbootstrap.com/img/new/standard/city/044.webp"] })} user={props.user}  but={false}  />
                            :null
                          }
                          {kpiIotype === "Video" ? 
                              <MyImager kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, data2:["https://www.youtube.com/embed/vlDzYIIOYmM"] })} user={props.user}  but={false} />
                            :null
                          }
                          {kpiIotype === "URL check" ? 
                              <MyURLChecker kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, data2:[1] })} user={props.user} but={false}   />
                            :null
                          }
                     </MDBCol>
      
                      <MDBCol sm="12" md="6" lg="6" xl="6" xxl="6">
      
                        <MDBSelect
                          className="m-1 p-1"                  
                          label="Dashboard Komponente"
                          data={dashOptions}
                          value={kpiIotype}
                          getValue={(e) => handleIoType(e)}
                        />
                        <h4>Bezeichnung</h4>
                        <MDBInput
                          input={kpiName}
                          className="m-2 p-2"                  
                          label="Bezeichnung" 
                          value={kpiName}
                          type="text" 
                          onChange={(e) => handleKpiName(e)}
                        />
                        <h4>Dashboard</h4>
                        <MDBInput
                          input={kpiGruppe}
                          className="m-2 p-2"                  
                          label="Dashboard" 
                          value={kpiGruppe}
                          type="text" 
                          onChange={(e) => handleKpiGruppe(e)}
                        />

                        {kpiIotype == "Graphik" ?
                          <MDBInput
                            className="m-2 p-2"                  
                            type='text'
                            label="Legende" 
                            value={kpiLabel}
                            onChange={(e) => handleKpiLabel(e)}
                          /> 
                          : null }
      
                        {kpiIotype == "Graphik n-Dim" ? //n-dim
                          <>
                          <MDBInput
                            className="m-2 p-2"                  
                            type='text'
                            label="Datenreihen durch Kommata trennen" 
                            value={kpiLabel}
                            onChange={(e) => handleKpiLabel(e)}
                          /> 
                          </>
                          : null}
      
                        {1<0 ?
                        <>
                        <h4>Smartwatch</h4>
                        <MDBCheckbox  onChange={(e) => handleSmart(e)} name='flexCheck' checked={kpiSmartwatch} id='flexCheckDefault' label='auf Fitbit App anzeigen' />
                        </>
                        :null
                        }
                        
                        <h4>Datensätze</h4>
                        <MDBInput
                          className="m-1 p-1"                  
                          type='number'
                          label="Anzahl gespeicherte Datensätze" 
                          value={kpiKeepVal}
                          onChange={(e) => handleAnz(e)}
                        /> 
      
                        <h4>Access-Key {kpiIotNumber} <MDBBtn onClick={(e) => newKey(e)} className="btn btn-danger">Neuer Key</MDBBtn></h4>

                        <div style={{textAlign:"right"}}>
                          <MDBBtn onClick={(e) => handleKpi(e)} type="Submit" className="m-3 btn btn-info">Speichern</MDBBtn>
                          <MDBBtn onClick={() => props.close()} type="Submit" className="m-3 btn-dark">Abbrechen</MDBBtn>
                        </div>
                     </MDBCol>
                     
                    </MDBRow>
                  </div>

                </MDBTabsPane>

                {kpi !== null ?
                <MDBTabsPane show={fillActive === 'tab2' && kpi }>

                      <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                          <h1>Cloud Datenstruktur <MDBBtn className="btn btn-danger" onClick={() => handleNewCrit()} >Neues Datenelement</MDBBtn></h1>
                          <MDBTable>
                          <MDBTableHead>
                            <tr style={{color:"lightgrey"}}>
                              <th scope='col'>Datenelement</th>
                              <th scope='col'>Typ</th>
                              <th scope='col'>Grenzwerte</th>
                              <th scope='col'>Schwellwerte</th>
                              <th colSpan="3" scope='col'>Alarmierung</th>
                              <th scope='col'>Aktionen</th>
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                          {kpiCrits.map((c,i) => 
                                <tr key={i} style={{color:"lightgrey"}} >
                                  <td>
                                    <b>{c.name}</b>
                                  </td>
                                  <td>
                                    {c.typ}
                                  </td>
                                  <td>
                                    {c.typ == "Numerisch" ? c.uwert + " - " + c.owert : null}
                                    {c.typ == "Alphanumerisch" ? critTextAlert : null}
                                  </td>
                                  <td>
                                    {c.typ == "Numerisch" ? c.swert1 + " / " + c.swert2 : null } 
                                  </td>
                                  <td>
                                    {c.alert ? <MDBIcon fas icon='bell' className="p-2 turq" /> : null }
                                  </td>
                                  <td>
                                    {c.typ=="Numerisch" ? c.alerthigh ? <MDBIcon fas icon='arrow-up' className="p-2 turq" /> : <MDBIcon fas icon='arrow-down' className="p-2 turq" /> : null}
                                  </td>
                                  <td >
                                    {c.alertdash ? <MDBIcon fas icon='signal' className="p-2 turq"/> : null } 
                                    {c.alertemail ? <MDBIcon fas icon='envelope' className="p-2 turq" /> : null } 
                                    {c.alertwhatsapp ? <MDBIcon fab icon='whatsapp' className="p-2 turq" /> : null } 
                                    {c.alertsms ? <MDBIcon fas icon='mobile' className="p-2 turq" /> : null } 
                                  </td>
                                  <td>
                                    <MDBIcon className="m-1 p-2 lightgrey" onClick={() => critUpdate(c.id)} fas icon="wrench"/>
                                    <MDBIcon className="m-1 p-2 red" onClick={() => delCrit(c.id)} fas icon="trash"/>
                                  </td>
                                </tr>
                                )}
                            </MDBTableBody>
                          </MDBTable>
                          </div>

                          {updCrit ? 
                            <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                                  <MDBRow>
                                    <MDBCol>
                                      <MDBInput
                                        id="critName"
                                        className="m-2 p-2"
                                        name={critName}
                                        type='text'
                                        label="Name" 
                                        value={critName}
                                        onChange={(e) => updateCrit(e)}
                                      /> 

                                    <MDBRadio onChange={(e) => updateCrit(e)}  name="critTyp" id={"Numerisch"} label="Numerisch" checked={critTyp=="Numerisch" ? true : false} />
                                    <MDBRadio onChange={(e) => updateCrit(e)}  name="critTyp2" id={"Alphanumerisch"} label="Alphanumerisch" checked={critTyp=="Alphanumerisch" ? true : false} />

                                      {critTyp == "Numerisch" ?
                                      <div>
                                      <h3>Grenzwerte</h3>
                                      <MDBRow>
                                        <MDBCol>
                                          <MDBInput
                                            id="critUwert"
                                            className="m-2 p-2"
                                            type='number'
                                            label="unterer Grenzwert"
                                            value={critUwert}
                                            onChange={(e) => updateCrit(e)}
                                          /> 
                                      </MDBCol>
                                      <MDBCol>
                                        <MDBInput
                                          id="critOwert"
                                          className="m-2 p-2"
                                          type='number'
                                          label="oberer Grenzwert" 
                                          value={critOwert}
                                          onChange={(e) => updateCrit(e)}
                                        /> 
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <h3>Schwellwerte</h3>
                                      <MDBCol>
                                        <MDBInput
                                          id="critSwert1"
                                          className="m-2 p-2"
                                          type='number'
                                          label="Schwellwert 1" 
                                          value={critSwert1}
                                          onChange={(e) => updateCrit(e)}
                                        /> 
                                      </MDBCol>
                                      <MDBCol>
                                        <MDBInput
                                          id="critSwert2"
                                          className="m-2 p-2"
                                          type='number'
                                          label="Schwellert 2" 
                                          value={critSwert2}
                                          onChange={(e) => updateCrit(e)}
                                        /> 
                                      </MDBCol>
                                    </MDBRow>
                                    </div>
                                    : null 
                                  }
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBRow>
                                      <MDBCol>
                                        <h3>Alarmierung</h3>
                                        
                                        <MDBCheckbox 
                                          id="critAlert"
                                          onChange={(e) => updateCrit(e)} 
                                          checked={critAlert} 
                                          label='Alarmierung' 
                                        />
                                        
                                        {critTyp == "Numerisch" ?
                                        <MDBCheckbox 
                                          id="critAlertHigh"
                                          onChange={(e) => updateCrit(e)} 
                                          checked= {critAlertHigh}
                                          label='Hoher Wert kritisch' 
                                        />
                                        : null}

                                        {critTyp == "Alphanumerisch" ?
                                        <MDBInput
                                          id="critTextAlert"
                                          className="m-2 p-2"
                                          type='text'
                                          label="Alarm" 
                                          value={critTextAlert}
                                          onChange={(e) => updateCrit(e)}
                                        /> 
                                        : null}
                                      </MDBCol>

                                      <MDBCol>
                                        <h3>Alarmierungsoptionen</h3>
                                        <MDBCheckbox 
                                          id="critAlertDash"
                                          onChange={(e) => updateCrit(e)} 
                                          checked={critAlertDash} 
                                          label='Dashboard' 
                                        />
                                        <MDBCheckbox 
                                          id="critAlertEmail"
                                          onChange={(e) => updateCrit(e)} 
                                          checked={critAlertEmail} 
                                          label='eMail' 
                                        />
                                        <MDBCheckbox 
                                          id="critAlertWhatsapp"
                                          onChange={(e) => updateCrit(e)} 
                                          //name={critAlertSms}
                                          checked={critAlertWhatsapp} 
                                          label='WhatsApp' 
                                        />
                                        <MDBCheckbox 
                                          id="critAlertSms"
                                          onChange={(e) => updateCrit(e)} 
                                          //name={critAlertSms}
                                          checked={critAlertSms} 
                                          label='SMS' 
                                        />
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBCol>

                              </MDBRow>
                              <MDBBtn onClick={(e) => handleCrit(e)} type="Submit" className="m-3 btn btn-info">Speichern</MDBBtn>
                              <MDBBtn onClick={() => cancelUpdate()} type="Submit" className="m-3 btn btn-dark">Abbrechen</MDBBtn>
                        </div>
                      : null}

                      {isLoading ? null :
                      <MyDialogData kpi={kpiID} kpikey={kpiKey} iotype={kpiIotype} />
                      }
                
                </MDBTabsPane>
                :null}
                
                {kpi !== null ?
                <MDBTabsPane show={fillActive === 'tab3' && kpi }>

                  {newKpiUser ? 
                  <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                  <h1>Benutzer {"["+users.length + "]"} suchen...<MDBBtn className="btn btn-dark" onClick={() => setNewKpiUser(false)} >Abbrechen</MDBBtn></h1>
                  <MDBInput
                    id="search"
                    className="m-2 p-2"
                    //name={critSwert2}
                    type='text'
                    label="Benutzer suchen" 
                    value={searcher}
                    onChange={(e) => searcherFunc(e)}
                  /> 
                  <MDBTable>
                  <MDBTableHead>
                    <tr style={{color:"lightgrey"}}>
                      <th colSpan="2" scope='col'>Name</th>
                      <th scope='col'>eMail</th>
                      <th scope='col'>seit</th>
                      <th></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {users.filter((u) => u.name.includes(searcher) ).map((u,i) => 
                      <tr style={{color:"lightgrey"}} key={i}>
                        { u.url !== "" && u.url !== null && u.url !== "/images/medium/missing.png" ? 
                        <img 
                            src={u.url}  
                            alt="responsive-image"
                            className="round"
                        /> :
                        <img
                            src="missing.png"
                            alt="responsive-image"
                            className="round"
                        />}
                        <td>
                          {u.name}
                        </td>
                        <td>
                          {u.email}
                        </td>
                        <td>
                          <MDBBtn className="m-3 btn-danger" onClick={() => addKpiUser(u.id, kpi.id)}>Neue Freigabe</MDBBtn>
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                  </MDBTable>
                  </div>
                  : null}
                
                  <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                    <h1>Freigaben <MDBBtn className="btn btn-danger" onClick={() => addNewKpiUser()} >Neue Freigabe</MDBBtn></h1>
                    <MDBTable>
                    <MDBTableHead>
                      <tr style={{color:"lightgrey"}}>
                        <th colSpan="2" scope='col'>Name</th>
                        <th scope='col'>eMail</th>
                        <th scope='col'>seit</th>
                        <th></th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                    {kpiUsers.map((u,i) => 
                          <tr key={i} style={{color:"lightgrey"}}>
                            <td>
                            { u.url !== "" && u.url !== null && u.url !== "/images/small/missing.png" ? 
                            <img 
                                src={u.url}  
                                alt="responsive-image"
                                className="round"
                            /> :
                            <img
                                src="missing.png"
                                alt="responsive-image"
                                className="round"
                            />}
                            </td>
                            <td>
                              {u.name}
                            </td>
                            <td>
                              {u.email}
                            </td>
                            <td>
                              {u.created_at}
                            </td>
                            <td>
                              <MDBIcon className="m-3 red" onClick={() => delKpiUser(u.id, kpi.id)} fas icon='trash' />
                            </td>
                          </tr>
                          )}
                      </MDBTableBody>
                    </MDBTable>
                  </div>

                </MDBTabsPane>
                :null}

                {kpi !== null ?
                <MDBTabsPane show={fillActive === 'tab4' && kpi}>
                  <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                    <h1>API Spezifikationen</h1>
                    <MDBRow className="m-3 p-3">
                      <MDBCol >
                        <h3 className="cyan">Authentifikation POST</h3>
                      </MDBCol>
                      <MDBCol>
                        <>
                          https://ioteasybackend.herokuapp.com/api/v1/user/signin?email=[ALPHANUMERISCH]&password=[ALPHANUMERISCH]
                          <br /><br />
                          {"====> SESSION_KEY"}
                        </>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-3 p-3">
                      <MDBCol>
                        <h3 className="cyan">Daten lesen (alle) GET</h3>
                      </MDBCol>
                      <MDBCol>
                        <>
                          https://ioteasybackend.herokuapp.com/api/v1/kpis/getkpidata/{kpi.id}?session=[SESSION KEY]&key={kpiIotNumber}
                          <br /><br />
                          {"====> DATA"}
                        </>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-3 p-3">
                      <MDBCol>
                        <h3 className="cyan">Daten lesen (letzter Eintrag) GET</h3>
                      </MDBCol>
                      <MDBCol>
                        <>
                          https://ioteasybackend.herokuapp.com/api/v1/kpis/getlastkpidata/{kpi.id}?session=[SESSION KEY]&key={kpiIotNumber}
                          <br /><br />
                          {"====> DATA"}
                        </>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-3 p-3">
                      <MDBCol>
                        <h3 className="cyan" >Daten schreiben POST</h3>
                      </MDBCol>
                      <MDBCol>
                        <>
                          https://ioteasybackend.herokuapp.com/api/v1/kpis/addkpidata/{kpi.id}?session=[SESSION KEY]&event=[ALPHANUMERISCHER WERT]&key={kpiIotNumber}&
                          {kpiCrits.map((c,i) => 
                            <div key={i}>
                              {c.name + "=" }
                              {(kpi.iotype === "Text" || kpi.iotype === "Laufschrift" || kpi.iotype === "Ampel") ? <>[ALPHANUMERISCH WERT]</> : <>[NUMERISCHER WERT]</>}
                            </div>
                          )}
                          <br /><br />
                          {"====> STATUS"}
                        </>
                      </MDBCol>
                    </MDBRow>    
                    <MDBRow className="m-3 p-3">
                      <MDBCol>
                        <h3 className="cyan" >Daten schreiben GET</h3>
                      </MDBCol>
                      <MDBCol>
                        <>
                          https://ioteasybackend.herokuapp.com/api/v1/kpis/addkpidata/{kpi.id}?session=[SESSION KEY]&event=[ALPHANUMERISCHER WERT]&key={kpiIotNumber}&
                          {kpiCrits.map((c,i) => 
                            <div key={i}>
                              {c.name + "=" }
                              {(kpi.iotype === "Text" || kpi.iotype === "Laufschrift" || kpi.iotype === "Ampel") ? <>[ALPHANUMERISCH WERT]</> : <>[NUMERISCHER WERT]</>}
                            </div>
                          )}
                          <br /><br />
                          {"====> STATUS"}
                        </>
                      </MDBCol>
                    </MDBRow>    
                      *) SESSION KEY currently not necessary; only KPI KEY
                  </div>
                </MDBTabsPane>
                :null}

              </MDBTabsContent>
        </div>
        :null}
                
      </MDBModal>
      
    </>
  );
}