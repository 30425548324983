import { 
  MDBContainer
  
} from 'mdb-react-ui-kit';

export default function ErrorHints(props) {
    return(

                    <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                    <h1>Fehlerhandling</h1>
                    <ul className="errorlist">
                        
                        <li className="erroritem cyan">Compile Errors - der Programmcode kann nicht in Maschinencode übersetzt werden
                            <ul className="errorlist">
                                <li className="erroritem">
                                    <b>Board - richtiger Microprozessor ausgewählt ?</b><br />
                                    Tools - Board - Board auswählen
                                </li>
                                <li className="erroritem">
                                    <b>Bibliotheken - alle Bibliothekens bzw. die richtige Version installiert ?</b><br />
                                    Sketch - Include Library - Manage Libraries (wenn bereits installiert ggf. Update notwendig)<br />
                                    Sketch - Include Library - Add ZIPP Library (wenn bereits heruntergeladen)<br />
                                    Internetsuche nach Bibliotheken Name - Bibliothek suchen und herunterladen<br />
                                </li>
                            </ul>
                        </li>
                        
                        <li className="erroritem cyan">Upload Errors - der Maschinencode wurde übersetzt aber kann nicht auf den Microprozessor geladen werden
                            <ul className="errorlist">
                                <li className="erroritem">
                                    <b>Port - richtigen Port ausgewählt ?</b><br />
                                    Tools - Board - Board auswählen 
                                </li>
                                <li className="erroritem">
                                    <b>Upload startet nicht (MC abhängig) ?</b><br />
                                    während des Uploads kurz die BOOT oder RST Taste gedrückt halten<br />
                                    vor dem Upload die Kabelverbindungen entfernen<br />
                                </li>
                            </ul>
                        </li>
                        {!props.nocon ?
                        <li className="erroritem cyan">RunTime Errors - die Kommunikation zwischen dem Microprozessor und der Cloud Plattform funktioniert nicht 
                            <ul className="errorlist">
                                <li className="erroritem">
                                    <b>WiFi Zugang - stimmen die Zugangsdaten ?</b><br />
                                    SSID und PASSWORT überprüfen<br /> 
                                </li>
                                <li className="erroritem">
                                    <b>Keine Datenübertragung nach IOTeasy ?</b><br />
                                    Tools - Serial Monitor - nach erfolgreichem Upload den Seriellen Monitor starten und Return Codes analysieren<br />
                                </li>
                                <li className="erroritem">
                                    <b>Falsche Daten - es werden keine oder falsche Daten übertragen ?</b><br />
                                    Verkabelung nochmals überprüfen<br />
                                </li>
                                <li className="erroritem">
                                    <b>Fehlermeldungen vom IOTeasy Portal ? </b><br />
                                    [500] - Server nicht gefunden - URL überprüfen <br />
                                    [404] - Service nicht gefunden - URL überprüfen <br />
                                    [ERROR] - diverse - URL Parameter falsch (KPI_ID, GRUPPE, API-KEY, Anzahl Parameter, etc.<br />
                                </li>
                            </ul>
                        </li>
                        :null}
                    </ul>
                    </div>


        )
}