import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MDBContainer, MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

//import MyYesNoBtn from './myYesNoBtn';
//import MyDummy from './myDummyM';

import axios from 'axios';

export default function MyAlerts (props) {

  const [alerts, setAlerts] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  //life-cycle hooks
  useEffect(() => {
    readAlerts();
    const timer = setInterval(() => {
       readAlerts();
    }, 30000);
    
    return () => {
      clearInterval(timer);
    }    
    
  },[]);
  
  const readAlerts = () => {
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/alerts?all=all&key='+props.user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        setAlerts(res.data.alerts);
        console.log(alerts);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  
  
  const deleteAlert = (name, id) => {
    if(window.confirm("Soll Alarm " + name + " wirklich gelöscht werden?")) {
      const killString = 'https://ioteasybackend.herokuapp.com/api/v1/alerts/' + id;
      console.log(killString);
      axios.delete(killString)
        .then(res => {
          console.log(res.data);
          readAlerts();
        })
        .catch((error) => {
          // Error
          console.log("error deleting data...");
          if (error.res) {
            console.log(error.res);
          }
        })
    }
  }

  const deleteAllAlerts = () => {
    deleteAlert("alle Einträge",0);
  }

  return(
    
    <MDBContainer fluid className="cool" style={{width:"100%", marginTop:"75px"}}>
      {isloading ? 
        <div className="loader">
          <MDBIcon className="fa-10x" spin fas icon='spinner' />
          <br /><br />
          <h1>loading Alerts...</h1>
        </div> 
      :
        <React.Fragment>
          <div className="myheader">Alarmübersicht {" "}
            <MDBBtn onClick={() => deleteAllAlerts()} className="btn btn-info"> <MDBIcon icon='trash' /> Log löschen</MDBBtn>
            {!props.user.abo ? 
              <div className="yellow">
                Achtung SMS werden nicht zugestellt - Du musst hierfür ein ABO lösen !
              </div>
            : null}
          </div>

          <MDBTable className="white" style={{color:"#A0A0A0", textAlign:"left"}}>
          <MDBTableHead>
            <tr>
              <th scope='col'>Status</th>
              <th scope='col'>Alarm</th>
              <th scope='col'>De-Alarm</th>
              <th colSpan="2" scope='col'>Daten</th>
              <th scope='col'>Wert</th>
              <th colSpan="2" scope='col'>Grenzwerte</th>
              <th colSpan="4" scope='col'>Alarmierung</th>
              <th scope='col'>Alarm löschen</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {alerts.map((a,i) => 
                <tr key={i}>
                  <td>
                    {a.typ == "Alarm" ?
                      <MDBIcon className='red' icon='exclamation-triangle' size='5x' />
                    :
                      <MDBIcon className='green' icon='check-square' size='5x' />
                    }
                  </td>
                  <td>
                    {a.zeit}
                  </td>
                  <td>
                    {a.dzeit}
                  </td>
                  <td>
                    {a.kpi}
                  </td>
                  <td>
                    {a.crit}
                  </td>
                  <td>
                    {a.value}
                  </td>
                  <td>
                    {a.swert1}
                  </td>
                  <td>
                    {a.swert2}
                  </td>
                  <td>
                    {a.alertdash ? 
                      <MDBIcon className='yellow' icon='signal' size='3x' />
                    : null}
                  </td>
                  <td>
                    {a.alertemail ? 
                      <MDBIcon className='yellow' icon='envelope' size='3x' />
                    : null}
                  </td>
                  <td>
                    {a.alertwhatsapp ? 
                      <MDBIcon fab icon='whatsapp' className ='yellow' size='3x'/>
                    : null}
                  </td>
                  <td>
                    {a.alertsms ? 
                      <MDBIcon className='yellow' icon='mobile' size='3x' />
                    : null}
                  </td>
                  <td>
                    <MDBIcon onClick={() => deleteAlert(a.kpi, a.id)} style={{cursor:"pointer"}} className='red' icon='trash' size='1x' />
                  </td>
                </tr>
                )}
            </MDBTableBody>
          </MDBTable>
        </React.Fragment>
      }
    
    </MDBContainer>

  )

}
