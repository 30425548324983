import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { 
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon,   MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselElement, MDBCarouselCaption, MDBContainer, MDBScrollbar, MDBInput,  MDBTable, 
  MDBTableHead, 
  MDBTableBody

} from 'mdb-react-ui-kit';

export default function MyAdminDialog(props) {

  //const [staticModal, setStaticModal] = useState(true);
  const [searcher, setSearcher] = useState("");
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let readString = ""
    if(props.typ==="User") {
      readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/registrations?key='+props.user.userkey;
    }
    if(props.typ==="Blocked TRX") {
      readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/throttles?key='+props.user.userkey;
    }
    if(props.typ==="Blocked IPs") {
      readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/ipblocks?key='+props.user.userkey;
    }
    if(props.typ==="Data") {
      readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/datavols?key='+props.user.userkey;
    }
    if(props.typ==="Blocked SMS") {
      readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/sms?key='+props.user.userkey;
    }
    console.log(readString);
    axios.get(readString)
      .then(res => {
        if(props.typ==="User"){
          setResult(res.data.users);
        }
        if(props.typ==="Blocked TRX"){
          setResult(res.data.rejects);
        }
        if(props.typ==="Blocked IPs"){
          setResult(res.data.blocks);
        }
        if(props.typ==="Data"){
          setResult(res.data.data);
        }
        if(props.typ==="Blocked SMS"){
          setResult(res.data.sms);
        }
        console.log(result);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  },[props.typ]); 

  const searcherFunc = (e) => {
    //alert(e.target.value);
    setSearcher(e.target.value);
  }

  return (
    <>
      <MDBModal staticBackdrop  className="bg-glasst" role="dialog" show={props.showme} >

        <div className="myheader">{props.typ}</div>
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />
        
          {props.typ==="User" && !isLoading ?
            <MDBContainer>
                    <MDBRow>
                      <MDBCol>
                        <form>
                        <MDBInput
                          autoFocus
                          id="search"
                          className="m-2 p-2"
                          type='text'
                          label="Benutzer suchen" 
                          value={searcher}
                          onChange={(e) => searcherFunc(e)}
                        /> 
                        </form>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBTable>
                      <MDBTableHead>
                        <tr className="cyan left">
                          <th colSpan="2" scope='col'>Name</th>
                          <th scope='col'>eMail</th>
                          <th scope='col'>registriert</th>
                          <th scope='col'>letzter Login</th>
                          <th scope='col'>Widgets</th>
                          <th scope='col'>Dashboards</th>
                          <th scope='col'>Devices</th>
                          <th scope='col'>Abos</th>
                          <th></th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {result.filter((u) => u.name.includes(searcher) ).map((u,i) => 
                          <tr key={i} className="yellow left">
                            { u.url !== "" && u.url !== null && u.url !== "/images/medium/missing.png" ? 
                            <img 
                                src={u.url}  
                                alt="responsive-image"
                                className="round"
                            /> :
                            <img
                                src="missing.png"
                                alt="responsive-image"
                                className="round"
                            />}
                            <td>
                              {u.name}
                            </td>
                            <td>
                              {u.email}
                            </td>
                            <td>
                              {u.reg}
                            </td>
                            <td>
                              {u.log}
                            </td>
                            <td>
                              {u.kpianz}
                            </td>
                            <td>
                              {u.dashanz}
                            </td>
                            <td>
                              {u.devanz}
                            </td>
                            <td>
                              {u.abos}
                            </td>
                            <td>
                              <MDBIcon className="m-3 red" fas icon='plus' />
                            </td>
                          </tr>
                        )}
                      </MDBTableBody>
                      </MDBTable>
                    </MDBRow>
            </MDBContainer>
          
            :null
          }

          {props.typ==="Blocked TRX" && !isLoading ?
            <MDBContainer>
                    <MDBRow>
                      <MDBCol>
                        <form>
                        <MDBInput
                          autoFocus
                          id="search"
                          className="m-2 p-2"
                          type='text'
                          label="Widget suchen" 
                          value={searcher}
                          onChange={(e) => searcherFunc(e)}
                        /> 
                        </form>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBTable>
                      <MDBTableHead>
                        <tr className="cyan left">
                          <th scope='col'>Name</th>
                          <th scope='col'>User</th>
                          <th scope='col'>Anzahl</th>
                          <th></th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {result.filter((u) => u.kpi.includes(searcher) ).map((u,i) => 
                          <tr key={i} className="yellow left">
                            <td>
                              {u.kpi}
                            </td>
                            <td>
                              {u.user}
                            </td>
                            <td>
                              {u.anz}
                            </td>
                            <td>
                              <MDBIcon className="m-3 red" fas icon='plus' />
                            </td>
                          </tr>
                        )}
                      </MDBTableBody>
                      </MDBTable>
                    </MDBRow>
            </MDBContainer>
          
            :null
          }

          {props.typ==="Blocked IPs" && !isLoading ?
            <MDBContainer>
                    <MDBRow>
                      <MDBCol>
                        <form>
                        <MDBInput
                          autoFocus
                          id="search"
                          className="m-2 p-2"
                          type='text'
                          label="IP" 
                          value={searcher}
                          onChange={(e) => searcherFunc(e)}
                        /> 
                        </form>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBTable>
                      <MDBTableHead>
                        <tr className="cyan left">
                          <th scope='col'>IP</th>
                          <th scope='col'>Anzahl</th>
                          <th></th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {result.filter((u) => u.ip.includes(searcher) ).map((u,i) => 
                          <tr key={i} className="yellow left">
                            <td>
                              {u.ip}
                            </td>
                            <td>
                              {u.anz}
                            </td>
                            <td>
                              <MDBIcon className="m-3 red" fas icon='plus' />
                            </td>
                          </tr>
                        )}
                      </MDBTableBody>
                      </MDBTable>
                    </MDBRow>
            </MDBContainer>
            :null
          }

          {props.typ==="Data" && !isLoading ?
            <MDBContainer>
                    <MDBRow>
                      <MDBCol>
                        <form>
                        <MDBInput
                          autoFocus
                          id="search"
                          className="m-2 p-2"
                          type='text'
                          label="User suchen" 
                          value={searcher}
                          onChange={(e) => searcherFunc(e)}
                        /> 
                        </form>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBTable>
                      <MDBTableHead>
                        <tr className="cyan left">
                          <th scope='col'>User</th>
                          <th scope='col'>Data</th>
                          <th scope='col'>Item</th>
                          <th scope='col'>Anzahl</th>
                          <th></th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {result.filter((u) => u.user.includes(searcher) ).map((u,i) => 
                          <tr key={i} className="yellow left">
                            <td>
                              {u.user}
                            </td>
                            <td>
                              {u.kpi}
                            </td>
                            <td>
                              {u.name}
                            </td>
                            <td>
                              {u.anz}
                            </td>
                            <td>
                              <MDBIcon className="m-3 red" fas icon='plus' />
                            </td>
                          </tr>
                        )}
                      </MDBTableBody>
                      </MDBTable>
                    </MDBRow>
            </MDBContainer>
            :null
          }
          
          {props.typ==="Blocked SMS" && !isLoading ?
            <MDBContainer>
                    <MDBRow>
                      <MDBCol>
                        <form>
                        <MDBInput
                          autoFocus
                          id="search"
                          className="m-2 p-2"
                          type='text'
                          label="User suchen" 
                          value={searcher}
                          onChange={(e) => searcherFunc(e)}
                        /> 
                        </form>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBTable>
                      <MDBTableHead>
                        <tr className="cyan left">
                          <th scope='col'>User</th>
                          <th scope='col'>KPI</th>
                          <th scope='col'>Kriterium</th>
                          <th></th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {result.filter((u) => u.user.includes(searcher) ).map((u,i) => 
                          <tr key={i} className="yellow left">
                            <td>
                              {u.user}
                            </td>
                            <td>
                              {u.kpi}
                            </td>
                            <td>
                              {u.crit}
                            </td>
                            <td>
                              <MDBIcon className="m-3 red" fas icon='trash' />
                            </td>
                          </tr>
                        )}
                      </MDBTableBody>
                      </MDBTable>
                    </MDBRow>
            </MDBContainer>
            :null
          }
          
      </MDBModal>
      
    </>
  );
}