import React, { useState, useEffect } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBInputGroup,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBAccordion, 
  MDBAccordionItem,
  MDBRadio,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBValidation,
  MDBIcon,
  MDBScrollbar
} from 'mdb-react-ui-kit';

import axios from 'axios';

export default function MyRegistration(props){

  const [staticModal, setStaticModal] = useState(true);
  const toggleShow = () => setStaticModal(!staticModal);

  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState("");
  const [confirmpassword, setComfirmpassword] = useState("");

  const [info, setInfo] = useState("");

  const newPassword = () => {
    
      if(props.mode=="pwconfirm" && password != confirmpassword) {
        setInfo("Passwörter unterschiedlich !");
        return;
      }
    
      var bodyFormData = new FormData();

      bodyFormData.append('email', email);
      if(props.mode==="pwconfirm") {
        bodyFormData.append('password', password);
        bodyFormData.append('token', props.token);
      }

      axios({
        method: "post",
        url: "https://ioteasybackend.herokuapp.com/api/v1/users?mode="+props.mode,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response.data.message);
          if(response.data.status=="OK") {
            console.log(response.data.user);
            if(props.mode=="pwlink") {
              props.setKey("cancel", null);
            }
            if(props.mode=="pwconfirm") {
              props.setKey("login", response.data.user);
            }
            setStaticModal(false);
          } else {
            setInfo(response.data.message);
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response.message);
        });

  };
  
  const closeWin=() => {
    setStaticModal(false);
    props.setKey("cancel", null);
  }
  
  return (
      <MDBModal staticBackdrop tabIndex='-1' show={staticModal} getOpenState={(e) => setStaticModal(e)} >
        <MDBModalDialog size="md" className="frame">
          <MDBModalContent className="">
            <MDBModalHeader className="modalheader">
              <MDBModalTitle >Passwort ändern<div className="red">{info}</div></MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => closeWin()}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className=""> 
            
              <MDBRow>
                <MDBCol md="12">
                    {props.mode == "pwconfirm" ? 
                      <div className="m-3 p-3">
                        <MDBInput 
                          readonly
                          value={email}
                          className=""
                          type="text" 
                          label="email" 
                          onChange={(e) => setEmail(e.target.value)} 
                          />
                      </div>
                    :
                      <div className="m-3 p-3">
                        <MDBInput 
                          value={email}
                          className=""
                          type="text" 
                          label="email" 
                          onChange={(e) => setEmail(e.target.value)} 
                          />
                      </div>
                    }
    
                    <div className="m-3 p-3">
                      {props.mode === "pwconfirm" ?
                      <>
                      <MDBInput 
                        className=""
                        value={password}
                        type="password" 
                        label="Passwort" 
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <MDBInput 
                        className=""
                        value={confirmpassword}
                        type="password" 
                        label="Passwort bestätigen"
                        onChange={(e) => setComfirmpassword(e.target.value)}
                      />
                      </>
                      : null}
                    </div>
                    

                </MDBCol>
              </MDBRow>

            </MDBModalBody>
            <MDBModalFooter className="modalfooter">
              {props.mode === "pwlink" ?
                  <MDBBtn onClick={() => newPassword()} className="btn btn-info">Link senden</MDBBtn>
              :null}
              
              {props.mode=="pwconfirm" ?
                  <MDBBtn onClick={() => newPassword()} className="btn btn-warning">Speichern</MDBBtn>
              :null}

            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
     
  );
}