import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBCol, MDBRow, MDBIcon } from 'mdb-react-ui-kit';
import { CircleSlider } from "react-circle-slider";
import '@radial-color-picker/react-color-picker/dist/react-color-picker.min.css';
import axios from 'axios';
import ManageBtn from './myBtnMgr';

export default function MyCircleSlider(props) {

    const [kpi, setKpi] = useState(JSON.parse(props.kpi));
    const [value, setValue] = useState(null);

    useEffect(() => {
    },[])
    
    useEffect(() => {
      setKpi(JSON.parse(props.kpi));
    },[props.kpi])

    const handleChange = (value) => {
        console.log(`Changed value ${value}`);
        setValue(value);
    };

    const onSelect=() => {
      const event = Date.now();
      const addString = 'https://ioteasybackend.herokuapp.com/api/v1/kpis/addkpidata/' + kpi.id + '?event=' + event + '&key=' + kpi.iotnumber  + '&' + kpi.label1[0] + '=' + value;
      console.log(addString);
      axios.post(addString)
        .then(res => {
          console.log(res.data);
        })
        .catch((error) => {
          // Error
          console.log("error writing data...");
          if (error.res) {
            console.log(error.res);
          }
        })

  }
  
  const chartStyle = {
    width: 100,
    height:120
  }  

  return(
          <div style={{textAlign:'center'}}
           className="
                  bg-glass
                  shadow-4-strong
                  rounded-6
                  p-4
                  ripple
                  "
          >
            {/*<MDBBtn onClick={() => this.work()} class="btn btn-sm btn-secondary rounded" style={this.but}><i class="fas fa-check"></i></MDBBtn>*/}
            <h2>{kpi.name}</h2>
            <CircleSlider
                value={kpi.data2[0]}
                min={kpi.bound[0].uwert}
                max={kpi.bound[0].owert}
                size={150}
                showTooltip={true}
                gradientColorFrom="#FF00FF"
                gradientColorTo="#00FFFF"
                tooltipColor="#ff00ff"
                onChange={handleChange}
            />
            <i onClick={() => onSelect()} className="myCircleBtn fas fa-pencil-alt"></i>
            <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
         </div>
      );
}
