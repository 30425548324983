import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MDBContainer, MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon} from 'mdb-react-ui-kit';

import axios from 'axios';

import MyDialogDevice from './myDialogDevice';
import MyYesNoBtn from './myYesNoBtn';
import MyDummy from './myDummyM';

import devices from './param';

import MyOnOffButton from './myOnOffButton';


export default function MyDevices (props) {

  const [myDevices, setMyDevices] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [devicesImg, setDevicesImg] = useState([]);
  const [editDType, setEditDtype] = useState(false);
  const [deviceID, setDeviceID] = useState(null);
  const [showDevice, setShowDevice] = useState(false);

  //life-cycle hooks
  useEffect(() => {
    readDevices();
    const timer = setInterval(() => {
       readDevices();
    }, 30000);
    
    return () => {
      clearInterval(timer);
      //ReactDOM.render("", document.getElementById('modal'));
    }    
    
  },[]);
  
  const readDevices = () => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/devices?key='+props.user.userkey;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/devices?key='+props.user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        setMyDevices(res.data.devices);
        const k = res.data.devices;
        console.log(k);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  
  
  const manageDevice = (id) => {
    //alert(id);
    setDeviceID(id);
    setShowDevice(true);
  }
  
  const closeme = () => {
    setDeviceID(null);
    setShowDevice(false);
  }
  
  const r = (i) => {
    if(i < devicesImg.length) {
      return(this.state.devicesImg[i].img);
    }
  }
  
  const findDevPic = (spec) => {
    let pic = "not found"
    for(let i=0; i<devices.length; i++) {
      //console.log("PIC is " + devices[i].picture);
      if (spec === devices[i].text) {
        pic = devices[i].picture;
      }
    }
    return(pic);
  }
  
  const deleteDevice = (id, name) => {
    if(window.confirm("Soll " + name + " wirklich gelöscht werden?")) {
      //const killString = 'https://tgiot.herokuapp.com/api/v1/devices/' + id;
      const killString = 'https://ioteasybackend.herokuapp.com/api/v1/devices/' + id;
      console.log(killString);
      axios.delete(killString+"++++")
        .then(res => {
          console.log(res.data);
          readDevices();
        })
        .catch((error) => {
          // Error
          console.log("error deleting data...");
          if (error.res) {
            console.log(error.res);
          }
        })
    }
    //ReactDOM.render("", document.getElementById('modal'));
  }
  
  const genDevices = () => {
    return(
      myDevices.map((d, i) => 

        <MDBCol key={i} sm="12" md="12" lg="4" xl="3" xxl="3">
          <div  style={{textAlign:'center', height:450}}
             className="
                    bg-glass
                    shadow-4-strong
                    rounded-6
                    p-4
                    ripple
                    "
            >
            <img
              src={findDevPic(d.dtype)}
              width="120px"
              height="120px"        
              objectfit="cover"
            />
            <img
              src={d.iotype+".png"}
              width="120px"
              height="120px"        
              objectfit="cover"
            />
            <br />
            <br />
            <h3 className="myheaderi"> 
              {d.name}
            </h3>
            <div className="p-1">
              <div className="yellow mid">
              <i className="fas fa-microchip p-2 yellow"></i>{d.dtype}<br />
              </div>
              <i className="fas fa-database p-2" style={{color:'darkgrey'}}></i>{d.kpi}<br />
              <i className="fas fa-wifi p-2" style={{color:'darkgrey'}}></i>{d.connectivity}
              {d.connectivity === "WLAN" ? " " + d.ssid : null}
              {d.connectivity === "LORA" ? " " + d.loraband + " " + d.loramode : null}
            </div>

            {props.user.name !== "IOTeasy" ?
              <div className="kachelbtn">
                <div onClick={() => deleteDevice(d.id, d.name)} style={{position:"absolute", bottom:30, left:30}}><i className="fas fa-trash p-2"></i></div>
                <div onClick={() => manageDevice(d.id, d.name)} style={{position:"absolute", bottom:30, right:30}}><i className="fas fa-wrench p-2"></i></div>
              </div>
              :
              <div className="kachelbtn">
                <div onClick={() => alert("disabled in DEMO mode")} style={{position:"absolute", bottom:30, left:30}}><i className="fas fa-trash p-2"></i></div>
                <div onClick={() => alert("disabled in DEMO mode")} style={{position:"absolute", bottom:30, right:30}}><i className="fas fa-wrench p-2"></i></div>
              </div>
            }
          </div>
        
        </MDBCol>
        
      )
    )
  }

    return(
      <React.Fragment>
      <MDBContainer fluid className="cool" style={{width:"100%", marginTop:"75px"}}>
        <div className='myheader'>
            IOT Devices  {" "}
            <MDBBtn onClick={() => props.createDevice("mygenerator")} className="btn btn-lg btn-danger">{" "} Neues Device</MDBBtn>
        </div>
          {isloading ? 
            <div className="loader">
              <MDBIcon className="fa-10x" spin fas icon='spinner' />
              <br /><br />
              <h1>loading Devices...</h1>
            </div> 
            :
            <MDBRow>
              {genDevices()}
            </MDBRow>
          }
    </MDBContainer>
    {showDevice 
      ? <MyDialogDevice user={props.user} id={deviceID} closeme={closeme} showme={showDevice} />
      : null
    }
    </React.Fragment>
    )
}
