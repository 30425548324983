import { 
  MDBContainer
  
} from 'mdb-react-ui-kit';

export default function CodeWrite_gen(props) {

    let paramstring = "";
    let requeststring = "";
    if (props.device.connectivity !== "nocon") {
      for(let i=0; i<props.device.crits.length;i++) {
        //alert(props.device.crits[i].typ);
        if(i>0) {
          paramstring = paramstring + ", ";
        }
        if(props.device.crits[i].typ === "Alphanumerisch") {
          paramstring = paramstring + "String ";
        }
        if(props.device.crits[i].typ === "Numerisch") {
          paramstring = paramstring + "float ";
        }
        requeststring = requeststring + ' + "&' + props.device.crits[i].name + '="+' + props.device.crits[i].name ;
        paramstring = paramstring + props.device.crits[i].name;
      }
    }

    return(

<MDBContainer className="lightgrey">
{(props.devices[props.did].type === "Sensor" && props.device.connectivity === "WLAN") || (props.devices[props.did].type === "Sensor" && props.device.loramode === "gateway") || (props.devices[props.did].type === "Spezial") ?
<>
// ********************************************************************************************<br />
<h1>// Write IOTeasy Cloud </h1>
// ********************************************************************************************<br />
void write_{props.device.kpi_id}({paramstring}) {"{"}<br />
<div className="tab1">
DynamicJsonDocument doc(2000);<br />      
if (WiFi.status() == WL_CONNECTED) {"{"}<br />
</div>
<div className="tab2">
const char* serverName = "http://ioteasybackend.herokuapp.com/api/v1/kpis/addkpidata/{props.device.kpi_id}";<br />
WiFiClient client;<br />
HTTPClient http;<br />
http.begin(client, serverName);<br />
http.addHeader("Content-Type", "application/x-www-form-urlencoded");<br />
int event = random(65000);<br />
String httpRequestData = "key="+kpi_key+"&event=" + String(event) {requeststring};<br />
Serial.println(httpRequestData);<br />
int httpResponseCode = http.POST(httpRequestData);<br />
Serial.print("HTTP Response code: ");<br />
Serial.println(httpResponseCode);<br />
String response = http.getString();<br />
Serial.println(response);<br />
// Free resources<br />
http.end();<br />
</div>
<div className="tab1">
{"}"}<br/>
else {"{"} <br />
</div>
<div className="tab2">
Serial.println("WiFi Disconnected");<br />
</div>
<div className="tab1">
{"}"}<br/>
</div>
<div>
{"}"}<br/>
</div>
</>
:null}

</MDBContainer>

)}