import { 
  MDBContainer
  
} from 'mdb-react-ui-kit';

export default function CodeIntro_gen(props) {
    return(

<MDBContainer className="lightgrey">
<h1>// IOTeasy Generator for ESP32</h1>
// ********************************************************************************************<br />
// IMPORTANT!!!<br />
// libraries described/provided/linked/used in/to this Project are open source but copyright protected<br />
// it's completeley within the user's responsibility to ensure proper usage of the underlying licences.<br />
// IOTeasy distributes this code in the hope that it will be useful, but WITHOUT ANY WARRANTY<br />
// always check PINs of Board and Devices - sometimes proposed Pins don't work as they are already used <br />
// - e.g. Pin 5 für SPI Interface, then just try another one
//<br />
// ********************************************************************************************<br />
<br />
<h4>// Type of Device: {props.devices[props.did].type}</h4>
<h4>// Name of Device: {props.devices[props.did].text}</h4>

{props.device !== null ? 
    <>
    <h4>
        // Datenreferenz: {props.device.kpi}
    </h4>
    <h4>
        // Connectivity: {props.device.connectivity}
    </h4>
    {props.device.connectivity === "LORA" ?
        <>
            <h4>// LORA Typ {props.device.loramode}</h4> 
            <h4>// LORA Band {props.device.loraband} MHz</h4> 
        </>
    :null }
    {props.device.connectivity === "WLAN" || (props.device.connectivity === "LORA" && props.loratype === "gateway") ?
        <>
            {false ?
            <>
            <h4>// SSID: {props.device.ssid}</h4> 
            <h4>// Password: {props.device.password}</h4> 
            </>
            :null}
        </>
        :
        null
    }
    </>
:null}

<br />
</MDBContainer>

    )
}