import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MDBContainer, MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

//import MyYesNoBtn from './myYesNoBtn';
//import MyDummy from './myDummyM';

import axios from 'axios';

export default function MyIPScanner (props) {

  const [IP, setIP] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  const readIP = () => {
    const readString = 'https://geolocation-db.com/json/';
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data);
        setIP(res.data.IPv4)
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading ip...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  
  
  //life-cycle hooks
  useEffect(() => {
    readIP();
  },[]);
  
  return(

      <>
      {isloading ? 
          <h1>loading IP...</h1>
      :
        <React.Fragment>
          <p>IP is {IP}</p>
        </React.Fragment>
      }
    </>

  )

}
