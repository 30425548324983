import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Acrylic from 'react-acrylic';
import axios from 'axios';
import { MDBContainer, MDBChart, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBAlert } from 'mdb-react-ui-kit';

import MyOnOffButton from './myOnOffButton';
import MyGaugeChart from './myGaugeChart';
import MyColorPicker from './myColorPicker';
import MyCircleSlider from './myCircleSlider';
import MyCharter from './myCharter';
import MyMap from './myMap';
import MyTrafficLight from './myTrafficLight';
import MyImager from './myImager';
import MyTexter from './myTexter';
import MyURLChecker from './myURLChecker';
import MyQRCoder from './myQRCoder';
import MyDummy from './myDummyM';

import MyDialogKpi from './myDialogKpi';

export default function MyDash (props) {

  const [showButton, setShowButton] = useState(true);

  const [first, setFirst] = useState(true);
  const [isloading, setIsloading] = useState(true);
  const [count, setCount] = useState(0);

  const [kpis, setKpis] = useState([]);
  const [gruppe, setGruppe] = useState(props.gruppe);
  const [user, setUser] = useState(props.user);

  const [alerts, setAlerts] = useState([]);
  const [alarm, setAlarm] = useState(0);
  
  const [showDialog, setShowDialog] = useState(false);
  const [kpiID, setKpiID] = useState(null);

  //life-cycle hooks
  useEffect(() => {
    console.log('first time');
  },[]);

  //life-cycle hook update gruppe props
  useEffect(() => {
    //alert(props.user.name);
    if(props.user.name === "IOTeasy") {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
    setIsloading(true);
    console.log('use effect props.gruppe ' + props.gruppe);
    readKPIs(props.gruppe);
    const timer = setInterval(() => {
       readKPIs(props.gruppe);
    }, 20000);
    const timer2 = setInterval(() => {
       readALERTs(props.gruppe);
       updateRULEs();
    }, 60000);
    
    return () => {
      clearInterval(timer);
      clearInterval(timer2);
    };

  }, [props.gruppe]);
  
  const readKPIs = (gruppe) => {
    //setCount(count+1);
    //const readString = 'https://tgiot.herokuapp.com/api/v1/kpis?key='+props.user.userkey+'&gruppe='+gruppe;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/kpis?key='+props.user.userkey+'&gruppe='+gruppe;
    //alert(readString);
    axios.get(readString)
      .then(res => {
          setCount(prevCount => prevCount + 1);
          console.log("reading..."+gruppe);
          setKpis(res.data.kpis);
          console.log(res.data.kpis);
          //console.log(count);
          setIsloading(false);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
            console.log(error.res);
            setIsloading(true);
        }
      })
  }

  const readALERTs = (gruppe) => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/alerts?key='+props.user.userkey+'&gruppe='+gruppe;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/alerts?key='+props.user.userkey+'&gruppe='+gruppe;
    //alert(readString);
    axios.get(readString)
      .then(res => {
          console.log("reading alerts...");
          setAlerts(res.data.alerts);
          console.log(res.data.alerts);
          if(document.getElementById('alertBtn')) {
            const btn = document.getElementById('alertBtn');
            btn.click();
          }
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
            console.log(error.res);
            setIsloading(true);
        }
      })
  }

  const updateRULEs = () => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/rules?key='+props.user.userkey;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/rules?key='+props.user.userkey;
    //alert(readString);
    axios.get(readString)
      .then(res => {
          console.log("reading rules...");
          console.log(res.data);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
            console.log(error.res);
        }
      })
  }

  const iterateButtons = () => {
    return(

      kpis.filter(kpi => kpi.iotype === "Schalter").map((kpi,i) => (
          <MDBCol key={i} md="6" xl="3" lg="2" xs="12">
            <MyOnOffButton kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog} />
          </MDBCol>
        )
      )

    )
  }

  const iterateURLs = () => {
    return(
      kpis.filter(kpi => kpi.iotype === "URL check").map((kpi,i) => (
          <MDBCol key={i} md="6" xl="2" lg="3" xs="12">
            <MyURLChecker kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog}  />
          </MDBCol>
        )
      )
    )
  }

  const iterateRGBs = () => {
    return(
      kpis.filter(kpi => (kpi.iotype === "Regler RGB")).map((kpi,i) => (
        <MDBCol key={i} md="6" xl="3" lg="4" xs="12">
          <MyColorPicker kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog} />
        </MDBCol>
        )
      )
    )
  }
  
  const iterateCircleSliders = () => {
    return(
      kpis.filter(kpi => (kpi.iotype === "Regler")).map((kpi,i) => (
        <MDBCol key={i} md="6" xl="2" lg="3" xs="12">
            <MyCircleSlider kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog} />
          </MDBCol>
            )
          )
        )
  }

  const iterateTrafficLights = () => {
    return(
      kpis.filter(kpi => (kpi.iotype === "Ampel")).map((kpi,i) => (
        <MDBCol key={i} md="6" xl="2" lg="3" xs="12">
          <MyTrafficLight kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog} />
          </MDBCol>
        )
      )
    )
  }
  
  const iterateTexter = () => {
    return(
      kpis.filter(kpi => (kpi.iotype === "Text" || kpi.iotype === "Laufschrift")).map((kpi,i) => (
        <MDBCol key={i}  sm="12" md="12" lg="6" xl="4" xxl="4">
          <MyTexter kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog} />
        </MDBCol>
      )
      )
    )
  }

  const iterateQRs = () => {
    //alert("QR");
    return(
      kpis.filter(kpi => (kpi.iotype === "QRCode")).map((kpi,i) => (
        <MDBCol key={i} sm="12" md="12" lg="6" xl="4" xxl="4">
          <MyQRCoder kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog} />
        </MDBCol>
      )
      )
    )
  }

  const iterateGauges = () => {
    return(
      kpis.filter(kpi => (kpi.iotype === "Tachometer")).map((kpi,i) => (

        <MDBCol key={i} md="4" xl="4" lg="4" xs="12">
          <MyGaugeChart kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog} />
        </MDBCol>
          )
        )
    )
  }

  const iterateGraphs = () => {
    return(
      kpis.filter(kpi => (kpi.iotype === "Graphik" || kpi.iotype == "Graphik n-Dim" || kpi.ktype == "Line")).map((kpi,i) => (
        <MDBCol key={i} sm="12" md="12" lg="6" xl="4" xxl="4">
          <MyCharter kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog}  />
        </MDBCol>
        )
      )
    )
  }

  const iterateMaps = () => {
    return(
      kpis.filter(kpi => (kpi.iotype === "Karte" || kpi.ktype === "Map")).map((kpi,i) => (
        <MDBCol key={i} sm="12" md="12" lg="6" xl="4" xxl="4">
          <MyMap kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog}  />
        </MDBCol>
        )
      )
    )
  }

  const iterateImages = () => {
    return(
      kpis.filter(kpi => (kpi.iotype === "Video" || kpi.iotype === "Bild" || kpi.iotype ==="IP Kamera Link") ).map((kpi,i) => (
        <MDBCol key={i} sm="12" md="12" lg="6" xl="4" xxl="4">
          <MyImager kpi={JSON.stringify(kpi)} user={props.user} but={showButton} delete={deleteKpi} edit={editKpi} close={closeDialog}  />
        </MDBCol>
        )
      )
    )
  }
  
  const newKpi = () => {
    setKpiID(null);
    setShowDialog(true);
  }
  
  const editKpi = (id) => {
    //alert("ID is.. "+id);
    setKpiID(id);
    setShowDialog(true);
    window.scrollTo(0,0);
  }

  const deleteKpi = (id) => {
    //alert("ID is.. "+id);
    if(window.confirm("Soll " + id + " wirklich gelöscht werden?")) 
      {
      //const killString = 'https://tgiot.herokuapp.com/api/v1/kpis/' + id;
      const killString = 'https://ioteasybackend.herokuapp.com/api/v1/kpis/' + id;
      console.log(killString);
      axios.delete(killString)
        .then(res => {
          console.log(res.data);
          this.setState({ isloading: true})
        })
        .catch((error) => {
          // Error
          console.log("error deleting data...");
          if (error.res) {
            console.log(error.res);
          }
        })
      } else 
      {
        //alert("nothing to do !")
      }
      //props.closeme();
  }
  
  const triggerRef = useRef(null);
  
  const showAlerts=() => {
    for(let i=0; i<10; i++) {
      if(document.getElementById('alertBtn'+String(i))) {
        //alert(i);
        const btn = document.getElementById('alertBtn'+String(i));
        btn.click();
      }
    }
  }
  
  const closeDialog = () => {
    //alert("closing...");
    setKpiID(null);
    setShowDialog(false);
  }

  return (

      isloading ? 
        <div className="loader">
          <MDBIcon className="fa-10x" spin fas icon='spinner' />
          <br /><br />
          <h1>loading Dashboard {props.gruppe}...</h1>
        </div> :
        
      <React.Fragment>
      <MDBContainer fluid className="cool" style={{width:"100%", marginTop:"75px"}}>
        <div className='myheader'>
            Dashboard {props.gruppe}  {" [" + count + "] "}
            <MDBBtn onClick={() => newKpi()} className="btn btn-lg btn-danger">{" "} Neue Komponente</MDBBtn> 
        </div>
        <MDBRow>
          {iterateButtons()}
          {iterateURLs()}
          {iterateRGBs()}
          {iterateCircleSliders()}
          {iterateTrafficLights()}
          {iterateTexter()}
          {iterateQRs()}
        </MDBRow>
        <MDBRow>
          {iterateGauges()}
          {iterateGraphs()}
          {iterateMaps()}
          {iterateImages()}
        </MDBRow>

        <MDBBtn id={'alertBtn'} style={{display:'none'}} ref={triggerRef}>show</MDBBtn>

        <MDBAlert show color='danger' autohide width={300} position='top-right' offset={50} delay={10000} stacking={true} triggerRef={triggerRef} >
          <h3 className='alert-heading'><MDBIcon fas icon='bell' className='fa-fw me-3' />Alarmübersicht</h3>
          {alerts.map((a,i) =>
            <div key={i}>
              <hr/>
              <h4>{a.kpi}</h4>
              <p>{a.crit} {a.value}</p>
            </div>
          )}
        </MDBAlert>
        

      </MDBContainer>
        {showDialog ?
          <MyDialogKpi close={closeDialog} user={props.user} kpi={kpiID} gruppe={props.gruppe} showme={showDialog} edit={editKpi} delete={deleteKpi}/>        
          :null
        }
      </React.Fragment>
    )
}
