import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import { MDBNavbar, MDBContainer, MDBNavbarBrand, MDBNavbarToggler, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBDropdownLink,
         MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';

export default function MyNavigation (props) {

  const [showMenu, setShowMenu] = useState(true);
  const [slimOpen, setSlimOpen] = useState(true);
  const [slimMode, setSlimMode] = useState(false);
  const [slimCollapse0, setSlimCollapse0] = useState(false);
  const [slimCollapse1, setSlimCollapse1] = useState(false);
  const [slimCollapse2, setSlimCollapse2] = useState(false);

  const genDash=() =>{
    return(
      props.user !== null ?
      props.dashboards.map((d,index) => 
        <MDBSideNavLink  key={index} onClick={() => cmenu("mydashboard", index)}>
          <MDBIcon fas icon="tachometer-alt" className='fa-fw me-3' style={{marginLeft:"20px"}} />
          {d.dashboard}
        </MDBSideNavLink>
      ) : null
    );
  };
  
  const cmenu = (p1,p2) => {
    props.menu(p1,p2);
    if(p1 !== "HOME") {
      setSlimOpen(!slimOpen)  
    }
  }
  
  return(
    <>
    <MDBNavbar expand='lg' light bgColor='dark' color="grey" fixed="top">
      <MDBContainer fluid>
        <MDBNavbarBrand onClick={() => cmenu("HOME", "TOP")} className="brand"> IOTeasy </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowMenu(!showMenu)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showMenu}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>

            <MDBNavbarItem >
              <MDBNavbarLink onClick={() => cmenu("HOME","PROJECTS")} className="navi" active aria-current='page' >
                <MDBIcon fas icon="users" /> Community
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem >
              <MDBNavbarLink onClick={() => cmenu("HOME","DEVICES")} className="navi" active aria-current='page' >
                <MDBIcon fas icon="microchip" /> Devices
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={() => cmenu("HOME","ABO")} className="navi"  aria-current='page' >
                <MDBIcon fas icon="credit-card" /> ABO
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={() => cmenu("HOME","LINK")} className="navi"  aria-current='page' >
                <MDBIcon fas icon="link" /> Links
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={() => cmenu("HOME", "CONTACT")} className="navi"  aria-current='page' >
                <MDBIcon fas icon="envelope" /> Kontakt
              </MDBNavbarLink>
            </MDBNavbarItem>
            {props.user ?
            <MDBNavbarItem>
              <MDBNavbarLink onClick={() => setSlimOpen(!slimOpen)} className="navi" aria-current='page' >
                <span className="yellow"> <MDBIcon fas icon="user" /> {props.user.name} {props.user.lastname} </span>
              </MDBNavbarLink>
            </MDBNavbarItem>
            : null }
            {false ?
            <MDBNavbarItem>
              <MDBNavbarLink onClick={() => cmenu("login")} className="navi"  aria-current='page' >
                <MDBIcon fas icon="user" /> Test Login
              </MDBNavbarLink>
            </MDBNavbarItem>
            :null}
          </MDBNavbarNav>

          <MDBNavbarNav right >
            {props.user === null ?
            <>
            <MDBNavbarItem>
              <MDBNavbarLink style={{cursor:"pointer"}} onClick={() => cmenu("testlogin")}>
                <span className='login '><MDBIcon fas icon="sign-in-alt" /> TestLogin</span>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink style={{cursor:"pointer"}} onClick={() => cmenu("login")}>
                <span className='login '><MDBIcon fas icon="sign-in-alt" /> Login</span>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink style={{cursor:"pointer"}} onClick={() => cmenu("register")}>
                <span className='login'><MDBIcon fas icon="sign-in-alt" /> Registrieren</span>
              </MDBNavbarLink>
            </MDBNavbarItem>
            </>
            :
            <MDBNavbarItem>
              <MDBNavbarLink style={{cursor:"pointer"}} onClick={() => cmenu("logout")}>
                <span className='logout '><MDBIcon fas icon="sign-out-alt"/> Logout</span>
              </MDBNavbarLink> 
            </MDBNavbarItem>
            }
          </MDBNavbarNav>

        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>    
    
    {props.user ?
      <MDBSideNav
        style={{position:"fixed", top:80}}
        color='light'
        bgColor='dark'

        backdrop={false}
        isOpen={slimOpen}
        absolute
        slim={slimMode}
        slimCollapsed={!slimCollapse1 && !slimCollapse2}
      >
        <MDBSideNavMenu>

              <MDBSideNavItem>
                <MDBSideNavLink icon='angle-down' iconAngle={180} onClick={() => setSlimCollapse1(!slimCollapse1)}>
                  <MDBIcon fas icon='signal' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>myDashboards</span>
                </MDBSideNavLink>
                <MDBSideNavCollapse show={slimCollapse1}>
                  {props.dashboards.length >0 ? genDash() 
                  :
                    <MDBSideNavLink  key={999} onClick={() => cmenu("mynewdashboard", 0)}>
                      <MDBIcon fas icon="tachometer-alt" className='fa-fw me-3' style={{marginLeft:"20px"}} />
                        Neues Dashboard
                      </MDBSideNavLink>
                  }
                </MDBSideNavCollapse>
              </MDBSideNavItem>

              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => cmenu("mydevices")}>
                  <MDBIcon fas icon='microchip' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>myDevices</span>
                </MDBSideNavLink>
              </MDBSideNavItem>

              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => cmenu("myrules")}>
                  <MDBIcon fab icon='gg-circle' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>myRules</span>
                </MDBSideNavLink>
              </MDBSideNavItem>
              
              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => cmenu("myalerts")}>
                  <MDBIcon fas icon='bell' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>myAlerts</span>
                </MDBSideNavLink>
              </MDBSideNavItem>

              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => cmenu("myprojects")}>
                  <MDBIcon fas icon='project-diagram' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>myProjects</span>
                </MDBSideNavLink>
              </MDBSideNavItem>


              {props.user.name !== "IOTeasy" ?
              <>
              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => cmenu("myabos")}>
                  <MDBIcon fas icon='credit-card' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>myAbos</span>
                </MDBSideNavLink>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => cmenu("myprofile")}>
                  <MDBIcon fas icon='user' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>myProfile</span>
                </MDBSideNavLink>
              </MDBSideNavItem>
              </>
              :null}

              {props.user.email === "horst.wurm@bluewin.ch" ?
              <>
              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => cmenu("myadmin")}>
                  <MDBIcon fas icon='tools' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>myAdmin</span>
                </MDBSideNavLink>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => cmenu("mytest")}>
                  <MDBIcon fas icon='cog' className='fa-fw me-3' />
                  <span className='sidenav-non-slim'>Test</span>
                </MDBSideNavLink>
              </MDBSideNavItem>
              </>
              :null}

        </MDBSideNavMenu>
      </MDBSideNav>
        : null}

    </>
    );
}
