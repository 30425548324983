import React, { useState, useEffect } from 'react';
import { 
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBContainer,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
} from 'mdb-react-ui-kit';

import {devices, deviceTypes, deviceCats, connectTypes, loraTypes, loraBands} from './param';

export default function MyWirer(props) {

  const [staticModal, setStaticModal] = useState(true);
  let index = -1;

  useEffect(() => {
    const canvas = document.getElementById("myCanvas");
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = 'rgb(255, 255, 255)';
    ctx.clearRect(0, 0, 940, 320);

    ctx.font = '12px arial';
    
    var img = new Image();
    img.source = "esp32.jpg"
    img.onload = function() {
        ctx.drawImage(img, 20, 20, 200,200);    
    }

    for(let d=0; d < devices[props.did].wiring.devices.length; d++) {
      //Device 1
      ctx.fillStyle = 'rgb(175, 175, 175)';
      if(devices[props.did].wiring.devices[d].typ==="big"){
        ctx.fillRect(d*200, 0, 150, 400);
      } else {
        ctx.fillRect(d*200, 0, 150, 150);
      }

      ctx.font = '16px arial';
      ctx.strokeStyle = 'rgb(255, 255, 255)';
      
      ctx.strokeText(devices[props.did].wiring.devices[d].name, d*200+10, 20);

      ctx.font = '12px arial';
      //ctx.fillStyle = 'rgb(0, 0, 0)';
      for(let p=0; p<devices[props.did].wiring.devices[d].pins.length; p++) {
        ctx.fillStyle = 'rgb(120, 120, 120)';
        ctx.fillRect(d*200+70, p*30+40, 15, 15);
        ctx.strokeText(devices[props.did].wiring.devices[d].pins[p].name, d*200+100, p*30+40);
      }
    
    }

    //Connectors
    ctx.lineWidth = 4;
    for(let c=0; c<devices[props.did].wiring.connections.length; c++) {
      
      ctx.strokeStyle = devices[props.did].wiring.connections[c].color;
      ctx.beginPath();
      
      let fromdev = devices[props.did].wiring.connections[c].from.dev;
      let frompin = devices[props.did].wiring.connections[c].from.pin;
      
      let todev = devices[props.did].wiring.connections[c].to.dev;
      let topin = devices[props.did].wiring.connections[c].to.pin;

      ctx.moveTo(fromdev*200-120,frompin*30+20);
      ctx.lineTo(todev*200-125,topin*30+20);
      ctx.stroke();

    }


  }, []);

  const drawme = () => {  
    //const canvas = document.querySelector('.myCanvas');
    const canvas = document.getElementById("myCanvas");
    //alert(canvas);
    //const width = canvas.width = window.innerWidth;
    //const height = canvas.height = window.innerHeight;  
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'rgb(0, 0, 0)';
    
    //ctx.fillRect(0, 0, width, height);
    };
    
  const getIndexofDeviceByValue = (i) => {
    let index = 0;
    for(let j=0; j<devices.length; j++) {
      if (devices[j].value===i) {
        index=j;
        break;
      }
    }
    return index;
  }  
    
  return (
    <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
    <h1>Bauteile</h1>
    <MDBTable>
    <MDBTableHead>
      <tr style={{color:"lightgrey"}}>
        <th scope='col'>Bauteil</th>
        <th scope='col'>Typ</th>
        <th scope='col'>Bezeichnung</th>
        <th scope='col'>Beschreibung</th>
      </tr>
    </MDBTableHead>
    <MDBTableBody>
      {devices[props.did].pack.map(d => 
        <tr style={{color:"lightgrey"}} key={d}>
          <td>
            <img src= {devices[getIndexofDeviceByValue(d)].picture} 
              width="70px"
              height="70px"        
              objectfit="cover"
            />
          </td>
          <td>
            {devices[getIndexofDeviceByValue(d)].type}<br />
          </td>
          <td>
            {devices[getIndexofDeviceByValue(d)].name}<br />
          </td>
          <td>
            {devices[getIndexofDeviceByValue(d)].desc}<br />
          </td>
        </tr>
        )}
      </MDBTableBody>
    </MDBTable>
    <h1>Verkabelung</h1>
    <canvas className="myCanvas" id="myCanvas" width="940" height="340">
      <p>Your browser doesn't support canvas. Boo hoo!</p>
    </canvas>
    </div>
  );
}