import React, { useState, useEffect } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBAccordion, 
  MDBAccordionItem,
  MDBRadio,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBValidation,
  MDBIcon,
  MDBScrollbar,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBSwitch
} from 'mdb-react-ui-kit';

import axios from 'axios';

export default function MyDialogProject(props) {

  const [staticModal, setStaticModal] = useState(true);

  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [manageAddon, setManageAddon] = useState(false);
  
  const [user, setUser] = useState(props.user);
  const [pid, setPid] = useState(props.id);
  

// Project
  //const [pid, setPid] = useState(props.id);
  const [ppublish, setPpublish] = useState(false);
  const [pname, setPname] = useState("Projekt 0815...");
  const [pdescription, setPdescription] = useState("");
  const [pcategory, setPcategory] = useState("Projekt");

  const [addons, setAddons] = useState([]);

// Addon
  const [aid, setAid] = useState(null);
  const [atype, setAtype] = useState("Bild");
  const [aname, setAName] = useState("");
  const [adescription, setAdescription] = useState("");
  const [doc, setDoc] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [filename, setFilename] = useState(null);
  
  const handleProject = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('user_id', user.id);
    bodyFormData.append('name', pname);
    bodyFormData.append('publish', ppublish);
    bodyFormData.append('description', pdescription);
    bodyFormData.append('category', pcategory);

    // CREATE Project
    if(pid === null) {
      bodyFormData.append('category', "Projekt");
      axios({
        method: "post",
        url: "https://ioteasybackend.herokuapp.com/api/v1/projects/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          //alert(response.data);
          console.log(response.data);
          //setPid(response.data.id);
          //alert(response.data.id);
          readProject(response.data.id);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      // UPDATE Project
      //alert(ppublish);
      axios({
        method: "put",
        url: "https://ioteasybackend.herokuapp.com/api/v1/projects/"+pid,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          props.closeme();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }  
  
  const handleAddon = () => {
    //alert(aid);

    var bodyFormData = new FormData();
    bodyFormData.append('project_id', pid);
    bodyFormData.append('name', aname);
    bodyFormData.append('description', adescription);
    bodyFormData.append('atype', atype);
    if(avatar && atype === "Bild") {
      bodyFormData.append('avatar', avatar);
    }
    if(doc && (atype === "Code" || atype ==="Dokument")) {
      bodyFormData.append('document', doc);
    }
    //bodyFormData.append('filename', filename);
    
    // CREATE AddOn
    if(aid === null) {
      axios({
        method: "post",
        url: "https://ioteasybackend.herokuapp.com/api/v1/addons/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          setManageAddon(false);
          readProject(pid);
          //alert(response.data);
          console.log(response.data);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      // UPDATE AddOn
      axios({
        method: "put",
        url: "https://ioteasybackend.herokuapp.com/api/v1/addons/"+aid,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          //alert("Update...."+response);
          setManageAddon(false);
          readProject(pid);
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }  
  
  const readProject = (id) => {
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/projects/'+id+"?key="+props.user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data.project);
        setProject(res.data.project);
        setPid(res.data.project.id);
        setPname(res.data.project.name);
        setPpublish(res.data.project.publish);
        setPdescription(res.data.project.description);
        setPcategory(res.data.project.category);
        setAddons(res.data.project.addons);
        setIsLoading(false);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }

  const readAddon = (id) => {
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/addons/'+id+"?key="+props.user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data.addon);
        setIsLoading(false);
        setAid(res.data.addon.id)
        setAtype(res.data.addon.atype)
        setAName(res.data.addon.name)
        setAdescription(res.data.addon.description)
        if(res.data.addon.filename)
          setFilename(res.data.addon.filename)
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }
  
  const deleteAddon = (id) => {
    if(id){
      const killString = 'https://ioteasybackend.herokuapp.com/api/v1/addons/' + id;
      console.log(killString);
      axios.delete(killString)
        .then(res => {
          console.log(res.data);
          readProject();
          //this.setState({ isloading: true})
        })
        .catch((error) => {
          // Error
          console.log("error deleting data...");
          if (error.res) {
            console.log(error.res);
          }
        })
    }
  }
  
  useEffect(() => {
    //alert(props.id);
    if(props.id !==null ) {
      readProject(props.id);
    } else {
      setIsLoading(false);
    }
  }, [props.id],[props.showProjectDialog]);

  const cancleHandleAddon = () => {
    setManageAddon(false);
  }
  
  const editAddon = (id) => {
    readAddon(id);
    setManageAddon(true);
  }

  const newAddon = () => {
    setAid(null);
    setFilename(null);
    setManageAddon(true);
  }
  
  const updateTyp = (e) => {
    if(e) {
      setAtype(e.text);
    }
  }

  return (
      <MDBModal className="bg-glasst" staticBackdrop show={props.showme} >
        <div className="myheader">Projekt {pname} </div>
          <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />

                {isLoading ? 
                  <div className="loader">
                    <MDBIcon className="fa-10x" spin fas icon='spinner' />
                    <br /><br />
                    <h1>loading Project...</h1>
                  </div> 
                :
                  <>
                    <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          input={"name"}
                          className="m-2 p-2"                  
                          label="Bezeichnung" 
                          //defaultValue={trim(deviceName)}
                          value={pname}
                          type="text" 
                          onChange={(e) => setPname(e.target.value)}
                        />
                        <MDBInput
                          input={"description"}
                          textarea
                          rows="4"
                          className="m-2 p-2"                  
                          label="Beschreibung"
                          value={pdescription}
                          type="text" 
                          onChange={(e) => setPdescription(e.target.value)}
                        />
                        <MDBSwitch 
                          label='auf IOTeasy teilen' 
                          checked={ppublish}
                          onChange={(e) => setPpublish(e.target.checked)}
                        />                        
                      </MDBCol>
                    </MDBRow>
                    </div>
                  
                    {props.id !== null || 1 === 1 ?
                      <>
                      {manageAddon ?
                        <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                            <h4>Anhang ({atype})</h4>
                            <MDBSelect
                              className="m-1 p-1"                  
                              label="Typ des Anhangs"
                              data={[{text:"Bild", value:"Bild"}, {text:"Code", value:"Code"}, {text:"Dokument", value:"Dokument"},{text:"Link", value:"Link"}]}
                              value={atype}
                              getValue={(e) => updateTyp(e) } 
                            />

                            {atype === "Dokument" || atype === "Code" ?
                              <>
                              <h4>Dokument/Code-Artefact hochladen</h4>
                              <MDBInput 
                                className="btn btn-info"
                                type="file" 
                                name="document"
                                accept="document/ino, document/pdf" 
                                multiple={false}
                                onChange={(e) => {setDoc(e.target.files[0]); setFilename(e.target.files[0])}} 
                              />
                              </>
                            : null
                            }
                            
                            {atype === "Bild" ?
                            <>
                            <h4>Bild hochladen</h4>
                            <MDBInput 
                              className="btn btn-info"
                              type="file" 
                              name="avatar" 
                              accept="image/png, image/jpeg, image/jpg" 
                              multiple={false}
                              onChange={(e) => {setAvatar(e.target.files[0]); setFilename(e.target.files[0])}} 
                            />
                            { filename !== "" && filename !== null ? 
                            <img 
                                src={filename}  
                                alt="responsive-image"
                                className="img-fluid"
                                width="200px"
                                height="200px"
                            /> :
                            <img
                                src="missing.png"
                                alt=""
                                className="img-fluid"
                            />}
                            </>
                            :null}

                            {atype === "Link" ?
                            <h4>Link</h4>
                            :null }

                            <MDBInput 
                              className="m-2 p-2"                  
                              input={aname}
                              label="Bezeichnung" 
                              value={aname}
                              type="text" 
                              onChange={(e) => {setAName(e.target.value)}}
                            />
                            <br />

                            <h4>Beschreibung</h4>
                            <MDBInput 
                              textarea
                              rows="4"
                              label="Beschreibung" 
                              value={adescription}
                              type="text" 
                              onChange={(e) => setAdescription(e.target.value)}
                            />
                        <br />
                        <div style={{textAlign:"right"}}>
                            <MDBBtn onClick={() => handleAddon()} className="btn btn-info" style={{margin:"10px"}}>Speichern</MDBBtn>
                            <MDBBtn onClick={() => cancleHandleAddon()} className="btn btn-dark" style={{margin:"10px"}}>Abbrechen</MDBBtn>
                        </div>
                        </div>
                        :
                        null
                      }

                      {/* Addon List */}
                      {project !== null ? 
                      <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                      <MDBRow>
                        <MDBCol>
                          <h1>Anhänge</h1>
                          <MDBBtn onClick={() => newAddon()} className="btn btn-info">Neuer Anhang</MDBBtn>
                          <MDBTable>
                          <MDBTableHead>
                            <tr style={{color:"lightgrey"}}>
                              <th scope='col'>Typ</th>
                              <th scope='col'>Beschreibung</th>
                              <th scope='col'>Anhang</th>
                              <th scope='col'>Aktion</th>
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                            {addons.map((a,i) =>
                                <tr style={{color:"lightgrey"}} key={i}>
                                  <td className="center">
                                    {a.atype === "Bild" ?
                                      <>
                                      {a.filename ?
                                        <img 
                                            src={a.filename}  
                                            alt="responsive-image"
                                            //className="img-fluid"
                                            width="200px"
                                            height="200px"
                                        /> :
                                        <img
                                            src="missing.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                      }
                                      </>
                                      :null
                                    }
                                    
                                    {a.atype === "Link" ?
                                      <a href={"https://"+a.name} target="_blank"><MDBIcon style={{color:"grey"}} fas icon="link" size="3x"/></a>
                                    :null
                                    }
  
                                    {a.atype === "Dokument" ?
                                      <a href={"https://"+a.filename} target="_blank"><MDBIcon style={{color:"grey"}} fas icon="file" size="3x"/></a>
                                    :null
                                    }
  
                                    {a.atype === "Code" ?
                                      <a href={"https://"+a.filename} target="_blank"><MDBIcon style={{color:"grey"}} fas icon="code" size="3x"/></a>
                                    :null
                                    }
  
                                  </td>
                                  <td>
                                    {a.description}
                                  </td>
                                  <td>
                                    {a.atype === "Link" ?
                                      <>{a.name}</> :
                                      <>{a.filename}</>
                                    }
                                  </td>
                                  <td>
                                    <MDBIcon onClick={() => deleteAddon(a.id)} className="m-1 p-2 red" fas icon="trash"/>
                                    <MDBIcon onClick={() => editAddon(a.id)} className="m-1 p-2 lightgrey" fas icon="wrench"/>
                                  </td>
                                </tr>
                            )}
                            </MDBTableBody>
                          </MDBTable>
                        </MDBCol>
                      </MDBRow>
                      </div>
                      :null}

                      </>
                      :null
                    }
                </>
                }
              <div style={{textAlign:"right"}}>
              <MDBBtn onClick={() => handleProject()} type="Submit" className="btn btn-info" style={{margin:"10px"}}>Speichern</MDBBtn>
              <MDBBtn onClick={() => props.closeme()} className="btn btn-dark" style={{margin:"10px"}}>Abbrechen</MDBBtn>
              </div>
      </MDBModal>
      
  );
}