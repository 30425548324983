import { MDBContainer } from 'mdb-react-ui-kit';

export default function CodeLoop_noCon(props) {
  
const pinReads = (read, print) => {
  return(
    <>
    {props.devices[props.did].pins.map((p,i) => 
      <div key={i}>
        <>
          {read ? <>{p.pintype} {p.pinvar} = {p.pinread}({p.piname});<br /></>:null}
          {print ? <>Serial.println("{p.pinvar}: " + String({p.pinvar})); <br /></>:null}
        </>
      </div> 
    )}
    </>
    );
}  

const calcReads = () => {
  return(
    <>
    {props.devices[props.did].calcs ?
      <>
      {props.devices[props.did].calcs.map((c,i) => 
        <div key={i}>
          <>
            {c.calc}<br />
          </>
        </div> 
      )}
      </>
    :null}
    </>
  );
}  

const pinLoops =() => {
  return(
    <>
    {props.devices[props.did].loops.map((s,i) =>
        <div key={i}>
            <>
                {s.loop}< br />
            </>
        </div>
    )}
    </>
  )
}

    return(
<MDBContainer className="lightgrey">
// ********************************************************************************************<br />
<h1>// Loop </h1>
// ********************************************************************************************<br />
void loop() {"{"}<br />

<div className="tab1">

{props.devices[props.did].type === "Sensor" && props.devices[props.did].controls.trigger === true ?
  <>
    {pinReads(true,false)}
    {calcReads()}

    if(wert {"!="} wertsave) {"{"}<br/>
    <div className="tab2">
      {pinReads(false,true)}
      {pinLoops()}
      wertsave = wert;<br/>
    </div>
    {"}"}<br />
  </>
:null
}

{props.devices[props.did].type === "Sensor" && !props.devices[props.did].controls.trigger ?
  <>
  if(millis() {">"} millisave + delays)  {"{"}<br/>
    <div className="tab2">
      {pinReads(true,true)}
      {pinLoops()}
      millisave = millis();<br/>
    </div>
  {"}"}<br />
  </>
:null
}

{props.devices[props.did].type === "Aktor" ?
  <div className="tab1">
    <>
    if(millis() {">"} millisave + delays)  {"{"}<br/>
    </>
    <div className="tab2">
      {pinLoops()}
      millisave = millis();<br/>
    </div>
    <div className="tab1">
    {"}"}<br />
    </div>
  </div>
:null
}

{props.devices[props.did].type === "Spezial" ?
  <div className="tab1">
    {pinLoops()}
  </div>
:null
}

</div>
{"}"}<br />

</MDBContainer>
)}

