import React, { useState, useEffect } from 'react';

import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBIcon,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBRadio,
  MDBDatepicker,
  MDBTimepicker
} from 'mdb-react-ui-kit';

import axios from 'axios';

export default function MyDialogRule(props) {

  const [staticModal, setStaticModal] = useState(true);

  const [critOptions, setCritOptions] = useState([props.crits]);
  const [currentValue, setCurrentValue] = useState(0);
  
  const [isloading, setIsLoading] = useState(true);

  const [id, setId] = useState(props.id);
  const [name, setName] = useState("");
  const [rtype, setRtype] = useState("Daten");
  const [active, setActive] = useState(true);
  const [date_from, setDate_from] = useState(new Date().toDateString());
  const [date_to, setDate_to] = useState(new Date().toDateString());
  const [time_f, setTime_f] = useState("01:00");
  const [time_t, setTime_t] = useState("17:00");
  const [sourceCrit, setSourceCrit] = useState(null);
  const [currentSourceCrit, setCurrentSourceCrit] = useState(null);
  const [compareOperator, setCompareOperator] = useState("==");
  const [compareValue, setCompareValue] = useState(0);
  const [targetCrit, setTargetCrit] = useState(null);
  const [currentTargetCrit, setCurrentTargetCrit] = useState(null);
  const [targetValue, setTargetValue] = useState(0);
  const [persType, setPerseType] = useState("final");
  const [restoreValue, setRestoreValue] = useState(0);
  const [duration, setDuration] = useState(0);
  const [lastExecution, setLastExecution] = useState(null);
  const [lastRestore, setLastRestore] = useState(null);
  const [status, setStatus] = useState(null);

  const readRule = (id) => {
    setIsLoading(true);
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/rules/'+props.id;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data.rule);
        setId(res.data.rule.id);
        setName(res.data.rule.name);
        setActive(res.data.rule.active);
        setRtype(res.data.rule.rtype);
        setDate_from(res.data.rule.date_from);
        setDate_to(res.data.rule.date_to);
        setTime_f(res.data.rule.time_f);
        setTime_t(res.data.rule.time_t);
        setSourceCrit(res.data.rule.sourcecrit);
        setCompareOperator(res.data.rule.compareoperator);
        setCompareValue(res.data.rule.comparevalue);
        setTargetCrit(res.data.rule.targetcrit);
        setTargetValue(res.data.rule.targetvalue);
        setPerseType(res.data.rule.perstype);
        setRestoreValue(res.data.rule.restorevalue);
        setDuration(res.data.rule.duration);

        setIsLoading(false);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }
  
  const handleRule = (e) => {
    //e.preventDefault();

    var bodyFormData = new FormData();
    
    bodyFormData.append('name', name);
    bodyFormData.append('active', active);
    bodyFormData.append('rtype', rtype);
    bodyFormData.append('date_from', date_from);
    bodyFormData.append('date_to', date_to);
    bodyFormData.append('time_f', time_f);
    bodyFormData.append('time_t', time_t);
    bodyFormData.append('sourcecrit', sourceCrit);
    bodyFormData.append('compareoperator', compareOperator);
    bodyFormData.append('comparevalue', compareValue);
    bodyFormData.append('targetcrit', targetCrit);
    bodyFormData.append('targetvalue', targetValue);
    bodyFormData.append('perstype', persType);
    bodyFormData.append('restorevalue', restoreValue);
    bodyFormData.append('duration', duration);

    // CREATE RULE
    if(props.id==null) {
      axios({
        method: "post",
        url: "https://ioteasybackend.herokuapp.com/api/v1/rules/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      // UPDATE RULE
      axios({
        method: "put",
        url: "https://ioteasybackend.herokuapp.com/api/v1/rules/"+props.id,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          //readRules(props.id);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    props.closeme();
  }
  
  useEffect(() => {
    let co = []
    for(let i=0; i<props.crits.length;i++) {
      co.push({value: props.crits[i].id, text: (props.crits[i].kpi + " " + props.crits[i].name), lastval:props.crits[i].lastval });
    }
    setCritOptions(co);
    if(props.id>0) {
      readRule(props.id)
    }
    setIsLoading(false);
  },[]);  
  
  useEffect(() => {
    //alert("hi");
    for(let i=0; i<critOptions.length; i++) {
      if(sourceCrit==critOptions[i].value) {
        setCurrentSourceCrit(critOptions[i].text);
      }
      if(targetCrit==critOptions[i].value) {
        setCurrentTargetCrit(critOptions[i].text);
      }
    }
  },[sourceCrit, targetCrit]);
  
  const handleSourceCrit = (item) => {
    if(item && critOptions) {
      //alert(item.value);
      setSourceCrit(item.value);
      for(let i=0; i<critOptions.length; i++) {
      //alert(item.value + " ===? " + critOptions[i].value);
        if(item.value==critOptions[i].value) {
          //alert("setting curval to "+ critOptions[i].lastval);
          setCurrentValue(critOptions[i].lastval);
        }
      }
    }
  }
  
  const handleTargetCrit = (item) => {
    if(item && critOptions) {
      //alert(item.value);
      setTargetCrit(item.value);
      //for(let i=0; i<critOptions.length; i++) {
      //alert(item.value + " ===? " + critOptions[i].value);
        //if(item.value==critOptions[i].value) {
          //alert("setting curval to "+ critOptions[i].lastval);
          //setCurrentValue(critOptions[i].lastval);
        //}
      //}
    }
  }

  return (
      <MDBModal className="bg-glasst" staticBackdrop show={props.showme} >
        <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
          <h1>Regel
          {id ? 
            <>{" " + name}</>
          : <>{" "}neu</>
          }
          </h1>
          <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />
          {isloading ? null :
          <form>
            <MDBInput
              id="name"
              className="m-2 p-2"
              name={name}
              type='text'
              label="Bezeichnung" 
              value={name}
              onChange={(e) => setName(e.target.value)}
            /> 
            <MDBCheckbox onChange={(e) => setActive(e.target.checked)} name='active' value={active} id='active' label='aktiv' checked={active} />
            
            <MDBRow>
                <MDBCol>
                  <h1>Typ...</h1>
                </MDBCol>
            </MDBRow>
            
            <MDBRadio onChange={(e) => setRtype(e.target.name)} name={"Daten"} label={"Daten"} checked={rtype=="Daten"} />
            <MDBRadio onChange={(e) => setRtype(e.target.name)} name={"Datum/Zeit"} label={"Datum/Zeit"} checked={rtype=="Datum/Zeit"} />

            <MDBRow>
                <MDBCol>
                  <h1>wenn...</h1>
                </MDBCol>
            </MDBRow>

            {rtype==="Datum/Zeit" ?
              <>
              <MDBRow>
                <MDBCol>
                  <MDBDatepicker value={date_from} setValue={setDate_from}  />
                </MDBCol>
                <MDBCol>
                  <MDBDatepicker value={date_to} setValue={setDate_to}  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBTimepicker format='24h'  defaultTime={"01:00 PM"} getValue={(value)=>{setTime_f(value)}} />
                </MDBCol>
                <MDBCol>
                  <MDBTimepicker format='24h'  defaultTime={time_t} getValue={(value)=>{setTime_t(value)}} />
                </MDBCol>
              </MDBRow>
              </>
            : 
              <MDBRow>
                <MDBCol>
                  <MDBSelect
                    className="m-1 p-1"                  
                    label="Quell Dashboard Komponente"
                    data={critOptions}
                    value={currentSourceCrit}
                    //getValue={(value) => setSourceCrit(value)}
                    getValue={(value) => handleSourceCrit(value)}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBRadio onChange={(e) => setCompareOperator(e.target.name)} name={"<="} label={"kleiner oder gleich"} checked={compareOperator=="<="} />
                  <MDBRadio onChange={(e) => setCompareOperator(e.target.name)} name={"=="} label={"gleich"} checked={compareOperator=="=="} />
                  <MDBRadio onChange={(e) => setCompareOperator(e.target.name)} name={"!="} label={"ungleich"} checked={compareOperator=="!="} />
                  <MDBRadio onChange={(e) => setCompareOperator(e.target.name)} name={">="} label={"grösser oder gleich"} checked={compareOperator==">="} />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    id="compareValue"
                    className="m-2 p-2"
                    name={compareValue}
                    type='text'
                    label="Vergleichswert" 
                    value={compareValue}
                    onChange={(e) => setCompareValue(e.target.value)}
                  /> 
                  <div className="yellow">
                  aktueller Wert: {currentValue}
                  </div>
                </MDBCol>
              </MDBRow>
            }

            <MDBRow>
                <MDBCol>
                  <h1>dann...</h1>
                </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBSelect
                  className="m-1 p-1"                  
                  label="Ziel Dashboard Komponente"
                  data={critOptions}
                  value={currentTargetCrit}
                  //getValue={(value) => setSourceCrit(value)}
                  getValue={(value) => handleTargetCrit(value)}
                />
              </MDBCol>
              <MDBCol>
                gleich
              </MDBCol>
              <MDBCol>
                  <MDBInput
                    id="targetValue"
                    className="m-2 p-2"
                    name={targetValue}
                    type='text'
                    label="Zielwert" 
                    value={targetValue}
                    onChange={(e) => setTargetValue(e.target.value)}
                  /> 
              </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                  <h1>Dauer...</h1>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                  <MDBRadio onChange={(e) => setPerseType(e.target.name)} name={"final"} label={"final"} checked={persType=="final"} />
                  <MDBRadio onChange={(e) => setPerseType(e.target.name)} name={"temporär"} label={"temporär"} checked={persType=="temporär"} />
                </MDBCol>
                <MDBCol>
                  {persType=="temporär" ?
                  <MDBInput
                    id="duration"
                    className="m-2 p-2"
                    name={duration}
                    type='number'
                    label="Dauer in Minuten" 
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                  /> 
                    :null
                  }
                </MDBCol>
                <MDBCol>
                  {persType=="temporär" ?
                  <MDBInput
                    id="restorevalue"
                    className="m-2 p-2"
                    name={restoreValue}
                    type='number'
                    label="Rücksetzwert" 
                    value={restoreValue}
                    onChange={(e) => setRestoreValue(e.target.value)}
                  /> 
                    :null
                  }
                </MDBCol>
            </MDBRow>
              
          </form>}
          <div style={{textAlign:"right"}}>
            <MDBBtn onClick={() => handleRule()} type="Submit" className="btn btn-info" style={{margin:"10px"}}>Speichern</MDBBtn>
            <MDBBtn onClick={() => props.closeme()} className="btn btn-dark" style={{margin:"10px"}}>Abbrechen</MDBBtn>
          </div>

        </div>
      </MDBModal>
  );
}