import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MDBContainer, MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

//import MyYesNoBtn from './myYesNoBtn';
import MyAbo from './myAbo';
import MyStripe from './myStripe';
//import MyDummy from './myDummyM';

import axios from 'axios';

export default function MyAbos (props) {

  const [paket, setPaket] = useState(null);
  const [price, setPrice] = useState(null);
  const [payMessage, setPayMessage] = useState(null);

  const [pkey, setPkey] = useState(null);
  const [abos, setAbos] = useState([]);
  const [duration, setDuration] = useState(0);
  const [isloading, setIsLoading] = useState(true);

  const [showAboDialog, setShowAboDialog] = useState(false);
  const [aboID, setAboID] = useState(null);

  //life-cycle hooks
  useEffect(() => {
    readpkey();
    readAbos();
    const timer = setInterval(() => {
       setPayMessage(null);
       readAbos();
    }, 30000);
    
    return () => {
      clearInterval(timer);
    }    
    
  },[]);
  
  const readpkey = () => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/dashboards/getstripekey';
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/getstripekey';
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data.key);
        setPkey(res.data.key);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }    

  const hideDialog = () => {
    setShowAboDialog(false);
  }

  const cleaner = (mode, paket) => {
    //alert(mode);
    if(mode==="OK") {
      setPayMessage("Herzlichen Dank " + props.user.name + " !");
      //updateAbos(paket);
      
    } else {
      setPayMessage("Schade " + props.user.name + ", vielleicht später !");
    }
    readAbos();
  }
  
  const newAbo = (paket, price) => {
    setPaket(paket);
    setPrice(price);
    setShowAboDialog(true)
    //ReactDOM.render(<MyStripe cleaner={cleaner} pkey={pkey} user={JSON.stringify(props.user)} paket={paket} price={price} />, document.getElementById('modal'));
  }
  
  const deleteAbo = (id) => {
    if(window.confirm("Soll Abo " + id + " wirklich gelöscht werden?")) {
      //const killString = 'https://tgiot.herokuapp.com/api/v1/abos/' + id;
      const killString = 'https://ioteasybackend.herokuapp.com/api/v1/abos/' + id;
      console.log(killString);
      axios.delete(killString)
        .then(res => {
          console.log(res.data);
          readAbos();
          //this.setState({ isloading: true})
        })
        .catch((error) => {
          // Error
          console.log("error deleting data...");
          if (error.res) {
            console.log(error.res);
          }
        })
    }
  }
  
  const buyAbo = () => {
      var formData = new FormData();
      formData.append('user_id', props.user.id);
      formData.append('topic', paket);
      axios({
        method: "post",
        //url: "https://tgiot.herokuapp.com/api/v1/abos/",
        url: "https://ioteasybackend.herokuapp.com/api/v1/abos/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          closeme();
          console.log(response);
          readAbos();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
  }
  
  const readAbos = () => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/abos?key='+props.user.userkey;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/abos?key='+props.user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        setAbos(res.data.abos);
        setDuration(res.data.abodur);
        console.log(res.data.abos);
        console.log(res.data.abodur);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  
  
  const closeme = () => {
    setShowAboDialog(false);
  }
  
  return(
    <React.Fragment>
    <MDBContainer fluid className="cool" style={{width:"100%", marginTop:"75px"}}>
    
      <MyAbo user={props.user} buy={newAbo}/>
      {duration > 0 ?
      <h1 className="green big"> noch {duration} Tage </h1>
      :null}

      {isloading ? 
        <div className="loader">
          <MDBIcon className="fa-10x" spin fas icon='spinner' />
          <br /><br />
          <h1>loading Abos...</h1>
        </div> 
      :
        <React.Fragment>
          {payMessage !=null ? 
            <h1 className="yellow big"> {payMessage} </h1>
            : null}
          <br/>

          <div className="myheader">Aboübersicht{" "}</div>

          <MDBTable className="white" style={{color:"#A0A0A0", textAlign:"left"}}>
          <MDBTableHead>
            <tr>
              <th scope='col'>Status</th>
              <th scope='col'>Abo</th>
              <th scope='col'>gültig von</th>
              <th scope='col'>gültig bis</th>
              <th scope='col'></th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {abos.map((a,i) => 
                <tr key={i}>
                  <td>
                    {a.status}
                  </td>
                  <td>
                    {a.topic}
                  </td>
                  <td>
                    {a.date_from}
                  </td>
                  <td>
                    {a.date_to}
                  </td>
                  <td>
                    <MDBIcon onClick={() => deleteAbo(a.id)}  icon='trash' />
                  </td>
                </tr>
            )}
            </MDBTableBody>
          </MDBTable>
          
          
        </React.Fragment>
      }
    
    </MDBContainer>
    {showAboDialog ?
      <>
      {/* <MyStripe user={props.user} id={aboID} showme={showAboDialog} closeme={hideDialog} /> */}
      <MyStripe pkey={pkey} user={JSON.stringify(props.user)} paket={paket} price={price} showme={showAboDialog} closeme={closeme} buyAbo={buyAbo} />
      </>
      :null
    }
    </React.Fragment>

  )

}
