import React, { useState, useEffect } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBAccordion, 
  MDBAccordionItem,
  MDBRadio,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBValidation,
  MDBIcon,
  MDBScrollbar,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody
} from 'mdb-react-ui-kit';

import axios from 'axios';
import {devices, deviceTypes, deviceCats, connectTypes, loraTypes, loraBands, dashOptions} from './param';

import ErrorHints from './errorHints';
import MyWirer from './myWirer';

import CodeIntro_gen from './codeIntro_gen';
import CodeInit_gen from './codeInit_gen';
import CodeSetup_gen from './codeSetup_gen';
import CodeLoop_wlan from './codeLoop_wlan';
import CodeLoop_lora from './codeLoop_lora';
import CodeRead_gen from './codeRead_gen';
import CodeWrite_gen from './codeWrite_gen';
import CodeFunc_gen from './codeFunc_gen';

import MyOnOffButton from './myOnOffButton';
import MyGaugeChart from './myGaugeChart';
import MyColorPicker from './myColorPicker';
import MyCircleSlider from './myCircleSlider';
import MyCharter from './myCharter';
import MyMap from './myMap';
import MyTrafficLight from './myTrafficLight';
import MyImager from './myImager';
import MyTexter from './myTexter';
import MyURLChecker from './myURLChecker';
import MyQRCoder from './myQRCoder';
import MyDialogData from './myDialogData';

export default function MyDialogDevice(props) {

  const [isloading, setIsloading] = useState(true);

  const [changeKpi, setChangeKpi] = useState(false);

  const [fillActive, setFillActive] = useState('tab1');

  const [staticModal, setStaticModal] = useState(true);

  const [deviceIndex, setDeviceIndex] = useState(0);

  const [deviceName, setDeviceName] = useState("newDevice");
  const [deviceDtype, setDeviceDtype] = useState("");
  const [deviceDtypeNr, setDeviceDtypeNr] = useState(0);

  const [device, setDevice] = useState("");

  const [kpis, setKpis] = useState([]);
  
  const [kpiNr, setKpiNr] = useState(0);
  const [kpiName, setKpiName] = useState("");
  const [kpiIotype, setKpiIotype] = useState("");
  const [connectTypName, setConnectTypName] = useState("WLAN");
  const [loraTypName, setLoraTypName] = useState("node");
  const [loraBandName, setLoraBandName] = useState("8868 MHz Europa");
  const [ssid, setSsid] = useState("WLAN0815");
  const [password, setPassword] = useState("password");
  const [frequency, setFrequency] = useState(30);

  const handleDevice = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('kpi_id', kpiNr);
    bodyFormData.append('dtype', deviceDtype);
    bodyFormData.append('dtypenr', deviceDtypeNr);
    bodyFormData.append('name', deviceName);
    bodyFormData.append('connectivity', connectTypName);
    bodyFormData.append('loramode', loraTypName);
    bodyFormData.append('loraband', loraBandName);
    bodyFormData.append('ssid', ssid);
    bodyFormData.append('password', password);
    bodyFormData.append('frequency', frequency);
    
    // UPDATE DEVICE
    axios({
      method: "put",
      //url: "https://tgiot.herokuapp.com/api/v1/devices/"+props.id,
      url: "https://ioteasybackend.herokuapp.com/api/v1/devices/"+props.id,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      //handle success
      console.log(response.data);
      props.closeme();
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
  }

  const readDevice = () => {
    //const readString = 'https://tgiot.herokuapp.com/api/v1/devices/'+props.id;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/devices/'+props.id;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data.device);
        setDevice(res.data.device);
        //readKPIs(deviceDtype);
        for(let i=0; i<devices.length; i++) {
          if(devices[i].text === res.data.device.dtype) {
            setDeviceIndex(i);
            readKPIs(i);
          }
        }
        setKpiNr(res.data.device.kpi_id);
        setKpiName(res.data.device.kpi);
        setKpiIotype(res.data.device.iotype);
        setDeviceName(res.data.device.name);
        setDeviceDtype(res.data.device.dtype);
        setDeviceDtypeNr(res.data.device.dtypenr);
        setConnectTypName(res.data.device.connectivity);
        setLoraTypName(res.data.device.loramode);
        setLoraBandName(res.data.device.loraband);
        setSsid(res.data.device.ssid);
        setPassword(res.data.device.password);
        setFrequency(res.data.device.frequency);

        setIsloading(false);

      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }
  
  const readKPIs = (index) => {
    
    let typs=[]
    for(let i=0; i<devices[index].dashoptions.length; i++) { 
      typs.push(dashOptions[devices[index].dashoptions[i]].text)
    }
    //alert(typs);
    //const readString = 'https://tgiot.herokuapp.com/api/v1/dashboards/matchingkpis?iotypes='+typs+"&key="+props.user.userkey;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/matchingkpis?iotypes='+typs+"&key="+props.user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log("ok...");
        setKpis(res.data.kpis);
        setIsloading(false);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }
  
  const handleFillClick = (value) => {
      //alert(value);
      if(value==="tab3") {
        //readUsers();
        //readKpiUsers(props.id);
      }
    if (value === fillActive) {
      return;
    }
    setFillActive(value);
  };

  useEffect(() => {
  }, []);
  
  useEffect(() => {
    readDevice(props.id);
  }, [props.id]);
  
  const handleKpiChange = (id, name, iotype) => {
    console.log("setting KPI Typ to " + id);
    setKpiNr(id);
    setKpiName(name);
    setKpiIotype(iotype);
    setChangeKpi(false);
  }
  
  const clipcopy = () => {

    //var copyText = document.getElementById("clip").textContent.toString();
    //var copyText = document.getElementById("clip").textContent;

    var text = document.getElementById("clip").innerText;
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });

  }
  
  return (
    <>
      <MDBModal className="bg-glasst" staticBackdrop show={props.showme} >
        <div className="myheader">
        Device
        {props.id ? 
            <>{" " + device.name}</>
          : <>{" "}neu</>
        }
        </div>
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />

              <MDBTabs fill className='mb-3' >
                <MDBTabsItem>
                  <MDBTabsLink className="tl" onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
                    Device
                  </MDBTabsLink>
                </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink className="tl" onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
                      Daten
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink className="tl" onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
                      Connectivity
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink className="tl" onClick={() => handleFillClick('tab4')} active={fillActive === 'tab4'}>
                      Bauteile und Verkabelung
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink className="tl" onClick={() => handleFillClick('tab5')} active={fillActive === 'tab5'}>
                      MC Programmcode
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink className="tl" onClick={() => handleFillClick('tab6')} active={fillActive === 'tab6'}>
                      Fehlerhandling
                    </MDBTabsLink>
                  </MDBTabsItem>
              </MDBTabs>

              {isloading ? 
                <div className="loader">
                  <MDBIcon className="fa-10x" spin fas icon='spinner' />
                  <br /><br />
                  <h1>loading Device...</h1>
                </div> :

              <MDBTabsContent>
                <MDBTabsPane show={fillActive === 'tab1'}>

                  <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                    <h1>Device</h1>
                    <MDBRow>
                      <MDBCol>
                        <img src={devices[deviceIndex].picture} 
                          width="200px"
                          height="200px"        
                          objectfit="cover"
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          input={"name"}
                          className="m-2 p-2"                  
                          label="Bezeichnung" 
                          value={deviceName}
                          type="text" 
                          onChange={(e) => setDeviceName(e.target.value)}
                        />
                        <MDBInput
                          input={"Frequenz"}
                          className="m-2 p-2"                  
                          label="Update-Frequenz"
                          value={frequency}
                          type="number" 
                          onChange={(e) => setFrequency(e.target.value)}
                        />
                      </MDBCol>
                    </MDBRow>
                  </div>
                </MDBTabsPane>

                <MDBTabsPane show={fillActive === 'tab2' }>
                  <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                  <h1>Dashboard Referenz</h1>
                  <MDBRow>
                    <MDBCol sm="12" md="12" lg="12" xl="12" xxl="12">
                      <MDBTable>
                      <MDBTableHead>
                        <tr style={{color:"lightgrey"}}>
                          <th colSpan="2" scope='col'>Dashboard Komponente</th>
                          <th scope='col'>Typ</th>
                          <th scope='col'>Aktionen</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                          <tr style={{color:"lightgrey"}}>
                            <td>
                              {kpiIotype === "Schalter" ? 
                                  <MyOnOffButton kpi={JSON.stringify({name:kpiName, data2:[1]})} user={props.user} but={false} />
                                :null
                              }
                              {kpiIotype === "Regler" ? 
                                  <MyCircleSlider kpi={JSON.stringify({name:kpiName, data2:[100], bound:[{uwert:50, owert:150}] })} user={props.user} but={false} />
                                :null
                              }
                              {kpiIotype === "Regler RGB" ? 
                                  <MyColorPicker kpi={JSON.stringify({name:kpiName, data2:["#ff00ff"]})} user={props.user}   but={false} />
                                :null
                              }
                              {kpiIotype === "Ampel" ? 
                                  <MyTrafficLight kpi={JSON.stringify({name:kpiName, data2:["red"]})} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Tachometer" ? 
                                  <MyGaugeChart kpi={JSON.stringify({name:kpiName, data2:[500]})} user={props.user}   but={false} />
                                :null
                              }
                              {kpiIotype === "Text" ? 
                                  <MyTexter kpi={JSON.stringify({name:kpiName, data2:["ich bin ein Text..."]})} user={props.user}   but={false} />
                                :null
                              }
                              {kpiIotype === "QRCode" ? 
                                  <MyQRCoder kpi={JSON.stringify({name:kpiName, data2:[kpiName]})} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Laufschrift" ? 
                                  <MyTexter kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, data2:[kpiName]})} user={props.user}   but={false} />
                                :null
                              }
                              {kpiIotype === "Graphik" ? 
                                  <MyCharter kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, label:["1","2","3","4","5","6","7","8","9","10"], data1:[[0,100,24,32,44,5,33,4,54,6]], label1:["1st Dataset"]})} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Graphik n-Dim" ? 
                                  <MyCharter kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, label:["1","2","3","4","5","6","7","8","9","10"], data1:[[0,100,24,32,44,5,33,4,54,6],[100,24,32,44,5,33,4,54,6,99]], label1:["1st Dataset", "2nd Dataset"]})} user={props.user} but={false}   />
                                :null
                              }
                              {kpiIotype === "Karte" ? 
                                  <MyMap kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, data1:[[8.5400],[47.3786 ]], data2:[8.5400, 47.3786] })} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Bild" ? 
                                  <MyImager kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, picture2:["https://mdbootstrap.com/img/new/standard/city/044.webp"] })} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Video" ? 
                                  <MyImager kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, data2:["https://www.youtube.com/embed/vlDzYIIOYmM"] })} user={props.user}  but={false} />
                                :null
                              }
                              {kpiIotype === "URL check" ? 
                                  <MyURLChecker kpi={JSON.stringify({iotype:kpiIotype, name:kpiName, data2:[1] })} user={props.user} but={false}   />
                                :null
                              }
                            </td>
                            <td>
                              {kpiName}<br />
                            </td>
                            <td>
                              {kpiIotype}<br />
                              {device ? device.crits.map((c,i) => <div key={i}>{c.name}</div>) : null}
                            </td>
                            <td>
                              <MDBIcon className="m-1 p-2 lightgrey" onClick={() => setChangeKpi(true)} fas icon="wrench"/>
                            </td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>

                      {changeKpi ?
                        <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                        <h1>Dashboard Referenzen Auswahl <MDBBtn className="btn btn-dark" onClick={() => setChangeKpi(false)}>Abbrechen</MDBBtn></h1>
                        {kpis.length>0 ?
                          <>
                          <h2>Welches Dashboard-Widget möchten Sie verwenden ?</h2>
                          <MDBRow>
                            {kpis.map((w,i) =>
                              <>
                              <MDBCol onClick={() => handleKpiChange(w.id, w.name, w.iotype)} key={i} className="bg-classi" style={{textAlign:"center"}}>
                              {kpiIotype === "Schalter" ? 
                                  <MyOnOffButton kpi={JSON.stringify({name:w.name, data2:[1]})} user={props.user} but={false} />
                                :null
                              }
                              {kpiIotype === "Regler" ? 
                                  <MyCircleSlider kpi={JSON.stringify({name:w.name, data2:[100], bound:[{uwert:50, owert:150}] })} user={props.user} but={false} />
                                :null
                              }
                              {kpiIotype === "Regler RGB" ? 
                                  <MyColorPicker kpi={JSON.stringify({name:w.name, data2:["#ff00ff"]})} user={props.user}   but={false} />
                                :null
                              }
                              {kpiIotype === "Ampel" ? 
                                  <MyTrafficLight kpi={JSON.stringify({name:w.name, data2:["red"]})} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Tachometer" ? 
                                  <MyGaugeChart kpi={JSON.stringify({name:w.name, data2:[500]})} user={props.user}   but={false} />
                                :null
                              }
                              {kpiIotype === "Text" ? 
                                  <MyTexter kpi={JSON.stringify({name:w.name, data2:["ich bin ein Text..."]})} user={props.user}   but={false} />
                                :null
                              }
                              {kpiIotype === "QRCode" ? 
                                  <MyQRCoder kpi={JSON.stringify({name:w.name, data2:[kpiName]})} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Laufschrift" ? 
                                  <MyTexter kpi={JSON.stringify({iotype:kpiIotype, name:w.name, data2:[kpiName]})} user={props.user}   but={false} />
                                :null
                              }
                              {kpiIotype === "Graphik" ? 
                                  <MyCharter kpi={JSON.stringify({iotype:kpiIotype, name:w.name, label:["1","2","3","4","5","6","7","8","9","10"], data1:[[0,100,24,32,44,5,33,4,54,6]], label1:["1st Dataset"]})} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Graphik n-Dim" ? 
                                  <MyCharter kpi={JSON.stringify({iotype:kpiIotype, name:w.name, label:["1","2","3","4","5","6","7","8","9","10"], data1:[[0,100,24,32,44,5,33,4,54,6],[100,24,32,44,5,33,4,54,6,99]], label1:["1st Dataset", "2nd Dataset"]})} user={props.user} but={false}   />
                                :null
                              }
                              {kpiIotype === "Karte" ? 
                                  <MyMap kpi={JSON.stringify({iotype:kpiIotype, name:w.name, data1:[[8.5400],[47.3786 ]], data2:[8.5400, 47.3786] })} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Bild" ? 
                                  <MyImager kpi={JSON.stringify({iotype:kpiIotype, name:w.name, picture2:["https://mdbootstrap.com/img/new/standard/city/044.webp"] })} user={props.user}  but={false}  />
                                :null
                              }
                              {kpiIotype === "Video" ? 
                                  <MyImager kpi={JSON.stringify({iotype:kpiIotype, name:w.name, data2:["https://www.youtube.com/embed/vlDzYIIOYmM"] })} user={props.user}  but={false} />
                                :null
                              }
                              {kpiIotype === "URL check" ? 
                                  <MyURLChecker kpi={JSON.stringify({iotype:kpiIotype, name:w.name, data2:[1] })} user={props.user} but={false}   />
                                :null
                              }
                              {/*<h3 className="grey">{w.name}</h3>*/}
                              </MDBCol>
                              {/*</div>*/}
                              </>
                            )}
                          </MDBRow>
                          </>
                          : <b>Keine Daten-Optionen für das ausgewählte Device verfügbar - entweder wählen Sie ein anderes Device oder erstellen Daten im Dashboard ! </b>}
                          </div>
                      : null }
                    </MDBCol>
                  </MDBRow>
                  </div>
                </MDBTabsPane>


                <MDBTabsPane show={fillActive === 'tab3'}>
                  <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">
                  <h1>Connectivity</h1>
                  <MDBRow>
                    <MDBCol md="4">
                      <h2>Typ</h2>
                      {connectTypes.map( (c,i) => <MDBRadio key={i} onClick={(e) => setConnectTypName(connectTypes[e.target.id].text)} name="connectTypOptions" id={c.value} label={c.text} defaultChecked={c.text === connectTypName}/>)}
                    </MDBCol>
                    <MDBCol>
                      {connectTypName === "LORA" ?
                          <>
                          <h2>LORA Parameter</h2>
                          {loraTypes.map( (l,i) => <MDBRadio key={i} onClick={(e) => setLoraTypName(loraTypes[e.target.id].text)} name="loraTypOptions" id={l.value} label={l.text} defaultChecked={l.text === loraTypName}/>)}
                          <br />
                          {loraBands.map( (b,i) => <MDBRadio key={i} onClick={(e) => setLoraBandName(loraBands[e.target.id].text)} name="loraBandOptions" id={b.value} label={b.text} defaultChecked={b.text === loraBandName}/>)}
                          </>
                      : null}
                    </MDBCol>
                    <MDBCol>
                      {connectTypName === "WLAN" || loraTypName === "gateway" ?
                        <>
                        <h2>WLAN Parameter</h2>
                        <MDBInput
                          input={"ssid"}
                          className="m-2 p-2"                  
                          label="SSID"
                          value={ssid}
                          type="text" 
                          onChange={(e) => setSsid(e.target.value)}
                        />
                        <MDBInput
                          input={"password"}
                          className="m-2 p-2"                  
                          label="Passwort" 
                          value={password}
                          type="text" 
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        </>
                        : null}
                      </MDBCol>
                    </MDBRow>
                  </div>
                </MDBTabsPane>

                <MDBTabsPane show={fillActive === 'tab4'}>
                  <MyWirer did={deviceIndex} />
                </MDBTabsPane>

                <MDBTabsPane show={fillActive === 'tab5'}>
                    <div className="tabcon shadow-4-strong rounded-6 p-4 ripple">

                    <div style={{color:"lightgrey"}}>
                    <MDBScrollbar style={{ textAlign:'left', position: 'relative', width: '100%', height: '500px' }}>
                    
                    <MDBBtn onClick={() => clipcopy()} className="btn btn-warning" style={{margin:"10px"}}>Copy to Clipboard</MDBBtn>

                    <code>
                    <pre>
                    <div id="clip">
                      <>
                      <div style={{color:"lightgrey"}} >
                        <CodeIntro_gen did={deviceIndex} devices={devices} device={device} />
                        <CodeInit_gen  did={deviceIndex} devices={devices} device={device} />
                        <CodeSetup_gen did={deviceIndex} devices={devices} device={device} />
                        {device.connectivity === "WLAN" ? <><CodeLoop_wlan did={deviceIndex} devices={devices} device={device} /></>:null}
                        {device.connectivity === "LORA" ? <><CodeLoop_lora did={deviceIndex} devices={devices} device={device} /></>:null}
                        <CodeRead_gen did={deviceIndex} devices={devices} device={device} />
                        <CodeWrite_gen did={deviceIndex} devices={devices} device={device} />
                        <CodeFunc_gen did={deviceIndex} devices={devices} device={device} />
                      </div>
                      </>
                    </div>
                    </pre>
                    </code>
                    </MDBScrollbar>
                    </div>
                  </div>
                </MDBTabsPane>

                <MDBTabsPane show={fillActive === 'tab6'}>
                  <ErrorHints nocon={false}/>
                </MDBTabsPane>
                
              </MDBTabsContent>
              }

              <div style={{textAlign:"right"}}>
              <MDBBtn onClick={() => handleDevice()} className="btn btn-info" style={{margin:"10px"}}>Speichern</MDBBtn>
              <MDBBtn onClick={() => props.closeme()} className="btn btn-dark" style={{margin:"10px"}}>Abbrechen</MDBBtn>
              </div>

      </MDBModal>
      
    </>
  );
}