import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import MyDash from './myDash';

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselElement,
  MDBCarouselCaption,
  MDBInput,
  MDBIcon
} from 'mdb-react-ui-kit';

import axios from 'axios';

import { Rating } from 'react-simple-star-rating'

import {linkits} from "./param"
import MyAbo from './myAbo';
import MyProjects from './myProjects';
import MyCaptcha from './myCaptcha';
import MyContact from './myContact';
import MyImpressum from './myImpressum';
import MyHomeProjectData from './myHomeProjectData';
import MyCoder from './myCoder';

import {deviceTypes, devices} from './param';

export default function MyHome(props) {

  const [showImpressum, setShowImpressum] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [showProjectData, setShowProjectData] = useState(false);
  const [deviceID, setDeviceID] = useState(null);
  const [projectID, setProjectID] = useState(null);
  
  const [allowemail, setAllowemail] = useState(false);

  const [search, setSearch] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [topics, setTopics] = useState([]);

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [topic, setTopic] = useState("Projekt");

  let top = useRef();
  let abo = useRef();
  let link = useRef();
  let contact = useRef();
  let projects = useRef();
  let device = useRef();
  
  const readTopics = () => {
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/gettopics?topic='+topic;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        setTopics(res.data.topics);
        const k = res.data.topics;
        console.log(k);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }   
  
  const warumCarouselneu = () => {
    return (
      <MDBContainer fluid ref={top} className="cool" style={{marginTop:'150px'}}>
        <h1 className="myheader">Willkommen bei IOTeasy</h1>
        <p className="mytext">Das Internet der Dinge (IOT internet of things) ist in aller Munde. Kaum ein Lebensbereich, in welchem nicht intelligente kleine Helfer eingesetzt werden. Am bekanntesten sind wohl die Lösungen im Bereich Smart Home. Storen werden von Wind und Sonne gesteuert; die Farbe der Wohnzimmer-Lampe kann durch Sprachsteuerung verändert werden; der Rasen wird gesprengt wenn der Boden trocken ist; Kameras überwachen das Haus und zeichnen bei Bewegung auf. Aber es gibt noch eine Vielzahl weiterer Anwendungen: So verraten GPS Sender den aktuellen Standort der Haustiere; Abstandssensoren melden den Füllstand von (Abfall)-Containern. Wärme- & Luftsensoren messen die Umwelt und schalten entsprechende Kühlungen an. Die Anwendungsgebiete sind nicht nur vielfältig, sondern eröffnen auch Ansätze zur Erschliessung neuer Geschäftsfelder. 
        Du interessierst Dich dafür wie das Internet der Dinge funktioniert ? Du möchtest das Zusammenspiel von Cloud, Microprozessoren und Sensoren verstehen und eigene Lösungen bauen ? Dann bist Du hier richtig!</p>
        <br />
        <hr />
        <h1 className="myheader">Warum IOTeasy ?</h1>
        <div className="mytext">
        <p><i style={{color:"cyan"}} className="far fa-check-circle"></i> <b>einfach</b> - keine bzw. wenig Programmier-Kenntnisse notwendig</p>
        <p><i style={{color:"cyan"}} className="far fa-check-circle"></i> <b>schnell</b> - in 10 Minuten ist die Lösung erstellt</p>
        <p><i style={{color:"cyan"}} className="far fa-check-circle"></i> <b>flexibel</b> - die Lösung kann spezifisch ausgebaut werden (Alarmierungen, Regeln, etc.)</p>
        <p><i style={{color:"cyan"}} className="far fa-check-circle"></i> <b>offen</b> - IOTeasy stellt eine offene API zur Verfügung</p>
        <p><i style={{color:"cyan"}} className="far fa-check-circle"></i> <b>kostenlos</b> - nur wenige Zusatzservices sind kostenpflichtig</p>
        </div>
      </MDBContainer>
    );    
  }  
  
  const warumCarousel = () => {
    return (
      <div style={{textAlign:"centeri"}}>
      <br />
      <div className="myheaderi">Warum IoTeasy?</div>
      <br />
      <p className="lead">IOTeasy stellt eine einfache, schnell zu erlernende IoT-Plattform zur Verfügung.</p>
      <br />
      <MDBCarousel showIndicators showControls fade>
        <MDBCarouselInner>
        
          <MDBCarouselItem className='active'>
            <MDBCarouselElement src='bgdash.jpg' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            <div  style={{textAlign:'center', height:200}}
               className="
                      bg-glass
                      shadow-4-strong
                      rounded-6
                      p-4
                      ripple
                      "
              >
              <h1 style={{color:"cyan"}} >
            		<i className="far fa-check-circle"></i> einfach !
              </h1>
              <h3>keine bzw. wenig Programmier-Kenntnisse notwendig</h3>
              </div>
            </MDBCarouselCaption>
          </MDBCarouselItem>
  
          <MDBCarouselItem>
            <MDBCarouselElement src='bgdash.jpg' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            <div  style={{textAlign:'center', height:200}}
               className="
                      bg-glass
                      shadow-4-strong
                      rounded-6
                      p-4
                      ripple
                      "
              >
              <h1 style={{color:"cyan"}}>
            		<i className="far fa-check-circle"></i> schnell !
              </h1>
              <h3>in 10 Minuten ist die Lösung erstellt</h3>
              </div>
            </MDBCarouselCaption>
          </MDBCarouselItem>
  
          <MDBCarouselItem>
            <MDBCarouselElement src='bgdash.jpg' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            <div  style={{textAlign:'center', height:200}}
               className="
                      bg-glass
                      shadow-4-strong
                      rounded-6
                      p-4
                      ripple
                      "
              >
              <h1 style={{color:"cyan"}}>
            		<i className="far fa-check-circle"></i> flexibel !
              </h1>
              <h3>die Lösung kann spezifisch ausgebaut werden (Alarmierungen, Regeln, etc.)</h3>
              </div>
            </MDBCarouselCaption>
          </MDBCarouselItem>

          <MDBCarouselItem>
            <MDBCarouselElement src='bgdash.jpg' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            <div  style={{textAlign:'center', height:200}}
               className="
                      bg-glass
                      shadow-4-strong
                      rounded-6
                      p-4
                      ripple
                      "
              >
              <h1 style={{color:"cyan"}}>
            		<i className="far fa-check-circle"></i> offen !
              </h1>
              <h3>IOTeasy stellt eine offene API zur Verfügung</h3>
              </div>
            </MDBCarouselCaption>
          </MDBCarouselItem>

        </MDBCarouselInner>
      </MDBCarousel>
      </div>
    );    
  }  
  
  const wieCarousel = () => {
    return (
      <MDBContainer fluid className="cool">
      <br />
      <div className="myheader">Wie funktioniert IoTeasy?</div>
      <br />
      <div className="mytext">IOTeasy besteht aus verschiedenen Komponenten. Die Nutzung ist kostenlos; für einige, wenige (SMS Alarmierung, Rule-Engine, höhere Datenvolumina & Datenaktualiserung) kann ein preiswertes ABO gelöst werden. 
      Mit dem Widget-Generator erstellt Du ein Widget, welches Daten in verschiedenen Formen darstellen kann. Da die Widgets über eine offene Schnittstelle verfügen kannst Du die Daten von überall speichern und abfragen. Den Widgets können entsprechende Alarmierungsfunktionen hinterlegt werden. Mit Hilfe des Regel-Generators kannst Du sogar Abhängigkeiten zwischen den Daten festlegen
      Der Programm-Generator schliesslich erzeugt für die unterstützten Devices den entsprechenden Programmcode zum Uploaden auf den Microprozessor. Entweder mit oder ohne IOTeasy Anbindung.</div>
      <br />
      <MDBCarousel showIndicators showControls fade>
        <MDBCarouselInner>
        
          <MDBCarouselItem className='active'>
            <MDBCarouselElement src='IOTeasy_dashboard.png' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            <div style={{textAlign:'center', height:200}}>
              <h1 style={{color:"cyan"}} >
            		<i className="fas fa-signal"></i> Dashboard
              </h1>
              <h3>Das Dashboard stellt vers. Widgets zur Verfügung, welche von den IOT-Devices gelesen bzw. geschrieben werden können </h3>
              </div>
            </MDBCarouselCaption>
          </MDBCarouselItem>
  
          <MDBCarouselItem>
            <MDBCarouselElement src='IOTeasy_alarm.png' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            <div  style={{textAlign:'center', height:200}}>
              <h1 style={{color:"cyan"}}>
            		<i className="far fa-bell"></i> Alarmierung
              </h1>
              <h3>Die Festlegung von Schwellwerten ermöglicht die Alarmierung über verschiedene Känale (Dashboard, eMail, SMS) </h3>
              </div>
            </MDBCarouselCaption>
          </MDBCarouselItem>

          <MDBCarouselItem>
            <MDBCarouselElement src='IOTeasy_rule.png' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            <div  style={{textAlign:'center', height:200}}>
              <h1 style={{color:"cyan"}}>
            		<i className="fab fa-gg-circle"></i> Regelassistent
              </h1>
              <h3>Der Regelassistent ermöglicht die Kombination der einzelnen IoT-Geräte zu einer Gesamtlösung</h3>
              </div>
            </MDBCarouselCaption>
          </MDBCarouselItem>

          <MDBCarouselItem>
            <MDBCarouselElement src='IOTeasy_devices.png' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            <div  style={{textAlign:'center', height:200}}>
              <h1 style={{color:"cyan"}}>
            		<i className="fas fa-microchip"></i> Devices
              </h1>
              <h3>Hier können verschiedene IoT-Devices, sowie deren Daten-Referenz definiert werden</h3>
              </div>
            </MDBCarouselCaption>
          </MDBCarouselItem>

          <MDBCarouselItem>
            <MDBCarouselElement src='IOTeasy_generator.png' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>

            <div  style={{textAlign:'center', height:200}}>
            <h1 style={{color:"cyan"}}>
            		<i className="fas fa-cog"></i> Code-Generator
              </h1>
              <h3>Mit dem Herzstück von IOTeasy kann der Programm-Code für den Microprozessor einfach generiert werden</h3>
              </div>

            </MDBCarouselCaption>
          </MDBCarouselItem>
  
        </MDBCarouselInner>
      </MDBCarousel>
      </MDBContainer>
    );    
  }  

  const deviceCarousel = () => {
    return (
      <MDBContainer fluid ref={device} style={{textAlign:"center", width:"80%"}}>
      <br/>
      <br />
      <br />
      <br />
      <br />
      <div className="myheader">Welche Devices werden von IoTeasy unterstützt?</div>
      <br />
      <div className="mytext">IOTeasy stellt für die aufgeführten Komponenten einen Programmcode zur einfachen Übernahme auf den Microprozessor zur Verfügung. Aktuell werden {devices.length} Sensoren/Aktoren unterstützt.</div>
      <br />
      <MDBCarousel showIndicators showControls fade>
        <MDBCarouselInner>
        
          {devices.filter(d => d.type!="Zubehör").map((d,i) =>
            <MDBCarouselItem key={i} className={i===0 ? 'active' : null}>
              <MDBCarouselElement src={"sensoren.jpg"} alt='...' className="img-fluid" width="400px" height="400px" />
              <MDBCarouselCaption>
              <div  style={{textAlign:'center', height:200}}
                 className="
                        bg-glassi
                        shadow-4-strong
                        rounded-6
                        p-4
                        ripple
                        "
                >
                <h1 style={{color:"cyan"}} >
              		<i className="fas fa-microchip"></i> 
              		{d.text + " "} 
                  {d.active==true ?
              		<MDBBtn onClick={() => codeShow(d.value)} className="btn btn-warning" style={{color:"black", backgroundColor:"cyan"}} > <i className="fas fa-cog"></i> Programmcode</MDBBtn>
              		:null}
                </h1>
                <MDBRow>
                  <MDBCol className="col-md-3">
                    <img src={d.picture} height="100px" />
                  </MDBCol>
                  <MDBCol>
                    <h3>{d.desc}</h3>
                  </MDBCol>
                </MDBRow>
              </div>
              </MDBCarouselCaption>
            </MDBCarouselItem>
          )}

        </MDBCarouselInner>
      </MDBCarousel>
      </MDBContainer>
    );    
  }  
  
  const showDevices = () => {
      return(
        <MDBContainer className="cool" fluid ref={device} >
        <br />
        <br />
        <div className="myheader">Welche Devices werden von IoTeasy unterstützt?</div>
        <br />
        <div className="mytext">IOTeasy stellt für die aufgeführten Komponenten einen Programmcode zur einfachen Übernahme auf den Microprozessor zur Verfügung. Aktuell werden {devices.length} Sensoren/Aktoren unterstützt.</div>
        <br />
        <MDBRow>
        {devices.filter(d => (d.type!=="Zubehör"&&d.type!=="Microprozessor")).map((d,i) =>
        <MDBCol key={i} sm="12" md="12" lg="4" xl="3" xxl="3">
          <div  style={{textAlign:'center', height:450}}
             className="
                    bg-glass
                    shadow-4-strong
                    rounded-6
                    p-4
                    ripple
                    "
            >
            <img
              src={d.picture}
              width="120px"
              height="120px"        
              objectfit="cover"
            />
            <br />
            <br />
            <h3>
              {d.name}
            </h3>
            <div className="p-1">
              <div className="yellow mid">
                {d.text}<br />
              </div>
              <p>{d.desc}</p>
            </div>
            <div className="kachelbtn">
              {d.active ?
                <div onClick={() => codeShow(d.value)} style={{position:"absolute", bottom:30, right:30}}><i className="fas fa-microchip p-2"></i></div>
              :
                <div style={{position:"absolute", bottom:30, right:30}}><i className="fas fa-hammer p-2 red"></i></div>
              }
            </div>
          </div>
        </MDBCol>
        )}
        </MDBRow>
        </MDBContainer>
      )
  }

  const projectCarousel = () => {
    return (
      <MDBContainer fluid ref={projects} className="cool">
      <div className="myheader">Community Projekte</div>
      <div className="mytext">Hier findest Du eine kleine Sammlung von Ideen für Dein nächstes 'maker'-Projekt - lass Dich inspirieren...</div>
      <br/>
      <MDBCarousel showIndicators showControls fade style={{width:'50%', margin:'auto'}}>
        <MDBCarouselInner>
        
          {topics.map((t,i) =>
            <MDBCarouselItem key={i} className={i==0 ? 'active' : null}>
              <MDBCarouselElement src={t.imgs.length === 0 ? "iotprojekte.jpg" : t.imgs[0]} alt='...' className="img-fluid" height="500px" />
              <MDBCarouselCaption>

              <div  className="cool" style={{textAlign:'center', height:200}}>
                <h4>
                  {t.name}
                </h4>
                <p className="cyan">
                  {user !== null ?
                    <>{t.owner} </>
                  : 
                    <>IOTeasy User </>
                  }
                  {t.created_at+ " "}
                  {t.cls.map((c,i) => <React.Fragment key={i}> {c.atype} [{c.anz}] </React.Fragment>)}
                </p>
                <Rating onClick={() => alert("rate...")} ratingValue={t.rating.toFixed(1)/5*100} readonly={true} fillColor={"rgba(50, 200, 200, 0.8)"} /* Available Props */ />/{t.ratanz}
                {user !== null ?
                  <div className="kachelbtn">
                    <div onClick={() => projectShow(t.id)} style={{position:"absolute", bottom:30, right:30}}><i className="fas fa-info p-2"></i></div>
                  </div>
                : null}
              </div>

              </MDBCarouselCaption>
            </MDBCarouselItem>
          )}

        </MDBCarouselInner>
      </MDBCarousel>
      </MDBContainer>
    );    
  }  

  const loginF = (mode) => {
    props.login(mode);
  }
  
  const openUrl=(url) => {
    window.open(url, "_blank");
  }

  useEffect(() => {
    readTopics();
  },[])

  useEffect(() => {
    //alert(props.user)
    if(props.user !== null){
      setUser(JSON.parse(props.user));
    }
  },[props.user])
  
  useEffect(() => {
    //console.log(props.target);
    if(props.target==="PROJECTS")
      projects.current.scrollIntoView();
    if(props.target==="ABO")
      abo.current.scrollIntoView({behavior: 'smooth'});
    if(props.target==="TOP")
      top.current.scrollIntoView();
    if(props.target==="LINK")
      link.current.scrollIntoView();
    if(props.target==="CONTACT")
      contact.current.scrollIntoView();
    if(props.target==="DEVICES")
      device.current.scrollIntoView();
  },[props.target]);  
  
  const setCaptcha = (rc) => {
    ReactDOM.render("", document.getElementById('modal'));
    if(rc=="OK") {
      //showSendMail();
    }
  }

  const impressumShow = () => {
    window.scrollTo(0,0);
    setShowImpressum(true);
  }
  const impressumClose = () => {
    setShowImpressum(false);
  }

  const contactShow = () => {
    window.scrollTo(0,0);
    setShowContact(true);
  }
  const contactClose = () => {
    setShowContact(false);
  }

  const projectShow = (id) => {
    window.scrollTo(0,0);
    setProjectID(id);
    setShowProjectData(true);
  }
  const projectClose = () => {
    setProjectID(null);
    setShowProjectData(false);
  }

  const codeShow = (deviceid) => {

    let index = 0;
    for(let j=0; j<devices.length; j++) {
      if (devices[j].value===deviceid) {
        index=j;
        break;
      }
    }
    //alert(index);

    setDeviceID(index);
    window.scrollTo(0,0);
    setShowCode(true);
  }

  const codeClose = () => {
    setDeviceID(null);
    setShowCode(false);
  }

  const homepagestyle = {
    width:"100%!important",
    color:"lightgrey",
    marginTop:"100px",
    textAlign:"center"
  }

  return (
    <>
      {warumCarouselneu()}
      <hr />
      {wieCarousel()}
      <hr />
      {showDevices()}
      {/*<hr />
      {deviceCarousel()}
      */}
      <hr />
      {projectCarousel()}
      <hr />

      <br ref={abo} />
      {<MyAbo user={null} />}

      <MDBContainer fluid className="cool" ref={link}>
      <div className="myheader">Nützliche Links</div>
      <br />
      <MDBRow>
      {linkits.map(l => 
          <MDBCol key={l.key} sm="12" md="12" lg="6" xl="4" xxl="">
            <div  style={{textAlign:'center', height:400}}
               className="
                      bg-glass
                      shadow-4-strong
                      rounded-6
                      p-4
                      ripple
                      "
              >
              <h3>
                {l.name}
              </h3>
              <img
                src={l.picture}
                //class="img-fluid"
                width="100px"
                height="100px"        
                //maxwidth="50%"
                //height="auto"
                objectfit="cover"
              />
              <br />
              <hr />
              <div className="p-1">
                <p style={{color:'cyan'}}>{l.desc}</p>
              </div>
              
              <div className="kachelbtn">
                <div onClick={() => openUrl(l.link)} style={{position:"absolute", bottom:30, right:30}}><i className="fas fa-link p-2"></i></div>
              </div>
              
            </div>
          </MDBCol>)}
        </MDBRow>
        </MDBContainer>
      <br />
      
      <hr />
        <MDBContainer fluid ref={contact} className="cool" style={{width:'100%', marginBottom:0}}>
          <MDBRow>
            <MDBCol >
              <h4>Kontakt</h4>
              <MDBIcon style={{cursor:"pointer"}} onClick={() => contactShow()} className="m-1" size="3x" fas icon="envelope"/>
            </MDBCol>
            <MDBCol>
              <h4>Standort</h4>
              <MDBIcon className="m-1" size="3x" fas icon="map-marker"/>
              <p>
                Hörnliblick 11
                <br/>
                9556 Zezikon</p>
            </MDBCol>
            <MDBCol>
              <h4>Im Web</h4>
                <a style={{color:"lightgrey"}} href="https://www.facebook.com/IOTeasy.ch" target="_blank"><MDBIcon className="m-1" size="3x" fab icon="facebook"/></a>
                <MDBIcon className="m-1" size="3x" fab icon="twitter"/>
                <MDBIcon className="m-1" size="3x" fab icon="linkedin"/>
                <MDBIcon className="m-1" size="3x" fab icon="dribbble"/>
            </MDBCol>
            <MDBCol>
              <h4>Impressum</h4>
                {/*<MDBIcon style={{cursor:"pointer"}} onClick={() => props.menu("HOME", "IMPRESSUM")} className="m-1" size="3x" fas icon="print"/> */}
                <MDBIcon style={{cursor:"pointer"}} onClick={() => impressumShow()} className="m-1" size="3x" fas icon="print"/>
            </MDBCol>
        </MDBRow>
        <hr />
        <small>Copyright &copy; IOTeasy 2022</small>
      </MDBContainer>
      
      {showContact ? <MyContact show={showContact} closeme={contactClose} user={user} /> : null }
      {showImpressum ? <MyImpressum show={showImpressum} closeme={impressumClose} /> : null }
      {showCode ? <MyCoder did={deviceID} devices={devices} showme={showCode} closeme={codeClose} /> : null }
      {showProjectData ? <MyHomeProjectData user={props.user} showme={showProjectData} closeme={projectClose} project={projectID} /> :null}

    </>
  );
}