import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MDBContainer, MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

import MyDialogRule from './myDialogRule';

import axios from 'axios';
//import MyYesNoBtn from './myYesNoBtn';
//import MyDummy from './myDummyM';

export default function MyRules (props) {

  const [rules, setRules] = useState([]);
  const [crits, setCrits] = useState([]);
  const [actions, setActions] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  const [showRuleDialog, setShowRuleDialog] = useState(false);
  const [ruleID, setRuleID] = useState(null);

  //life-cycle hooks
  useEffect(() => {
    readRules();
    const timer = setInterval(() => {
       readRules();
    }, 30000);
    
    return () => {
      clearInterval(timer);
    }    
    
  },[]);
  
  const readRules = () => {
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/rules?key='+props.user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        setRules(res.data.rules);
        setCrits(res.data.crits);
        setActions(res.data.ruleactions);
        const k = res.data.rules;
        console.log("Rules...."+k);
        const c = res.data.crits;
        console.log(c);
        const r = res.data.ruleactions;
        console.log(r);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }  
  
  const showDialog = (id) => {
    setRuleID(id);
    setShowRuleDialog(true);
  }
  
  const hideDialog = () => {
    readRules();
    setShowRuleDialog(false);
  }
  
  const deleteRule = (id, name) => {
    if(window.confirm("Soll " + name + " wirklich gelöscht werden?")) {
      const killString = 'https://ioteasybackend.herokuapp.com/api/v1/rules/' + id;
      console.log(killString);
      axios.delete(killString)
        .then(res => {
          console.log(res.data);
          readRules();
          //setKillID(0);
          //this.setState({ isloading: true})
        })
        .catch((error) => {
          // Error
          console.log("error deleting data...");
          if (error.res) {
            console.log(error.res);
          }
        })
    }
    //ReactDOM.render("", document.getElementById('modal'));
  }
  
    return(
    <React.Fragment>
    <MDBContainer fluid className="cool" style={{width:"100%", marginTop:"75px"}}>
      {isloading ? 
        <div className="loader">
          <MDBIcon className="fa-10x" spin fas icon='spinner' />
          <br /><br />
          <h1>loading Rules...</h1>
        </div> 
      :
          <>
          <div className="myheader">Regelübersicht{" "}
            {props.user.name !== "IOTeasy" ?
              <MDBBtn onClick={() => showDialog(null)} className="btn btn-lg btn-danger">{" "} Neue Regel</MDBBtn> 
            :
              <MDBBtn onClick={() => alert("disabled in DEMO mode")} className="btn btn-lg btn-danger">{" "} Neue Regel</MDBBtn> 
            }
          </div>

          <MDBTable className="white" style={{color:"#A0A0A0", textAlign:"left"}}>
          <MDBTableHead>
            <tr>
              <th scope='col'>aktiv</th>
              <th scope='col'>Status</th>
              <th scope='col'>Name</th>
              <th scope='col'>Typ</th>
              <th scope='col'>Wert</th>
              <th scope='col'>wenn</th>
              <th scope='col'>dann</th>
              <th scope='col'>ausgeführt</th>
              <th scope='col'>zurückgesetzt</th>
              <th colSpan="2" scope='col'>Aktionen</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {rules.map((r,i) => 
                <tr key={i}>
                  <td>
                    {r.active ? 
                      <MDBIcon onClick={() => alert("")} icon='check' size="2x" className="green big" />
                    : 
                      <MDBIcon onClick={() => alert("")} icon='ban' size="2x" className="red big" />
                    }
                  </td>
                  <td>
                    {r.status == "working" ?
                      <MDBIcon size="3x" className="red big" spin fas icon='spinner' />
                      :
                      <MDBIcon size="3x" className="yellow big" fas icon='pause' />
                    }
                  </td>
                  <td>
                    {r.name}
                  </td>
                  <td>
                    {r.rtype}
                  </td>
                  <td className="yellow">
                    {r.sourceval}
                  </td>
                  <td>
                    {r.rtype=="Daten" ?
                      <>
                        {r.source} {r.compareoperator} {r.comparevalue}
                      </>
                    :
                      <>
                        {r.date_from} {r.time_f} bis <br /> {r.date_to} {r.time_t}
                      </>
                    }
                  </td>
                  <td>
                    {r.target} = {r.targetvalue}
                    {r.perstype=="temporär" ?
                      <>
                        <br/>
                        Rücksetzen auf {r.restorevalue} nach {r.duration} Minuten
                      </>
                    :null}
                  </td>
                  <td>
                    {r.lastexecution}
                  </td>
                  <td>
                    {r.perstype=="temporär" ? r.lastrestore :null}
                  </td>
                  <td>
                    <MDBIcon onClick={() => showDialog(r.id)} style={{cursor:"pointer"}} icon='wrench'/>
                  </td>
                  <td>
                    <MDBIcon onClick={() => deleteRule(r.id, r.name)} style={{cursor:"pointer"}} icon='trash' />
                  </td>
                </tr>
                )}
            </MDBTableBody>
          </MDBTable>
          {actions.map((r,i) =>
            <p key={i}>{r}</p>
          )}
        </>
      }
    </MDBContainer>
    {showRuleDialog ?
      <MyDialogRule user={props.user} id={ruleID} showme={showRuleDialog} closeme={hideDialog} crits={crits} />
      :null
    }
    </React.Fragment>
)
}
