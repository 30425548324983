import React, { useState, useEffect } from 'react';
import { MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBInputGroup,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCheckbox,
  MDBAccordion, 
  MDBAccordionItem,
  MDBRadio,
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
  MDBStepperContent,
  MDBValidation,
  MDBIcon,
  MDBScrollbar
} from 'mdb-react-ui-kit';

//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export default function MyCaptcha(props){
  /*
  const [staticModal, setStaticModal] = useState(true);
  const toggleShow = () => setStaticModal(!staticModal);

  const [ver, setVer] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
  loadCaptchaEnginge(6) ;
  },[])

  const setVerCode = () => {
    if (validateCaptcha(code)==true) {
      setVer(true);
      props.setCaptcha("OK", props.mode);
    }  
  }

  const closeWin=() => {
    setStaticModal(false);
    props.setCaptcha("cancel");
  }
  
  return (
      <MDBModal className="bg-glasss centernewi" staticBackdrop show={staticModal} getOpenState={(e) => setStaticModal(e)} >
        <MDBIcon className='bclose' onClick={() => closeWin()} fas icon="times" size="2x" />
        <br/>
        <h2>Ich bin kein Roboter...</h2>
        <br/>
        <MDBContainer className="tabconi" >
            
              <div style={{textAlign:"center"}}>
                <LoadCanvasTemplate />
                <div className="m-3 p-3">
                  <form>
                  <MDBInput 
                    value={code}
                    className=""
                    type="text" 
                    label="bitte o.g. Code eingeben" 
                    onChange={(e) => setCode(e.target.value)} 
                    />
                  </form>
                </div>
                <MDBBtn onClick={() => setVerCode()} className="btn btn-info">OK</MDBBtn>
              </div>
        </MDBContainer>

      </MDBModal>
     
  );
  */
  
}