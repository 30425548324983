import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { MDBRow, MDBCol, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon,   MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselElement, MDBCarouselCaption, MDBContainer } from 'mdb-react-ui-kit';

import {deviceTypes, devices} from './param';
import MyCoder from './myCoder';
//import MyWirer from 'myWirer';
import MyWizard from './myWizard';
import MyDummy from './myDummyM';

export default function AllDevices(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [showCode, setShowCode] = useState(false);
  const [showGenerator, setShowGenerator] = useState(false);

  const [Dtype, setDtype] = useState(null);
  const [did, setDid] = useState(null);

  const DoShowCode = (text, id) => {
    setDtype(text);
    
    let index = 0;
    for(let j=0; j<devices.length; j++) {
      if (devices[j].value===id) {
        index=j;
        break;
      }
    }
    setDid(index);
    setShowCode(true);
  }

  const DoShowGenerator = (text, id) => {
    setDtype(text);
    let index = 0;
    for(let j=0; j<devices.length; j++) {
      if (devices[j].value===id) {
        index=j;
        break;
      }
    }
    setDid(index);
    setShowGenerator(true);
  }

  const closeShowCode = () => {
    setShowCode(false);
  }

  const closeShowGenerator = () => {
    setShowGenerator(false);
  }

  const genDevices = (typ) => {
    return(
        devices.filter(d => d.type===typ).map((d, i) => 
          <MDBCol key={i} sm="12" md="12" lg="4" xl="3" xxl="3">
            <div  style={{textAlign:'center', height:500}}
               className="
                      bg-glass
                      shadow-4-strong
                      rounded-6
                      p-4
                      ripple
                      "
              >
              <img
                src={d.picture}
                //class="img-fluid"
                width="200px"
                height="200px"        
                //maxwidth="50%"
                //height="auto"
                objectfit="cover"
              />
              <br />
              <br />
              <h3>
                {d.name}
              </h3>
                {d.active ?
                  <p className="yellow">{d.text}</p>
                :
                  <p className="red">{d.text}</p>
                }
              <hr />
                {d.active === true && d.type !== "Zubehör" ?
                  <div>
                    <div style={{bottom:0}} className="kachelbtn" onClick={() => DoShowCode(d.text, d.value)} ><i className="fas fa-code p-2"></i> Basic Generator</div>
                    {props.user ? 
                      <div style={{bottom:-20}} className="kachelbtn" onClick={() => DoShowGenerator(d.text, d.value)} ><i className="fas fa-cloud p-2"></i> Cloud Generator</div>
                    :
                      <>
                      <div style={{bottom:-20}} className="kachelbtninactive"><i className="fas fa-cloud p-2"></i> Cloud Wizard 
                      <p style={{fontSize:"0.5em"}}>(Login erforderlich)</p>
                      </div>
                      </>
                    }
                  </div>
                : 
                  <div>
                    <div style={{bottom:0}} className="kachelbtn">not supported yet</div>
                  </div>
                }
                <div className="kacheltext" style={{top:400}}>
                  <p>{d.desc}</p>
                </div>

            </div>
          </MDBCol>
      )
    );
  }

  const myCarousel = () => {
    return (
      <MDBCarousel showIndicators showControls fade>
        <MDBCarouselInner>

          <MDBCarouselItem className='active'>
            <MDBCarouselElement src='IOTeasy_generator.png' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>

              <div  style={{textAlign:'center', height:200}}
                 className="
                        bg-glass
                        shadow-4-strong
                        rounded-6
                        p-4
                        ripple
                        "
                >
                <h1 style={{color:"cyan"}}>
              		<i className="fas fa-code"></i> Basic Wizard
                </h1>
                <h2>Unterstützt bei der Basis-Programmierung (Libraries, Verkabelung, Fehlermeldungen) zur Nutzung von Sensoren und Aktoren generieren - ganz einfach !</h2>
                </div>

            </MDBCarouselCaption>
          </MDBCarouselItem>
  
          <MDBCarouselItem>
            <MDBCarouselElement src='IOTeasy_generator.png' alt='...' className="img-fluid" height="500px" />
            <MDBCarouselCaption>
            
              <div  style={{textAlign:'center', height:200}}
                 className="
                        bg-glass
                        shadow-4-strong
                        rounded-6
                        p-4
                        ripple
                        "
                >
                <h1 style={{color:"cyan"}}>
              		<i className="fas fa-cloud"></i> Cloud Wizard
                </h1>
                <h2>Erstellt eine komplette IoT Cloud-Anwendung (Dashboard, Alarmierung, Regelwerk) - ganz einfach !</h2>
                <p>zur Nutzung des Cloud-Generator ist eine Registrierung notwendig</p>
              </div>
            
            </MDBCarouselCaption>
          </MDBCarouselItem>
  
        </MDBCarouselInner>
      </MDBCarousel>
    );    
  }

    return(
      <MDBContainer fluid className="cool" style={{width:"100%", marginTop:"75px"}}>
        <div className='myheader'>
            Device Generator
        </div>
        {/* myCarousel() */}
        {deviceTypes.map((t,i) => 
          <div key={i}>
            <div style={{ fontSize:'3em', padding: '20px' }} className='text-center'>
              {" " + t.text}
            </div>
            <MDBRow>
              {genDevices(t.text)}
            </MDBRow>
          </div>
        )}
        
        {showCode ? <MyCoder did={did} devices={devices} device={{loramode:"", loraband:"", connectivity:"WLAN", ssid:"myHome", password:"mylittlepw"}} showme={showCode} closeme={closeShowCode} /> :null};

        {showGenerator ? <MyWizard user={props.user} did={did} devices={devices} device={{loramode:"", loraband:"", connectivity:"WLAN", ssid:"myHome", password:"mylittlepw"}} showme={showGenerator} closeme={closeShowGenerator} showDevs={props.showDevs} /> :null}

        </MDBContainer>
    );

}
