import { MDBContainer } from 'mdb-react-ui-kit';

export default function CodeLoop_gen(props) {
  
const pinReads = (read, print) => {
  return(
    <>
    {props.devices[props.did].pins.map((p,i) => 
      <div key={i}>
        <>
          {read ? <>{p.pintype} {p.pinvar} = {p.pinread}({p.piname});<br /></>:null}
          {print ? <>Serial.println("{p.pinvar}: " + String({p.pinvar})); <br /></>:null}
        </>
      </div> 
    )}
    </>
    );
}  

const calcReads = () => {
  return(
    <>
    {props.devices[props.did].calcs ?
      <>
      {props.devices[props.did].calcs.map((c,i) => 
        <div key={i}>
          <>
            {c.calc}<br />
          </>
        </div> 
      )}
      </>
    :null}
    </>
  );
}  

const pinLoops =() => {
  return(
    <>
    {props.devices[props.did].loops.map((s,i) =>
        <div key={i}>
            <>
                {s.loop};< br />
            </>
        </div>
    )}
    </>
  )
}

const loraSend = () => {
  return(
    <>
      <br />
      // Node sending to Gateway ...<br />
      DynamicJsonDocument doc(2000);<br />
      String myOutput = ""; <br />
      doc["kpi"] = {props.device.kpi_id};<br />
      {props.devices[props.did].labels.map((l,i) => 
        <>
        doc["{l}"] = {l};<br />
        </>
      )}
      serializeJson(doc, myOutput); <br />
      Serial.println(myOutput);<br/>
      LoRa.beginPacket(); <br/>
      LoRa.print( myOutput );<br/>
      LoRa.endPacket();  <br/>
    
      <br />
      // Display Update <br />
      display.clearDisplay();<br/>
      display.setCursor(0,0);<br/>
      display.print("sending...");<br/>
      display.setCursor(0,10);<br/>
      display.print(String(myOutput));<br/>
      display.display();<br/>
    
    </>
  )
}

const loraReceive = () => {
  return(
    <div className="tab1">
      int packetSize = LoRa.parsePacket(); < br/>
      if (packetSize) {"{ cbk(packetSize); }"} <br />
    </div>
  )
}

const lora2IOTeasy = () => {

  let paramstring = "";
  let desiarray = [];
  for(let i=0; i<props.device.crits.length;i++) {
    if(i>0) {
      paramstring = paramstring + ", ";
    }
    paramstring = paramstring + props.device.crits[i].name;
  }
  for(let i=0; i<props.devices[props.did].labels.length;i++) {
    desiarray.push("");
    if(props.device.crits[i].typ==="Alphanumerisch") {
      desiarray.push("String " + props.device.crits[i].name + '= doc["' + props.device.crits[i].name + '"];');
    }
    if(props.device.crits[i].typ==="Numerisch") {
      desiarray.push("float " + props.device.crits[i].name + '= doc["' + props.device.crits[i].name + '"];');
    }
  }

  return(
    <>
      DynamicJsonDocument doc(2000);<br />
      DeserializationError err = deserializeJson(doc, packet);<br/>
      if(!err){"{"}<br/>
        <div className="tab2">
          // data <br />
          {desiarray.map((l,i) => 
            <>{l}<br /></>
          )}
          // check KPI <br />
          if({props.device.kpi_id} == doc["kpi"]) {"{"}<br />
            write_{props.device.kpi_id}({paramstring});<br />
          {"}"}
        </div>
      {"}"}<br />
    </>
  )
}

const lora2node = () => {
  return(
      <>
      // Gateway Read <br />
      DynamicJsonDocument doc(2000);<br/>
      DeserializationError err = deserializeJson(doc, packet);<br/>
      {props.device.crits.map((c,i) => 
          <div>
              <br/>
                  {c.typ === "Numerisch" ? 
                  <>
                      float {c.name} = doc["{c.name}"];<br/>
                  </> : null}
                  {c.typ === "Alphanumerisch" ?
                  <>
                      const char* {c.name} = doc["{c.name}"];<br/>
                      char {c.name}c [100] = {"{}"};<br />
                      String {c.name}s = String({c.name});<br />
                      {c.name}s.toCharArray({c.name}c, 100);<br />
                  </> : null}
          </div>
      )}
      // Node Action <br />
      {props.devices[props.did].reads.map((r,i) =>
        <div key={i}>
            <>
                {r.read}< br />
            </>
        </div>
      )}
      </>
  )
}

    return(
<MDBContainer className="lightgrey">
// ********************************************************************************************<br />
<h1>// Loop </h1>
// ********************************************************************************************<br />
void loop() {"{"}<br />

<div className="tab1">

{props.device.loramode === "node" ?
<>
{props.devices[props.did].type === "Sensor" && props.devices[props.did].controls.trigger === true ?
  <>
    {pinReads(true,false)}
    {calcReads()}
    if(wert {"!="} wertsave) {"{"}<br/>
    <div className="tab2">
      {pinReads(false,true)}
      {pinLoops()}
      {props.device !== null ? loraSend() :null}
      wertsave = wert;<br/>
    </div>
    {"{"}<br />
  </>
:null
}

{props.devices[props.did].type === "Sensor" && !props.devices[props.did].controls.trigger ?
  <>
  if(millis() {">"} millisave + delays)  {"{"}<br/>
    <div className="tab2">
      {pinReads(true,true)}
      {pinLoops()}
      {props.device !== null ? loraSend() :null}
      millisave = millis();<br/>
    </div>
  {"}"}<br />
  </>
:null
}

{props.devices[props.did].type === "Aktor" ?
  <div className="tab1">
    {props.device !== null ? loraReceive() :null}
  </div>
:null
}
</>
:null}

{props.device.loramode === "gateway" ?
<>

{props.devices[props.did].type === "Sensor" ?
<>
  {loraReceive()}
</>
:
<>
<div className="tab1">
if(millis() {">"} millisave + delays)  {"{"}<br/>
  read_{props.device.kpi_id}();<br />
</div>
{"{"}<br />
</>
}


</>
:null}

</div>
{"}"}<br />

{(props.device.loramode === "gateway" && props.devices[props.did].type === "Sensor") || (props.device.loramode === "node" && props.devices[props.did].type === "Aktor") ?
<>
<br />
void cbk(int packetSize) {"{"}
<div className="tab1">
  // read LORA <br />
  String packet ="";<br />
  String packSize = String(packetSize,DEC);<br />
  for (int i = 0; i {"<"} packetSize; i++) {"{"}<br /> 
    packet += (char) LoRa.read(); <br />
  {"}"}<br />
  String rssi = String(LoRa.packetRssi(), DEC) ;<br />
  <br />
  // Serial Output <br />
  Serial.print("Signal:");<br />
  Serial.println(rssi);<br />
  Serial.println(packet);<br />
  <br />
  // Display Output <br />
  display.clearDisplay();<br />
  display.setCursor(0,0);<br />
  display.print("Signal..."+rssi);<br />
  display.setCursor(0,10);<br />
  display.print("receiving...");<br />
  display.setCursor(0,20);<br />
  display.print(packet);<br />
  display.display();<br />
  <br />  
  {props.device.loramode === "gateway" && props.devices[props.did].type === "Sensor" ?
  <>
    {lora2IOTeasy()}
  </>
  :null}
  {props.device.loramode === "node" && props.devices[props.did].type === "Aktor" ?
  <>
    {lora2node()}
  </>  
  :null}
  <br/>
  
</div>
{"}"}
</>
:null}

</MDBContainer>
)}

