import React, { useState, useEffect } from 'react';
import { 
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBIcon,
  MDBInput
} from 'mdb-react-ui-kit';

import {devices, deviceTypes, deviceCats, connectTypes, loraTypes, loraBands, dashOptions} from './param';

import axios from 'axios';

export default function MyWizard(props) {
  
  const [staticModal, setStaticModal] = useState(true);

  const [step, setStep] = useState(1);

  const [kpimode, setKpimode] = useState("generate");

  const [iotype, setIotype] = useState(null);
  const [kpi, setKpi] = useState(null);

  const [kpis, setKpis] = useState([]);
  
  const [connect, setConnect] = useState("WLAN");
  const [band, setBand] = useState(868);

  const [ssid, setSsid] = useState("WLAN0815");
  const [password, setPassword] = useState("password");
  const [device, setDevice] = useState("");
  const [frequency, setFrequency] = useState(30);

  useEffect(() => {
    readMatchingKpis();
  }, [props.did]);
  
  const readMatchingKpis =() => {
    let typs=[]
    for(let i=0; i<devices[props.did].dashoptions.length; i++) { 
      typs.push(dashOptions[devices[props.did].dashoptions[i]].text)
    }
    //alert(typs);
    //const readString = 'https://tgiot.herokuapp.com/api/v1/dashboards/matchingkpis?iotypes='+typs+"&key="+props.user.userkey;
    const readString = 'https://ioteasybackend.herokuapp.com/api/v1/dashboards/matchingkpis?iotypes='+typs+"&key="+props.user.userkey;
    console.log(readString);
    axios.get(readString)
      .then(res => {
        console.log(res.data);
        setKpis(res.data.kpis);
      })
      .catch((error) => {
        // Error
        console.log("error reading data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }
  
  const wizard = () => {
    if(kpimode=="generate") {
      createKpi();
    }
    if(kpimode=="choose") {
      createDevice(kpi);
    }
  }
    
  const createDevice = (kpi_id) => {
      // CREATE DEVICE
      var bodyFormData = new FormData();
  
      var bodyFormData = new FormData();
      bodyFormData.append('kpi_id', kpi_id);
      bodyFormData.append('dtype', props.devices[props.did].text);
      //alert(props.devices[props.did].value);
      bodyFormData.append('dtypenr', props.devices[props.did].value);
      bodyFormData.append('name', device);
      bodyFormData.append('connectivity', connect);
      bodyFormData.append('loramode', "Node");
      bodyFormData.append('loraband', band);
      bodyFormData.append('ssid', ssid);
      bodyFormData.append('password', password);
      bodyFormData.append('frequency', frequency);
  
      axios({
        method: "post",
        //url: "https://tgiot.herokuapp.com/api/v1/devices/",
        url: "https://ioteasybackend.herokuapp.com/api/v1/devices/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        //handle success
        props.closeme();
        //props.showDevs();
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  
  }
    
  const createKpi = () => {
    // CREATE KPI
    var bodyFormData = new FormData();

    bodyFormData.append('user_id', props.user.id);
    bodyFormData.append('name', "DATA_"+device);
    bodyFormData.append('gruppe', "IOTeasy");
    bodyFormData.append('ktype', "Value");
    bodyFormData.append('iotnumber', "12345");
    //bodyFormData.append('iotype', props.devices[props.did].dash);
    bodyFormData.append('iotype', iotype);

    let label = "";
    for(let i=0; i<props.devices[props.did].labels.length; i++) {
      //label = label + props.devices[props.did].labels[i];
      if(i!=0) {
        label = label + ", ";
      }
      label = label + props.devices[props.did].labels[i];
    }
    bodyFormData.append('label', label);

    bodyFormData.append('keepval', 5);
    bodyFormData.append('smartwatch', false);

    axios({
      method: "post",
      //url: "https://tgiot.herokuapp.com/api/v1/kpis/",
      url: "https://ioteasybackend.herokuapp.com/api/v1/kpis/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success

        console.log(response);
        let newkpi = response.data.id;
        createDevice(newkpi);

    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });

  }
  
  const dataref = (id) => {
    if(kpimode=="generate"){
      setIotype(id)
    }
    if(kpimode=="choose"){
      setKpi(id)
    }
    setStep(3);
  }

  const stepCount = (mode) => {
    if(mode=="up") {
      if(step<6) {
        setStep(step+1);
      }
    }
    if(mode=="down") {
      if(step>1) {
        setStep(step-1);
      }
    }
  }
  
  const setConnectivity = (mode) => {
    setConnect(mode);
    if(mode=="LORA") {
     setDevice("LORA_D_" + devices[props.did].name);
    }
    if(mode=="WLAN") {
     setDevice("WLAN_D_" + devices[props.did].name);
    }
    setStep(4);
  }
  
  const showStep = () => {
    return(
      <h1>
        {step >1 ? <span><MDBIcon onClick={() => stepCount("down")}  fas icon="caret-left" /></span> :null}
        Schritt {step} 
        {step <4 ? <span><MDBIcon onClick={() => stepCount("up")}  fas icon="caret-right" /></span> :null}
      </h1>
      )
  }

  return (
      <div className="bg-glasst" staticBackdrop show={props.showme}  >
        <MDBIcon className='bclose' onClick={() => props.closeme()} fas icon="times" size="2x" />
        <br />
        <div className="tabcon">

              <h1>Mit dem Wizard erstellst Du ganz einfach eine komplette IoT Anwendung für {devices[props.did].name}</h1>
              <hr />

              {props.user !== null ? 
              <div style={{textAlign:"center"}}>
                <img
                  src={devices[props.did].picture}
                  //class="img-fluid"
                  width="200px"
                  height="200px"        
                  //maxwidth="50%"
                  //height="auto"
                  objectfit="cover"
                />
                {showStep()}
                {step === 1 ?
                  <>
                    <h2>Soll IOTeasy eine Dashboard-Komponente erstellen oder möchtest Du eine bereits bestehende Komponente nutzen ?</h2>
                    <MDBBtn onClick={() => {setKpimode("generate");stepCount("up")}} className="btn btn-info">Erstellen</MDBBtn>
                    {kpis.length > 0 ? <MDBBtn onClick={() => {setKpimode("choose");stepCount("up")}} className="btn btn-light">Auswählen</MDBBtn> :null}
                  </>
                :null
                }
                
                {step === 2 ?
                  <>
                    {kpimode === "choose" ?
                    <>
                    <h2>Welches Dashboard-Widget soll verwendet werden ?</h2>
                    <MDBRow>
                      {kpis.map((w,i) =>
                        <MDBCol onClick={() => dataref(w.id)} key={i} className="m-2 p-2">
                        <img src={w.iotype+".png"} 
                          width="200px"
                          height="200px"        
                          objectfit="cover"
                        />
                        <div className="yellow mid">{w.name}</div>
                        </MDBCol>
                      )}
                    </MDBRow>
                    </>
                    :null}
  
                    {kpimode === "generate" ?
                    <>
                    <h2>Wie sollen die Daten angezeigt werden ?</h2>
                    <MDBRow>
                      {devices[props.did].dashoptions.map((d,i) => 
                          <MDBCol onClick={() => dataref(dashOptions[devices[props.did].dashoptions[i]].text)} key={i} className="m-2 p-2">
                          {dashOptions[devices[props.did].dashoptions[i]].text+".png"}
                          <img src={dashOptions[devices[props.did].dashoptions[i]].text+".png"} 
                            width="200px"
                            height="200px"        
                            objectfit="cover"
                          />
                          </MDBCol>
                      )}
                    </MDBRow>
                    </>
                    :null}
                  </>
                :null
                }
  
                {step === 3 ?
                  <>
                    <h2>Welche Connectivity soll verwendet werden ?</h2>
                    <MDBBtn onClick={() => setConnectivity("WLAN")} className="btn btn-lg btn-info">WLAN</MDBBtn>
                    <MDBBtn onClick={() => setConnectivity("LORA")} className="btn btn-lg btn-light">LORA</MDBBtn>
                  </>
                  : null
                }

                {step === 4 ?
                  <>
                  
                    {connect=="LORA" ?
                    <>
                      <h2>Welches LORABAND soll verwendet werden ?</h2>
                      <MDBBtn onClick={() => setBand(433)} className={band==433 ? "btn btn-lg btn-info" : "btn btn-lg btn-light" }>433 MHz</MDBBtn>
                      <MDBBtn onClick={() => setBand(868)} className={band==868 ? "btn btn-lg btn-info" : "btn btn-lg btn-light" }>868 MHz</MDBBtn>
                      <MDBBtn onClick={() => setBand(915)} className={band==915 ? "btn btn-lg btn-info" : "btn btn-lg btn-light" }>915 MHz</MDBBtn>
                      <br /><br />
                    </>
                    :null}
                    
                    <>
                      <h2>Welches WLAN soll verwendet werden ?</h2>
                      <MDBInput
                        input={"ssid"}
                        className="m-2 p-2"                  
                        label="SSID"
                        value={ssid}
                        type="text" 
                        onChange={(e) => setSsid(e.target.value)}
                      />
                      <MDBInput
                        input={"password"}
                        className="m-2 p-2"                  
                        label="Passwort" 
                        value={password}
                        type="text" 
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </>
                    <MDBBtn onClick={() => stepCount("up")} className="btn btn-info">Weiter</MDBBtn>
                  </>
                  : null
                }
                
                {step === 5 ?
                  <>
                    <h2>Wie soll das Device bezeichnet werden ?</h2>
                    <MDBInput
                      input={"ssid"}
                      className="m-2 p-2"                  
                      label="Device Bezeichnung"
                      value={device}
                      type="text" 
                      onChange={(e) => setDevice(e.target.value)}
                    />
                    <h2>Wie oft sollen die Daten synchronisiert werden ?</h2>
                    <MDBInput
                      input={"Update-Frequenz in Sekunden"}
                      className="m-2 p-2"                  
                      label="Periodizität" 
                      value={30}
                      type="number" 
                      onChange={(e) => setFrequency(e.target.value)}
                    />
                    <MDBBtn onClick={() => wizard()} className="btn btn-lg btn-info">generieren...</MDBBtn>
                  </>
                  : null
                }
                
              </div>
              :
                <h2>Du musst Dich zuerst anmelden um diese Funktion zu nutzen ! </h2>
              }

        </div>
      </div>
  );
}