import React, { useState, useEffect } from 'react';
import { MDBCol, MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import axios from 'axios';

import ManageBtn from './myBtnMgr';

export default function MyOnOffButton(props) {

  const [kpi, setKpi] = useState(JSON.parse(props.kpi));
  const [user, setUser] = useState(props.user);
  const [stat, setStat] = useState(null);
  const [isLoading, setIsloading] = useState(true);

  const onSelect = () => {
    let stat = 0;
    if(kpi.data2[0]) {
      stat = 0;
    } else {
      stat = 1;
    }
    setStat({stat});
    const event = Date.now();
    const addString = 'https://ioteasybackend.herokuapp.com/api/v1/kpis/addkpidata/' + kpi.id + '?event=' + event + '&key=' + kpi.iotnumber  + '&'+kpi.label1[0]+'=' + stat;
    console.log(addString);

    axios.post(addString)
      .then(res => {
        console.log(res.data);
      })
      .catch((error) => {
        // Error
        console.log("error writing data...");
        if (error.res) {
          console.log(error.res);
        }
      })
  }

  useEffect(() => {
    setIsloading(false);
  },[])
  
  useEffect(() => {
    setKpi(JSON.parse(props.kpi));
  },[props.kpi])

  return(
    isLoading 
      ? null 
      :  <div style={{textAlign:'center'}} className="bg-glass shadow-4-strong rounded-6 p-4 ripple">
          <h3>
            {kpi.name}
          </h3>
          <br />
          {kpi.data2[0] == 1
            ? <><MDBBtn style={{backgroundColor:'green', cursor:'none'}} >On</MDBBtn> <MDBBtn onClick={() => onSelect()}  style={{backgroundColor:'transparent', opacity:'0.5'}} >Off</MDBBtn></>
            : <><MDBBtn onClick={() => onSelect()}  style={{backgroundColor:'transparent', opacity:'0.5'}} >On</MDBBtn> <MDBBtn style={{backgroundColor:'red', cursor:'none'}} >Off</MDBBtn></>
          }

          <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
        </div>
          
        )
    }