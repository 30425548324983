import React, { useState, useEffect } from 'react';
import { MDBBtn, MDBContainer, MDBCol } from 'mdb-react-ui-kit';
import GaugeChart from 'react-gauge-chart';
import axios from 'axios';
import ManageBtn from './myBtnMgr';

export default function MyGaugeChart(props) {

    const [kpi, setKpi] = useState(JSON.parse(props.kpi));
    const [value, setValue] = useState(null);

    useEffect(() => {
    },[])
    
    useEffect(() => {
      setKpi(JSON.parse(props.kpi));
      let v = kpi.data2[0];
      while (v > 1) {
        v = v / 10;
      }
      setValue(v);
    },[props.kpi])

    const chartStyle = {
      width: 400,
      height:200
    }  

    return(
            <div style={{textAlign:'center'}}
             className="
                    bg-glass
                    shadow-4-strong
                    rounded-6
                    p-4
                    ripple
                    "
            >
              {/* <MDBBtn onClick={() => this.work()} class="btn btn-sm btn-secondary rounded" style={{backgroundColor:"darkgrey", opacity:0.2, float:"right"}}><i class="fas fa-wrench"></i></MDBBtn> */}
              <h2>{kpi.name} </h2>
              <GaugeChart id="gauge-chart5"
                style={chartStyle}
                nrOfLevels={20}
                //colors={['#EA4228', '#F5CD19', '#5BE12C']}
                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                //percent={this.state.myvalue/100}
                percent={value}
                //formatTextValue={value => this.state.myvalue + " °C"}
                needleColor={"#ff00ff"}
              />
              <ManageBtn kpi={props.kpi} user={props.user} but={props.but} close={props.close} edit={props.edit} delete={props.delete} />
           </div>
      );
}
